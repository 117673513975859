import cn from 'classnames'
import { Line } from 'rc-progress'

import { RegularCheckCircle } from 'components/icon/regular'

import { useTranslate } from 'hooks'

import { formatPrice } from 'utils/functions'

import css from './minimum-order-status.module.scss'

type MinimumOrderStatusProps = {
  min: number
  currency: string
  spent: number
}

export const MinimumOrderStatus = ({ min, currency, spent }: MinimumOrderStatusProps) => {
  const { t } = useTranslate('common')

  return (
    <div className={cn(css.container, min <= spent ? css.reached : '')}>
      {min > spent ? (
        <>
          <Line
            percent={(spent * 100) / min}
            strokeWidth={3}
            strokeColor="#5D82A4"
            trailColor="#CCCCD1"
            trailWidth={3}
          />
          <div className={css.label}>
            <span>{t('left').replace('{{amount}}', formatPrice(min - spent, currency))}</span>
            <span>{t('min').replace('{{amount}}', formatPrice(min, currency))}</span>
          </div>
        </>
      ) : (
        <div className={css.label}>
          <RegularCheckCircle />
          {t('minimum_order_reached')}
        </div>
      )}
    </div>
  )
}
