import { SVGProps } from 'react'

const SvgLightHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="18px" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.27979 16.1L6.85397 14.775C5.0696 13.1583 3.48135 11.5707 2.08921 10.012C0.696403 8.454 0 6.78333 0 5C0 3.58333 0.479611 2.39567 1.43883 1.437C2.39739 0.479 3.58541 0 5.00289 0C5.80336 0 6.59982 0.187333 7.39228 0.562C8.18407 0.937333 8.88848 1.54167 9.5055 2.375C10.1225 1.54167 10.8273 0.937333 11.6197 0.562C12.4115 0.187333 13.2076 0 14.0081 0C15.4256 0 16.6136 0.479 17.5722 1.437C18.5314 2.39567 19.011 3.58333 19.011 5C19.011 6.8 18.3023 8.48733 16.8848 10.062C15.4673 11.6373 13.883 13.2167 12.132 14.8L10.7312 16.075C10.381 16.4083 9.97244 16.575 9.5055 16.575C9.03856 16.575 8.62999 16.4167 8.27979 16.1ZM8.78008 3.9C8.24644 3.06667 7.67944 2.45833 7.0791 2.075C6.47875 1.69167 5.78668 1.5 5.00289 1.5C4.00232 1.5 3.1685 1.83333 2.50145 2.5C1.83439 3.16667 1.50087 4 1.50087 5C1.50087 5.8 1.75935 6.63733 2.27632 7.512C2.79328 8.38733 3.44366 9.25833 4.22745 10.125C5.01123 10.9917 5.85739 11.8373 6.76592 12.662C7.67511 13.4873 8.5216 14.2583 9.30538 14.975C9.35541 15.025 9.42212 15.05 9.5055 15.05C9.58888 15.05 9.65559 15.025 9.70562 14.975C10.4894 14.2583 11.3359 13.4873 12.2451 12.662C13.1536 11.8373 13.9998 10.9917 14.7836 10.125C15.5673 9.25833 16.2177 8.38733 16.7347 7.512C17.2516 6.63733 17.5101 5.8 17.5101 5C17.5101 4 17.1766 3.16667 16.5096 2.5C15.8425 1.83333 15.0087 1.5 14.0081 1.5C13.2243 1.5 12.5323 1.69167 11.9319 2.075C11.3316 2.45833 10.7646 3.06667 10.2309 3.9C10.1475 4.01667 10.0391 4.10833 9.90573 4.175C9.77232 4.24167 9.63891 4.275 9.5055 4.275C9.37209 4.275 9.23868 4.24167 9.10527 4.175C8.97186 4.10833 8.86346 4.01667 8.78008 3.9Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightHeart
