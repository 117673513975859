import { NextRouter } from 'next/router'
import React from 'react'

import ImageComponent from 'components/image'
import Link from 'components/link'

import css from './autocomplete-item.module.scss'

export type AutocompleteItemProps = React.PropsWithChildren<
  React.ComponentProps<'a'> & {
    router: NextRouter
    actions?: JSX.Element | null
    href?: string
    langSubPath: string
    iconSrc?: string
  }
>
const AutocompleteItem = ({
  langSubPath,
  children,
  actions = null,
  href = '#',
  iconSrc = '/images/icons/light-search.svg',
}: AutocompleteItemProps) => {
  return (
    <Link customLangSubPath={langSubPath} href={href} className={css.link}>
      <div className={css.itemLeftWrapper}>
        <div className={css.iconWrapper}>
          <ImageComponent src={iconSrc} alt="search" width={16} height={16} />
        </div>
        <p className={css.searchTxt}>{children}</p>
      </div>
      {!!actions && (
        <div className={css.actionGroupWrapper}>
          <div className={css.actionGroup}>{actions}</div>
        </div>
      )}
    </Link>
  )
}

export default AutocompleteItem
