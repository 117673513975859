import Image from 'next/image'
import React, { useEffect, useState } from 'react'

import cn from 'classnames'

import css from './avatar.module.scss'

type AvatarProps = {
  src?: string
  alt: string
  className?: string
  imageWidth?: number
  imageHeight?: number
  backgroundColor?: string
  textColor?: string
}

/**
 * Creates Avatar component.
 * @param {string} src The source path of the image that will be used as an Avatar.
 * In case the image is not supplied, initials will be placed instead.
 * @param {string} alt Name and Surname that will be used for creating the initials
 * @returns {JSX.Element} A new Avatar Component.
 */
const Avatar = ({
  src,
  alt = '',
  className = '',
  imageWidth = 32,
  imageHeight = 32,
  backgroundColor = '#253754',
  textColor = '#FFF',
  ...props
}: AvatarProps) => {
  const [initials, setInitials] = useState<string>('')

  useEffect(() => {
    const init: string =
      alt
        .match(/(\b\S)?/g)
        ?.join('')
        ?.match(/(^\S|\S$)?/g)
        ?.join('')
        .toUpperCase() ?? ''
    setInitials(init)
  }, [alt])

  return (
    <div
      className={cn(css.avatar, className, !src && css.noImage)}
      style={{ width: imageWidth, height: imageHeight, backgroundColor: backgroundColor, color: textColor }}
      {...props}
      role="img"
    >
      {src ? (
        <Image src={src} width={imageWidth} height={imageHeight} alt={initials} className={css.avatarImage} priority />
      ) : (
        initials
      )}
    </div>
  )
}

export default Avatar
