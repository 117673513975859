import { SVGProps } from 'react'

const SvgLightZoomIn = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.025 20.05 13.25 14.3c-.5.417-1.075.742-1.725.975-.65.233-1.317.35-2 .35-1.717 0-3.167-.592-4.35-1.775C3.992 12.667 3.4 11.217 3.4 9.5c0-1.7.592-3.146 1.775-4.338 1.183-1.191 2.633-1.787 4.35-1.787 1.7 0 3.142.592 4.325 1.775 1.183 1.183 1.775 2.633 1.775 4.35 0 .717-.117 1.4-.35 2.05a5.612 5.612 0 0 1-.95 1.7l5.775 5.775a.656.656 0 0 1 .188.513.739.739 0 0 1-.213.512.733.733 0 0 1-.537.225.656.656 0 0 1-.513-.225Zm-9.5-5.925c1.283 0 2.371-.45 3.263-1.35.891-.9 1.337-1.992 1.337-3.275s-.446-2.375-1.337-3.275c-.892-.9-1.98-1.35-3.263-1.35-1.3 0-2.396.45-3.287 1.35C5.346 7.125 4.9 8.217 4.9 9.5s.446 2.375 1.338 3.275c.891.9 1.987 1.35 3.287 1.35Zm0-1.925a.706.706 0 0 1-.537-.225.74.74 0 0 1-.213-.525v-1.2H7.55a.728.728 0 0 1-.725-.75c0-.217.07-.396.212-.538a.731.731 0 0 1 .538-.212h1.2V7.525a.728.728 0 0 1 .75-.725c.217 0 .396.075.537.225a.74.74 0 0 1 .213.525v1.2h1.2c.217 0 .392.07.525.212s.2.321.2.538a.727.727 0 0 1-.75.75h-1.175v1.225c0 .2-.071.37-.213.512a.726.726 0 0 1-.537.213Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightZoomIn
