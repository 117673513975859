/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from 'react-query'

import httpClient from 'services/http-client'

export const GET_PAYMENT_TERMS = '/retailer/payment-terms'
export const GET_PAYMENT_TERMS_QK = 'PAYMENT_TERMS_QK'

export type PaymentTermOrder = {
  id: string
  creationDate: string
  amount: string
  dueDate: string
  outstandingBalance: string
  number: string
}
export type PaymentTerm = {
  available: string
  used: string
  approved: string
}
export type PaymentTermsResponse = {
  creditCardAvailable: boolean
  orders: PaymentTermOrder[]
  paymentTerms: PaymentTerm
  currency: string
  hasPendingRequests: boolean
}

export const useGetPaymentTerms = (): UseQueryResult<PaymentTermsResponse> =>
  useQuery({
    queryKey: [GET_PAYMENT_TERMS_QK],
    queryFn: async () => {
      const { data: paymentTerms } = await httpClient.get<PaymentTermsResponse[]>(`${GET_PAYMENT_TERMS}`)
      return paymentTerms
    },
  })
