import { SolidInfo } from '../icon/solid'
import css from './error-message.module.scss'

type ErrorMessageProps = {
  message: string
}

const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <div className={css.errorMessage} role="alert" data-test-id="error-message">
    <span className={css.icon}>
      <SolidInfo />
    </span>
    <span>{message}</span>
  </div>
)

export default ErrorMessage
