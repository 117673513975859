import { useForm } from 'react-hook-form'

import { FORM_MODE } from 'utils/constants'

import { TempPasswordResponse, usePostTempPassword } from './use-post-temp-password'

export type PostForgotPasswordForm = {
  email: string
  confirmationCode: string
  newPassword: string
  confirmPassword: string
}

type ConfirmForgotPassword = {
  session: string
  userType: string
  username: string
  onSuccess: (response: TempPasswordResponse) => void
  onError: (error: string[]) => void
}

export const useTempPassword = ({ session, userType, username, onSuccess, onError }: ConfirmForgotPassword) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm<PostForgotPasswordForm>({
    mode: FORM_MODE,
    defaultValues: { confirmationCode: 'code' },
  })

  const { mutateAsync: postTempPassword, isLoading } = usePostTempPassword(userType, onSuccess, onError)

  const onSubmit = handleSubmit(async () => {
    await postTempPassword({
      email: username,
      session: session,
      newPassword: getValues().newPassword,
      confirmPassword: getValues().confirmPassword,
    })
  })

  return {
    control,
    register,
    watch,
    errors,
    onSubmit,
    isValid,
    isLoading,
  }
}
