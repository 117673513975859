import { SVGProps } from 'react'

const SvgLightMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6 12.75a.726.726 0 0 1-.75-.75.728.728 0 0 1 .75-.75h12c.217 0 .396.07.538.212a.731.731 0 0 1 .212.538.728.728 0 0 1-.75.75H6Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightMinus
