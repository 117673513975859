export const ROUTES = {
  HOME: '/',
  ABOUT_US: '/about-us',
  MEDIA_COVERAGE: '/hs/media-coverage',
  PRIVACY_POLICY: '/privacy-policy',
  SALE: '/sale',
  RETAILER_TERMS: '/retailer-terms',
  BRAND_TERMS: '/brand-terms',
  RETAILER: '/retailer',
  // ----- BELOW: registration related routes
  RETAILER_REGISTRATION: '/retailer/registration',
  RETAILER_REGISTRATION_VERIFICATION: '/retailer/registration/verification',
  RETAILER_VERIFICATION: '/retailer/verification',
  RETAILER_VERIFICATION_CONFIRMATION: '/retailer/verification/confirmation',
  RETAILER_ONBOARDING: '/retailer/onboarding',
  RETAILER_THANK_YOU: '/retailer/onboarding/thank-you',
  // ----- ABOVE: registration related routes
  PAYMENT_TERMS_APPLY: '/retailer/payment-terms-apply',
  RETAILER_MY_BRANDS: '/retailer/my-brands',
  RETAILER_PROFILE: '/retailer/profile',
  RETAILER_FAVOURITES: '/retailer/favourites',
  RETAILER_CART: '/retailer/cart',

  PORTAL: '/portal',
  RETAILER_ORDERS: '/retailer/orders',
  RETAILER_RETURNS: '/retailer/returns',
  RETAILER_WALLET: '/retailer/wallet',
  RETAILER_PAYMENT_TERMS: '/retailer/payment-terms',
  RETAILER_CREDIT_CARDS: '/retailer/credit-cards',
  RETAILER_BANK_TRANSFERS: '/retailer/pending-bank-transfers',
  RETAILER_TRANSACTIONS: '/retailer/transactions',
  RETAILER_REWARDS: '/retailer/rewards',
  RETAILER_SETTINGS: '/retailer/settings',
  RETAILER_SHIPPING_ADDRESSES: '/retailer/settings/shipping-addresses',
  RETAILER_BILLING_ADDRESSES: '/retailer/settings/billing-addresses',
  RETAILER_NOTIFICATIONS: '/retailer/settings/notifications',
  RETAILER_UPLOAD: '/retailer/settings/upload-documents',
  RETAILER_CHECKOUT: '/retailer/checkout',
  BRAND: '/brand/[brandKey]',
  BRAND_REGISTRATION: '/brand/registration',
  BRAND_REGISTRATION_CONFIRMATION: '/brand/registration/confirmation',
  BRAND_ORDERS: '/portal/orders',
  BRAND_PRODUCTS: '/portal/products',
  PRODUCT: '/product/[key]',
  PRODUCT_PREVIEW: '/product-preview',
  SEARCH: '/search',
  SEARCH_PRODUCTS: '/search/products',
  SEARCH_PROMOTION: '/search/promotion',
  SEARCH_PRODUCTS_NEW: '/search/products/new',
  SEARCH_PRODUCTS_BEST_SELLERS: '/search/products/bestsellers',
  BRAND_AGREEMENT: '/brand-agreement',
  DISCOVER: '/discover',
  QUICK_START_GUIDE: '/hs/quick-start-guide',
}
