import { ChangeEvent, InputHTMLAttributes, forwardRef, useState } from 'react'

import { STRING_PREVENT_INPUT_AUTO_FILL } from 'constants/constants'

import { SvgRegularEye, SvgRegularHideEye } from '../icon/regular'
import Input from '../input'

export type InputProps = {
  name: string
  onChange: (changeEvent: ChangeEvent<HTMLInputElement>) => void
  label?: string
  isDisabled?: boolean
  placeholder?: string
  errorMessage?: string
  className?: string
  icon?: JSX.Element
  onBlur?: (changeEvent: React.FocusEvent<HTMLInputElement>) => void
  autoComplete?: string
} & InputHTMLAttributes<HTMLInputElement>

export const PasswordField = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      onChange,
      label,
      isDisabled,
      placeholder,
      errorMessage,
      onBlur,
      className,
      autoComplete = STRING_PREVENT_INPUT_AUTO_FILL,
      ...rest
    },
    ref
  ) => {
    const [hidden, setHidden] = useState(true)
    return (
      <Input
        label={label}
        placeholder={placeholder}
        ref={ref}
        isDisabled={isDisabled}
        className={className}
        errorMessage={errorMessage}
        icon={
          <button
            type="button"
            onClick={(event) => {
              event.preventDefault()
              !isDisabled && setHidden((prevVal) => !prevVal)
            }}
          >
            {hidden ? <SvgRegularEye /> : <SvgRegularHideEye />}
          </button>
        }
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        type={hidden ? 'password' : 'text'}
        autoComplete={autoComplete}
        {...rest}
      />
    )
  }
)

export default Input

PasswordField.displayName = 'PasswordField'
