import { SVGProps } from 'react'

const SvgRegularDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 15.25a.936.936 0 0 1-.337-.062.704.704 0 0 1-.288-.213l-3.125-3.1a.71.71 0 0 1-.2-.525.71.71 0 0 1 .2-.525c.15-.15.329-.23.537-.238a.694.694 0 0 1 .538.213l1.925 1.925v-7.65a.728.728 0 0 1 .75-.75c.217 0 .396.07.538.212a.731.731 0 0 1 .212.538v7.65l1.925-1.925a.71.71 0 0 1 .525-.2c.217 0 .4.075.55.225.133.133.2.304.2.512a.783.783 0 0 1-.2.538l-3.125 3.1a.706.706 0 0 1-.287.213.944.944 0 0 1-.338.062ZM6.3 19.5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 0 1 4.5 17.7v-1.95a.727.727 0 0 1 .75-.75.727.727 0 0 1 .75.75v1.95c0 .067.033.133.1.2s.133.1.2.1h11.4c.067 0 .133-.033.2-.1s.1-.133.1-.2v-1.95c0-.217.07-.396.212-.538A.73.73 0 0 1 18.75 15a.73.73 0 0 1 .538.212.731.731 0 0 1 .212.538v1.95c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H6.3Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgRegularDownload
