import { NavMenuItem } from 'interfaces/navMenu'

import { Navigation } from 'services/fetches/navigation'
import { encodeURIComp } from 'utils/uriEncode'

export const getTopLevelItemName = ({ name, categoryNav, tag }: NavMenuItem) => {
  if (tag === 'promotion') {
    return name
  }

  return categoryNav?.name ?? name
}

const CAT_L1_URL_PLACEHOLDER = '_c1_'

export const getMenuTopLeftSection = (navMenuItems: NavMenuItem[], category?: Navigation): NavMenuItem | null => {
  const menuTopLeftSection = navMenuItems.find((item) => item.tag === 'menuTopLeftSection')

  if (!menuTopLeftSection) return null

  const encodedCategoryName = encodeURIComp(category?.nameEn ?? '')

  return {
    ...menuTopLeftSection,
    children: menuTopLeftSection.children.map((item) => {
      return { ...item, url: item.url.replace(CAT_L1_URL_PLACEHOLDER, encodedCategoryName) }
    }),
  }
}

export const getBrandHighlightSection = (navMenuItems: NavMenuItem[]): NavMenuItem | undefined => {
  return navMenuItems.find((item) => item.tag === 'brandHighlight')
}
