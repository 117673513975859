import Image from 'next/image'

declare const VALID_LAYOUT_VALUES: readonly ['fill', 'fixed', 'intrinsic', 'responsive', undefined]
declare type LayoutValue = (typeof VALID_LAYOUT_VALUES)[number]

export type ImageComponentProps = {
  src: string
  width?: number
  height?: number
  alt: string
  layout?: LayoutValue
  fill?: boolean
  quality?: number
  priority?: boolean
  className?: string
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  onClick?: React.MouseEventHandler<HTMLImageElement>
  blurDataURL?: string | undefined
  placeholder?: 'blur' | 'empty'
}

const ImageComponent = ({
  src = 'https://via.placeholder.com/150',
  width,
  height,
  alt = '',
  fill,
  priority = false,
  className,
  onClick,
  objectFit,
  blurDataURL,
  placeholder,
  ...props
}: ImageComponentProps) => (
  <Image
    src={src}
    width={width}
    height={height}
    priority={priority}
    alt={alt}
    fill={fill}
    style={{ objectFit }}
    className={className}
    placeholder={placeholder}
    onClick={onClick}
    blurDataURL={blurDataURL}
    loading={priority ? 'eager' : 'lazy'}
    {...props}
  />
)

export default ImageComponent
