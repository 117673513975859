import { SVGProps } from 'react'

const RegularLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19 22H5C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V8C3 7.45 3.19567 6.97933 3.587 6.588C3.979 6.196 4.45 6 5 6H7C7 4.61667 7.48767 3.43733 8.463 2.462C9.43767 1.48733 10.6167 1 12 1C13.3833 1 14.5627 1.48733 15.538 2.462C16.5127 3.43733 17 4.61667 17 6H19C19.55 6 20.021 6.196 20.413 6.588C20.8043 6.97933 21 7.45 21 8V20C21 20.55 20.8043 21.021 20.413 21.413C20.021 21.8043 19.55 22 19 22ZM9 6H15C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6ZM19 20V8H5V20H19ZM12 14C13.2333 14 14.35 13.5917 15.35 12.775C16.35 11.9583 16.8417 11.0417 16.825 10.025C16.825 9.74167 16.7333 9.5 16.55 9.3C16.3667 9.1 16.1333 9 15.85 9C15.6167 9 15.4083 9.075 15.225 9.225C15.0417 9.375 14.9083 9.6 14.825 9.9C14.6417 10.5333 14.2833 11.0417 13.75 11.425C13.2167 11.8083 12.6333 12 12 12C11.3667 12 10.7793 11.8083 10.238 11.425C9.696 11.0417 9.34167 10.5333 9.175 9.9C9.09167 9.58333 8.96667 9.354 8.8 9.212C8.63333 9.07067 8.43333 9 8.2 9C7.91667 9 7.67933 9.1 7.488 9.3C7.296 9.5 7.2 9.74167 7.2 10.025C7.2 11.0417 7.69167 11.9583 8.675 12.775C9.65833 13.5917 10.7667 14 12 14Z"
      fill="currentColor"
    />
  </svg>
)
export default RegularLogout
