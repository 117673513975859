import { SVGProps } from 'react'

const SvgSolidFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.6825 2.00098C6.30572 2.00098 1.94727 6.47798 1.94727 12.001C1.94727 16.992 5.50743 21.129 10.1618 21.88V14.891H7.68909V12.001H10.1618V9.79798C10.1618 7.29198 11.6143 5.90798 13.8388 5.90798C14.9039 5.90798 16.0176 6.10298 16.0176 6.10298V8.56298H14.7909C13.5808 8.56298 13.2041 9.33398 13.2041 10.125V12.001H15.9037L15.4724 14.891H13.2041V21.88C17.8575 21.13 21.4177 16.991 21.4177 12.001C21.4177 6.47798 17.0592 2.00098 11.6825 2.00098Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSolidFacebook
