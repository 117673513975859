import { SVGProps } from 'react'

const SvgRegularNote = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5 19H14V15C14 14.7167 14.096 14.479 14.288 14.287C14.4793 14.0957 14.7167 14 15 14H19V5H5V19ZM5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H19C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V15L15 21H5ZM8 14C7.71667 14 7.479 13.904 7.287 13.712C7.09567 13.5207 7 13.2833 7 13C7 12.7167 7.09567 12.479 7.287 12.287C7.479 12.0957 7.71667 12 8 12H11C11.2833 12 11.521 12.0957 11.713 12.287C11.9043 12.479 12 12.7167 12 13C12 13.2833 11.9043 13.5207 11.713 13.712C11.521 13.904 11.2833 14 11 14H8ZM8 10C7.71667 10 7.479 9.904 7.287 9.712C7.09567 9.52067 7 9.28333 7 9C7 8.71667 7.09567 8.479 7.287 8.287C7.479 8.09567 7.71667 8 8 8H16C16.2833 8 16.5207 8.09567 16.712 8.287C16.904 8.479 17 8.71667 17 9C17 9.28333 16.904 9.52067 16.712 9.712C16.5207 9.904 16.2833 10 16 10H8Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgRegularNote
