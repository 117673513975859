import { SVGProps } from 'react'

const SvgLightContentCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.275 17.8c-.517 0-.95-.175-1.3-.525A1.736 1.736 0 0 1 7.45 16V4.625c0-.517.175-.95.525-1.3.35-.35.783-.525 1.3-.525h8.35c.5 0 .93.18 1.288.537.358.359.537.788.537 1.288V16c0 .5-.179.925-.537 1.275a1.78 1.78 0 0 1-1.288.525h-8.35Zm0-1.5h8.35c.1 0 .18-.03.238-.088A.288.288 0 0 0 17.95 16V4.625c0-.1-.029-.18-.087-.238a.324.324 0 0 0-.238-.087h-8.35a.32.32 0 0 0-.237.087.322.322 0 0 0-.088.238V16c0 .083.03.154.088.212a.318.318 0 0 0 .237.088Zm-3.525 5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 0 1 3.95 19.5V7.55c0-.2.07-.375.212-.525A.71.71 0 0 1 4.7 6.8c.2 0 .375.075.525.225a.72.72 0 0 1 .225.525V19.5c0 .083.029.154.087.212a.29.29 0 0 0 .213.088h8.95c.2 0 .375.075.525.225a.72.72 0 0 1 .225.525.706.706 0 0 1-.225.537.74.74 0 0 1-.525.213H5.75Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightContentCopy
