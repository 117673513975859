import { SVGProps } from 'react'

const SvgSolidLinkedIn = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.9739 15.34H12.3794V11.163C12.3794 10.167 12.36 8.88498 11.0262 8.88498C9.67402 8.88498 9.46763 9.96898 9.46763 11.09V15.34H6.87222V6.75098H9.36444V7.92098H9.39851C9.74703 7.24698 10.594 6.53398 11.8596 6.53398C14.4881 6.53398 14.9748 8.31198 14.9748 10.625V15.34H14.9739ZM3.94193 5.57598C3.74387 5.57624 3.54771 5.53635 3.36469 5.45858C3.18167 5.38082 3.0154 5.26672 2.87539 5.12281C2.73539 4.97891 2.62441 4.80804 2.54883 4.61999C2.47324 4.43194 2.43453 4.23042 2.43492 4.02698C2.43511 3.72081 2.52368 3.42158 2.68943 3.16712C2.85519 2.91267 3.09067 2.71441 3.36612 2.59743C3.64156 2.48045 3.94459 2.44999 4.23688 2.50992C4.52918 2.56984 4.79761 2.71745 5.00823 2.93408C5.21885 3.15071 5.36221 3.42664 5.42017 3.72696C5.47813 4.02728 5.44809 4.33851 5.33385 4.62129C5.21961 4.90408 5.0263 5.14572 4.77837 5.31565C4.53043 5.48558 4.23901 5.57617 3.94095 5.57598H3.94193ZM5.24255 15.34H2.64033V6.75098H5.24352V15.34H5.24255ZM16.2735 0.000976562H1.33873C0.62222 0.000976562 0.0449219 0.580976 0.0449219 1.29798V16.704C0.0449219 17.421 0.623193 18.001 1.33776 18.001H16.2696C16.9842 18.001 17.5683 17.421 17.5683 16.704V1.29798C17.5683 0.580976 16.9842 0.000976562 16.2696 0.000976562H16.2725H16.2735Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSolidLinkedIn
