import css from '../app-header.module.scss'

type UserMenuItemProps = {
  marginTop?: boolean
  marginBottom?: boolean
  children: React.ReactNode
}

const UserMenuItem = ({ marginTop = true, marginBottom = true, children }: UserMenuItemProps) => {
  return (
    <div className={`${css.menuItem} ${marginTop ? css.paddingTop : ''} ${marginBottom ? css.paddingBottom : ''}`}>
      {children}
    </div>
  )
}

export default UserMenuItem
