import React, { useEffect, useRef, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { usePopper } from 'react-popper'

import { Placement } from '@popperjs/core'
import cn from 'classnames'

import css from './popup.module.scss'

export type PopUpPlacementType =
  | 'top'
  | 'right'
  | 'left'
  | 'bottom'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'left-start'
  | 'left-end'

export type PopUpEventType = 'hover' | 'click'
type PopUpProps = {
  children: JSX.Element[]
  placement?: PopUpPlacementType
  eventType?: PopUpEventType
  offset?: number[]
  renderArrow?: boolean
  className?: string
  isVisible?: boolean
  onVisibleChange?: (visible: boolean) => void
}

const PopUp = ({
  children,
  placement = 'top-start',
  eventType = 'click',
  offset = [0, 0],
  renderArrow = false,
  className,
  isVisible = false,
  onVisibleChange,
  ...props
}: PopUpProps): JSX.Element => {
  const [visible, setVisible] = useState(false)
  const referenceElement = useRef<HTMLDivElement>(null)
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement.current, popperElement, {
    placement: placement as Placement,
    modifiers: [
      {
        name: 'offset',
        options: { offset: [offset[0], offset[1]] },
      },
      {
        name: 'arrow',
        options: { element: arrowElement },
      },
    ],
  })

  useEffect(() => {
    setVisible(isVisible)
  }, [isVisible])

  const emitVisible = (isVisible: boolean) => {
    if (onVisibleChange) {
      onVisibleChange(isVisible)
    }
  }

  const toggle = () => {
    setVisible(!visible)
    if (onVisibleChange) {
      emitVisible(!visible)
    }
  }

  const renderButton = (event: PopUpEventType): JSX.Element => (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {event === 'click' ? (
        <div
          role="button"
          tabIndex={0}
          ref={referenceElement}
          className={css.reference}
          data-test-id="toggler"
          onClick={toggle}
          onKeyDown={(keyDownEvent: React.KeyboardEvent) => {
            if (keyDownEvent.key === 'Enter') {
              toggle()
            }
          }}
          {...props}
        >
          {children[0]}
        </div>
      ) : (
        <span
          ref={referenceElement}
          className={css.reference}
          data-test-id="toggler"
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
          {...props}
        >
          {children[0]}
        </span>
      )}
    </>
  )

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setVisible(false)
        emitVisible(false)
      }}
    >
      {renderButton(eventType)}

      {!!visible && (
        <div
          className={cn(className, css.popper)}
          ref={setPopperElement}
          style={styles.popper}
          data-test-id="content"
          {...attributes.popper}
        >
          {!!renderArrow && <div ref={setArrowElement} className={css.arrow} style={styles.arrow} />}

          {children[1]}
        </div>
      )}
    </OutsideClickHandler>
  )
}

export default PopUp
