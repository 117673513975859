import { useMutation } from 'react-query'

import { ErrorResponse } from 'services/error-response'
import httpClient from 'services/http-client'

export const SELECT_ITEM = '/cart/items/selection/brand'

type SelectItemProps = { isSelected: boolean; brandKey: string }

const SelectItemInCart = async ({ isSelected, brandKey }: SelectItemProps) => {
  return await httpClient.put(`${SELECT_ITEM}`, {
    isSelected: isSelected,
    brandKey: brandKey,
  })
}
export const useSelectItemInCart = (onError: (errors: ErrorResponse) => void) =>
  useMutation(SelectItemInCart, { onError })
