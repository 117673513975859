import { SVGProps } from 'react'

const SvgLightMessageText = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 13.75h6.025a.728.728 0 0 0 .725-.75.731.731 0 0 0-.212-.538.731.731 0 0 0-.538-.212H6.975a.73.73 0 0 0-.725.75.726.726 0 0 0 .75.75Zm0-3h10.025a.728.728 0 0 0 .725-.75.731.731 0 0 0-.212-.538A.731.731 0 0 0 17 9.25H6.975a.73.73 0 0 0-.725.75.726.726 0 0 0 .75.75Zm0-3h10.025A.728.728 0 0 0 17.75 7a.728.728 0 0 0-.75-.75H6.975c-.2 0-.37.071-.512.213A.726.726 0 0 0 6.25 7a.726.726 0 0 0 .75.75Zm-4.5 11.1V4.3c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h15.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v11.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H6.05l-2.025 2c-.283.283-.608.346-.975.188-.367-.159-.55-.438-.55-.838ZM4 17.425 5.425 16H19.7a.29.29 0 0 0 .213-.088A.289.289 0 0 0 20 15.7V4.3a.292.292 0 0 0-.087-.213A.292.292 0 0 0 19.7 4H4.3a.289.289 0 0 0-.212.087A.29.29 0 0 0 4 4.3v13.125ZM4 4.3V4v13.425V4.3Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightMessageText
