import { SVGProps } from 'react'

const SvgRegularContentPaste = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 4.25C9.2125 4.25 9.39075 4.178 9.53475 4.034C9.67825 3.8905 9.75 3.7125 9.75 3.5C9.75 3.2875 9.67825 3.10925 9.53475 2.96525C9.39075 2.82175 9.2125 2.75 9 2.75C8.7875 2.75 8.6095 2.82175 8.466 2.96525C8.322 3.10925 8.25 3.2875 8.25 3.5C8.25 3.7125 8.322 3.8905 8.466 4.034C8.6095 4.178 8.7875 4.25 9 4.25ZM3.75 16.25C3.3375 16.25 2.98425 16.1033 2.69025 15.8097C2.39675 15.5157 2.25 15.1625 2.25 14.75V4.25C2.25 3.8375 2.39675 3.48425 2.69025 3.19025C2.98425 2.89675 3.3375 2.75 3.75 2.75H6.88125C7.01875 2.3125 7.2875 1.953 7.6875 1.6715C8.0875 1.3905 8.525 1.25 9 1.25C9.5 1.25 9.947 1.3905 10.341 1.6715C10.7345 1.953 11 2.3125 11.1375 2.75H14.25C14.6625 2.75 15.0157 2.89675 15.3097 3.19025C15.6033 3.48425 15.75 3.8375 15.75 4.25V14.75C15.75 15.1625 15.6033 15.5157 15.3097 15.8097C15.0157 16.1033 14.6625 16.25 14.25 16.25H3.75ZM3.75 14.75H14.25V4.25H12.75V5C12.75 5.4125 12.6033 5.7655 12.3097 6.059C12.0157 6.353 11.6625 6.5 11.25 6.5H6.75C6.3375 6.5 5.9845 6.353 5.691 6.059C5.397 5.7655 5.25 5.4125 5.25 5V4.25H3.75V14.75Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgRegularContentPaste
