import { SVGProps } from 'react'

const SvgLightDotsMoreHorizontal = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.225 13.5c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 4.725 12c0-.417.146-.77.438-1.062a1.444 1.444 0 0 1 1.062-.438c.417 0 .771.146 1.063.438.291.291.437.645.437 1.062 0 .417-.146.77-.437 1.062a1.447 1.447 0 0 1-1.063.438Zm5.775 0c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 10.5 12c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 12 10.5c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062 0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 12 13.5Zm5.775 0c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 16.275 12c0-.417.146-.77.438-1.062a1.444 1.444 0 0 1 1.062-.438c.417 0 .771.146 1.063.438.291.291.437.645.437 1.062 0 .417-.146.77-.437 1.062a1.447 1.447 0 0 1-1.063.438Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightDotsMoreHorizontal
