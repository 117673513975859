import { SVGProps } from 'react'

const SvgLightArrowChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m13.475 17.125-4.5-4.5a.822.822 0 0 1-.188-.288.935.935 0 0 1 0-.675.825.825 0 0 1 .188-.287l4.5-4.5A.72.72 0 0 1 14 6.65c.2 0 .375.075.525.225a.72.72 0 0 1 .225.525.72.72 0 0 1-.225.525L10.45 12l4.075 4.075a.72.72 0 0 1 .225.525.72.72 0 0 1-.225.525.72.72 0 0 1-.525.225.72.72 0 0 1-.525-.225Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightArrowChevronLeft
