import { SVGProps } from 'react'

const SvgLightCall = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.425 20.45c-1.933 0-3.854-.463-5.762-1.388a18.73 18.73 0 0 1-5.113-3.637c-1.5-1.5-2.712-3.2-3.637-5.1-.925-1.9-1.388-3.825-1.388-5.775 0-.3.1-.55.3-.75.2-.2.45-.3.75-.3h3.25a1.044 1.044 0 0 1 1.05.85L9.45 7.3c.033.25.025.475-.025.675-.05.2-.158.375-.325.525l-2.3 2.25a18.49 18.49 0 0 0 2.837 3.625A18.129 18.129 0 0 0 13.35 17.2l2.225-2.25c.167-.167.363-.283.588-.35.225-.067.454-.083.687-.05l2.775.575c.25.05.454.167.613.35a.998.998 0 0 1 .237.675v3.25c0 .3-.1.55-.3.75-.2.2-.45.3-.75.3ZM6.1 9.325l1.775-1.7a.221.221 0 0 0 .063-.113.171.171 0 0 0-.013-.112L7.5 5.15a.227.227 0 0 0-.075-.113A.204.204 0 0 0 7.3 5H5.175c-.05 0-.088.012-.113.037a.122.122 0 0 0-.037.088 12.459 12.459 0 0 0 1.075 4.2ZM18.85 18.9a.12.12 0 0 0 .088-.038.119.119 0 0 0 .037-.087v-2.1a.204.204 0 0 0-.037-.125.227.227 0 0 0-.113-.075l-2.1-.425a.106.106 0 0 0-.1 0l-.1.05-1.775 1.8c.667.317 1.358.558 2.075.725.717.167 1.392.258 2.025.275Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightCall
