import { INSTANT_SEARCH_ATTRIBUTES } from 'constants/instantSearch'

export const DEFAULT_NEW_PRODUCT_DURATION_IN_DAYS = 21

export type FilterQuery = {
  name: string
  queryOnValue: string
  locale: string
  attribute: string
}

export const NEW_PUBLISHED_QUERY: FilterQuery = {
  name: 'new-published',
  queryOnValue: '1',
  locale: 'PageSearch.PageSearch_Filters_new_publish',
  attribute: `${INSTANT_SEARCH_ATTRIBUTES.FIRST_PUBLISH_DATE_UNIX.attr}:${
    Math.round(new Date().getTime() / 1000) - DEFAULT_NEW_PRODUCT_DURATION_IN_DAYS
  }`,
}

export const BESTSELLERS_QUERY = {
  name: 'best-sellers',
  queryOnValue: '1',
  locale: 'PageSearch.PageSearch_Filters_bestsellers',
  attribute: `${INSTANT_SEARCH_ATTRIBUTES.BEST_SELLER.attr}:true`,
}

export const FILTER_QUERY_LIST = [NEW_PUBLISHED_QUERY, BESTSELLERS_QUERY]
