/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserStore = /* GraphQL */ `
  query GetUserStore($cognitoUserId: String!) {
    getUserStore(cognitoUserId: $cognitoUserId) {
      DOC
      TTL
      cognitoUserId
    }
  }
`
export const listUserStores = /* GraphQL */ `
  query ListUserStores($filter: TableUserStoreFilterInput, $limit: Int, $nextToken: String) {
    listUserStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        DOC
        TTL
        cognitoUserId
      }
      nextToken
    }
  }
`
