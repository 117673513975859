import { Dispatch, FormEvent, SetStateAction } from 'react'

import Button from 'components/button'
import Input from 'components/input'

import { useTranslate } from 'hooks/translation/use-translation'

import { regEmailOrPhoneNumberWithPlusSign } from 'constants/regular-expressions'

import css from '../forgot-password.module.scss'
import { useForgotPasswordForm } from '../hooks/use-forgot-password-form'

type EnterUsernameProps = {
  username: string
  setStep: Dispatch<SetStateAction<number>>
  setUsername: Dispatch<SetStateAction<string>>
}

export const EnterUsername = ({ username, setStep, setUsername }: EnterUsernameProps) => {
  const { t } = useTranslate('registration')
  const { t: t2 } = useTranslate('common')

  const { register, getValues, onSubmit, isValid, errors, isLoading, setError } = useForgotPasswordForm(username)

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      await onSubmit()
      if (isValid) {
        setUsername(getValues().username.trim())
        setStep((prevStep) => prevStep + 1)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
      const errorMessage = `${error?.response?.data?.code?.[0] || ''}`
      setError('username', { type: 'custom', message: t2(`login.error.${errorMessage}`) })
    }
  }

  return (
    <>
      <p className={css.headingStyle}>{t('Enter your username')}</p>
      <p className={css.descriptionStyle}>{t('Please enter')}</p>
      <form onSubmit={handleSubmit} style={{ position: 'relative', height: '100% ' }}>
        <Input
          data-test-id="forgot-password-username-input"
          label={t('Username')}
          placeholder={t('Enter your username')}
          {...register('username', {
            required: t('Username is required'),
            pattern: {
              value: regEmailOrPhoneNumberWithPlusSign,
              message: t('email_or_phone_invalid'),
            },
          })}
          type="text"
          autoComplete="username"
          errorMessage={errors.username?.message}
        />
        <Button
          data-test-id="post-forget-password-button"
          type="submit"
          style="secondary"
          size="large"
          label={t('Next')}
          className={css.buttonWrapperStyle}
          isLoading={isLoading}
        />
      </form>
    </>
  )
}
