import { SVGProps } from 'react'

const SvgLightBell = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.16602 15.7292C3.98546 15.7292 3.83629 15.6667 3.71852 15.5417C3.60018 15.4167 3.54102 15.2708 3.54102 15.1042C3.54102 14.9236 3.60018 14.7742 3.71852 14.6558C3.83629 14.5381 3.98546 14.4792 4.16602 14.4792H5.20768V8.4375C5.20768 7.32639 5.5549 6.32972 6.24935 5.4475C6.94379 4.56583 7.84657 4 8.95768 3.75V3.16667C8.95768 2.875 9.05852 2.62833 9.26018 2.42667C9.46129 2.22556 9.70768 2.125 9.99935 2.125C10.291 2.125 10.5374 2.22556 10.7385 2.42667C10.9402 2.62833 11.041 2.875 11.041 3.16667V3.75C12.1521 4 13.0549 4.56583 13.7493 5.4475C14.4438 6.32972 14.791 7.32639 14.791 8.4375V14.4792H15.8327C16.0132 14.4792 16.1627 14.5381 16.281 14.6558C16.3988 14.7742 16.4577 14.9236 16.4577 15.1042C16.4577 15.2708 16.3988 15.4167 16.281 15.5417C16.1627 15.6667 16.0132 15.7292 15.8327 15.7292H4.16602ZM9.99935 18.1667C9.58268 18.1667 9.22852 18.0208 8.93685 17.7292C8.64518 17.4375 8.49935 17.0833 8.49935 16.6667H11.4993C11.4993 17.0833 11.3535 17.4375 11.0618 17.7292C10.7702 18.0208 10.416 18.1667 9.99935 18.1667ZM6.45768 14.4792H13.541V8.4375C13.541 7.45139 13.1938 6.61444 12.4993 5.92667C11.8049 5.23944 10.9716 4.89583 9.99935 4.89583C9.02713 4.89583 8.19379 5.23944 7.49935 5.92667C6.8049 6.61444 6.45768 7.45139 6.45768 8.4375V14.4792Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightBell
