import cn from 'classnames'

import css from './accordion-tab.module.scss'

export type AccordionTabClasses = {
  classNameTab?: string
  classNameTabLabel?: string
  classNameTabContent?: string
  classNameTabContentInnerWrapper?: string
}

type AccordionTabProps = {
  title: string
  id?: string // unique id for the tab for linking input and label
  children: React.ReactNode
} & AccordionTabClasses

const AccordionTab = ({
  title,
  id,
  children,
  classNameTab,
  classNameTabLabel,
  classNameTabContent,
  classNameTabContentInnerWrapper,
}: AccordionTabProps) => {
  return (
    <div className={cn([css.tab, classNameTab])}>
      <input type="checkbox" id={id ?? title} />
      <label className={cn([css.tabLabel, classNameTabLabel])} htmlFor={id ?? title}>
        {title}
      </label>
      <div className={cn([css.tabContent, classNameTabContent])}>
        <ul className={cn([css.tabContentInnerWrapper, classNameTabContentInnerWrapper])}>{children}</ul>
      </div>
    </div>
  )
}

export default AccordionTab
