import { RefObject, useEffect } from 'react'

const headerLineClass = 'header_line'
export function useOnHoverOutside(ref: RefObject<HTMLDivElement>, handler: () => void) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const { target } = event
      const className = (target as HTMLDivElement).className || ''
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (
        !ref.current ||
        ref.current.contains(target as HTMLDivElement) ||
        className.toString().includes(headerLineClass)
      ) {
        return
      }
      handler()
    }
    document.addEventListener('mouseover', listener)
    return () => {
      document.removeEventListener('mouseover', listener)
    }
  }, [ref, handler])
}
