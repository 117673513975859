import { SVGProps } from 'react'

const SvgLightArrowChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.875 17.125a.72.72 0 0 1-.225-.525c0-.2.075-.375.225-.525L13.95 12 9.875 7.925A.72.72 0 0 1 9.65 7.4c0-.2.075-.375.225-.525a.72.72 0 0 1 .525-.225c.2 0 .375.075.525.225l4.5 4.5a.825.825 0 0 1 .188.287.944.944 0 0 1 0 .675.823.823 0 0 1-.188.288l-4.5 4.5a.72.72 0 0 1-.525.225.72.72 0 0 1-.525-.225Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightArrowChevronRight
