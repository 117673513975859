import { SVGProps } from 'react'

const RegularLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.65 20.8004L8.925 19.2254C7.15833 17.6087 5.56267 16.0044 4.138 14.4124C2.71267 12.8211 2 11.0671 2 9.15039C2 7.58372 2.525 6.27539 3.575 5.22539C4.625 4.17539 5.93333 3.65039 7.5 3.65039C8.38333 3.65039 9.21667 3.83772 10 4.21239C10.7833 4.58772 11.45 5.10039 12 5.75039C12.55 5.10039 13.2167 4.58772 14 4.21239C14.7833 3.83772 15.6167 3.65039 16.5 3.65039C18.0667 3.65039 19.375 4.17539 20.425 5.22539C21.475 6.27539 22 7.58372 22 9.15039C22 11.0671 21.2917 12.8254 19.875 14.4254C18.4583 16.0254 16.85 17.6337 15.05 19.2504L13.35 20.8004C12.9667 21.1671 12.5167 21.3504 12 21.3504C11.4833 21.3504 11.0333 21.1671 10.65 20.8004ZM11.05 7.75039C10.5667 7.06706 10.05 6.54606 9.5 6.18739C8.95 5.82939 8.28333 5.65039 7.5 5.65039C6.5 5.65039 5.66667 5.98372 5 6.65039C4.33333 7.31706 4 8.15039 4 9.15039C4 10.0171 4.30833 10.9377 4.925 11.9124C5.54167 12.8877 6.27933 13.8337 7.138 14.7504C7.996 15.6671 8.87933 16.5254 9.788 17.3254C10.696 18.1254 11.4333 18.7837 12 19.3004C12.5667 18.7837 13.3043 18.1254 14.213 17.3254C15.121 16.5254 16.0043 15.6671 16.863 14.7504C17.721 13.8337 18.4583 12.8877 19.075 11.9124C19.6917 10.9377 20 10.0171 20 9.15039C20 8.15039 19.6667 7.31706 19 6.65039C18.3333 5.98372 17.5 5.65039 16.5 5.65039C15.7167 5.65039 15.05 5.82939 14.5 6.18739C13.95 6.54606 13.4333 7.06706 12.95 7.75039C12.8333 7.91706 12.6917 8.04206 12.525 8.12539C12.3583 8.20872 12.1833 8.25039 12 8.25039C11.8167 8.25039 11.6417 8.20872 11.475 8.12539C11.3083 8.04206 11.1667 7.91706 11.05 7.75039Z"
      fill="currentColor"
    />
  </svg>
)
export default RegularLogout
