import { SVGProps } from 'react'

const SvgSolidInfoRounded = (props: SVGProps<SVGSVGElement>) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.48744 9.18916C6.64059 9.18916 6.76906 9.13727 6.87284 9.03349C6.97626 8.93006 7.02798 8.80177 7.02798 8.64862V6.47295C7.02798 6.31979 6.97626 6.19367 6.87284 6.09457C6.76906 5.99547 6.64059 5.94592 6.48744 5.94592C6.33428 5.94592 6.20599 5.99763 6.10257 6.10105C5.99879 6.20484 5.9469 6.33331 5.9469 6.48646V8.66213C5.9469 8.81529 5.99879 8.94141 6.10257 9.04051C6.20599 9.13961 6.33428 9.18916 6.48744 9.18916ZM6.48744 4.86484C6.64059 4.86484 6.76906 4.81295 6.87284 4.70916C6.97626 4.60574 7.02798 4.47745 7.02798 4.3243C7.02798 4.17114 6.97626 4.04268 6.87284 3.93889C6.76906 3.83547 6.64059 3.78376 6.48744 3.78376C6.33428 3.78376 6.20599 3.83547 6.10257 3.93889C5.99879 4.04268 5.9469 4.17114 5.9469 4.3243C5.9469 4.47745 5.99879 4.60574 6.10257 4.70916C6.20599 4.81295 6.33428 4.86484 6.48744 4.86484ZM6.48744 11.8919C5.73969 11.8919 5.03699 11.7499 4.37933 11.4659C3.72167 11.1823 3.1496 10.7973 2.66311 10.3108C2.17663 9.8243 1.79158 9.25222 1.50798 8.59457C1.22401 7.93691 1.08203 7.23421 1.08203 6.48646C1.08203 5.73871 1.22401 5.03601 1.50798 4.37835C1.79158 3.72069 2.17663 3.14862 2.66311 2.66214C3.1496 2.17565 3.72167 1.79042 4.37933 1.50646C5.03699 1.22286 5.73969 1.08105 6.48744 1.08105C7.23518 1.08105 7.93789 1.22286 8.59554 1.50646C9.2532 1.79042 9.82527 2.17565 10.3118 2.66214C10.7982 3.14862 11.1833 3.72069 11.4669 4.37835C11.7509 5.03601 11.8928 5.73871 11.8928 6.48646C11.8928 7.23421 11.7509 7.93691 11.4669 8.59457C11.1833 9.25222 10.7982 9.8243 10.3118 10.3108C9.82527 10.7973 9.2532 11.1823 8.59554 11.4659C7.93789 11.7499 7.23518 11.8919 6.48744 11.8919Z"
      fill="#5D82A4"
    />
  </svg>
)
export default SvgSolidInfoRounded
