import { SVGProps } from 'react'

const SvgLocationPin = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 9.5C9.4125 9.5 9.76575 9.353 10.0598 9.059C10.3533 8.7655 10.5 8.4125 10.5 8C10.5 7.5875 10.3533 7.23425 10.0598 6.94025C9.76575 6.64675 9.4125 6.5 9 6.5C8.5875 6.5 8.2345 6.64675 7.941 6.94025C7.647 7.23425 7.5 7.5875 7.5 8C7.5 8.4125 7.647 8.7655 7.941 9.059C8.2345 9.353 8.5875 9.5 9 9.5ZM9 15.0125C10.525 13.6125 11.6562 12.3405 12.3937 11.1965C13.1312 10.053 13.5 9.0375 13.5 8.15C13.5 6.7875 13.0655 5.67175 12.1965 4.80275C11.328 3.93425 10.2625 3.5 9 3.5C7.7375 3.5 6.67175 3.93425 5.80275 4.80275C4.93425 5.67175 4.5 6.7875 4.5 8.15C4.5 9.0375 4.86875 10.053 5.60625 11.1965C6.34375 12.3405 7.475 13.6125 9 15.0125ZM9 16.7188C8.9 16.7188 8.8 16.7 8.7 16.6625C8.6 16.625 8.5125 16.575 8.4375 16.5125C6.6125 14.9 5.25 13.4032 4.35 12.0223C3.45 10.6407 3 9.35 3 8.15C3 6.275 3.60325 4.78125 4.80975 3.66875C6.01575 2.55625 7.4125 2 9 2C10.5875 2 11.9843 2.55625 13.1903 3.66875C14.3967 4.78125 15 6.275 15 8.15C15 9.35 14.55 10.6407 13.65 12.0223C12.75 13.4032 11.3875 14.9 9.5625 16.5125C9.4875 16.575 9.4 16.625 9.3 16.6625C9.2 16.7 9.1 16.7188 9 16.7188Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLocationPin
