import { useEffect, useState } from 'react'

import { useTranslate } from 'hooks'

import css from './index.module.scss'

type PasswordValidationProps = {
  isPasswordValid: boolean
  passwordValue: string
  onPasswordValidChange: (isValid: boolean) => void
}

const regexMin8 = /^.{8,}$/
const regexLowercase = /^(?=.*[a-z]).*$/
const regexUppercase = /^(?=.*[A-Z]).*$/
const regexNumbers = /^(?=.*\d).*$/
const regexSymbols = /^(?=.*\W).*$/

const PasswordValidation = ({ isPasswordValid, passwordValue, onPasswordValidChange }: PasswordValidationProps) => {
  const { t } = useTranslate('registration')
  const [isPasswordFieldDirty, setIsPasswordFieldDirty] = useState<boolean>(false)

  const [min8, setMin8] = useState<boolean>(false)
  const [lowercase, setLowercase] = useState<boolean>(false)
  const [uppercase, setUppercase] = useState<boolean>(false)
  const [numbers, setNumbers] = useState<boolean>(false)
  const [symbols, setSymbols] = useState<boolean>(false)

  const validatePasswordField = (regex: RegExp, setter: React.Dispatch<React.SetStateAction<boolean>>) => {
    const isValid = regex.test(passwordValue)
    setter(isValid)

    return isValid
  }

  useEffect(() => {
    if (!isPasswordFieldDirty && passwordValue) {
      setIsPasswordFieldDirty(true)
    }
    const isMin8Valid = validatePasswordField(regexMin8, setMin8)
    const isLowercaseValid = validatePasswordField(regexLowercase, setLowercase)
    const isUppercaseValid = validatePasswordField(regexUppercase, setUppercase)
    const isNumbersValid = validatePasswordField(regexNumbers, setNumbers)
    const isSymbolsValid = validatePasswordField(regexSymbols, setSymbols)

    onPasswordValidChange(isMin8Valid && isLowercaseValid && isUppercaseValid && isNumbersValid && isSymbolsValid)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordValue])

  if (!isPasswordFieldDirty || isPasswordValid) return null

  return (
    <div className={css.passwordValidationWrapper} data-test-id="password-validation-div">
      <p className={min8 ? css.valid : css.invalid} data-test-id={min8 ? 'valid' : 'invalid'}>
        <span />
        {t('8_characters_minimum')}
      </p>
      <p className={uppercase ? css.valid : css.invalid} data-test-id={uppercase ? 'valid' : 'invalid'}>
        <span />
        {t('one_uppercase_character')}
      </p>
      <p className={lowercase ? css.valid : css.invalid} data-test-id={lowercase ? 'valid' : 'invalid'}>
        <span />
        {t('one_lowercase_character')}
      </p>
      <p className={numbers ? css.valid : css.invalid} data-test-id={numbers ? 'valid' : 'invalid'}>
        <span />
        {t('one_number')}
      </p>
      <p className={symbols ? css.valid : css.invalid} data-test-id={symbols ? 'valid' : 'invalid'}>
        <span />
        {t('one_special_character')}
      </p>
    </div>
  )
}

export default PasswordValidation
