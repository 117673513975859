import ImageComponent from '../image'
import css from './index.module.scss'

export type StatusInfoProps = {
  text: string
  imagePath: string
}
export const StatusInfo = ({ text, imagePath }: StatusInfoProps) => {
  return (
    <div className={css.statusInfoContainer}>
      <ImageComponent alt="status image" width={24} height={24} src={imagePath} />
      <p className={css.text}>{text}</p>
    </div>
  )
}
