import { useEffect, useMemo } from 'react'

import aa from 'search-insights'

import { getUserType } from 'features/login/use-login'

import { useDynamoDbUserContext } from 'contexts/DynamoDbUserContext'
import { useIsLoggedIn } from 'hooks/use-is-logged-in'

declare const window: Window & { algoliaInsights: typeof aa | undefined }

const AlgoliaInsightsConfigure = () => {
  const { dynamoDbUser } = useDynamoDbUserContext()
  const { accessToken } = useIsLoggedIn()

  const userType = useMemo(() => (accessToken ? getUserType(accessToken) : undefined), [accessToken])

  useEffect(() => {
    window.algoliaInsights = aa
    window.algoliaInsights('init', {
      appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? '',
      apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ?? '',
    })
  }, [])

  useEffect(() => {
    if (!window.algoliaInsights) return
    if (!dynamoDbUser || userType !== 'Retailer') return

    window.algoliaInsights('setAuthenticatedUserToken', 'retailer-' + dynamoDbUser.cognitoUserId)
    window.algoliaInsights('setUserToken', 'retailer-' + dynamoDbUser.cognitoUserId)
  }, [dynamoDbUser, userType])

  return null
}

export default AlgoliaInsightsConfigure
