/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable id-denylist, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query'
import uuid from 'react-uuid'

import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { addSeconds, formatISO, isPast, parseISO } from 'date-fns'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

import { LoginResponse, isSSOLogin } from 'features/login/use-login'

import { MAX_COOKIES_DAY } from 'constants/constants'

export const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL ?? ''
export const ACCESS_TOKEN = 'access-token'
const TOKEN_EXPIRATION = 'token-expiration'
export const COGNITO_GROUP = 'cognito-group'
const SESSION_ID = 'session-id'

export const ADMIN_DEFAULT_DOMAIN = 'https://admin.dev.markato.cloud'
export type AccessToken = {
  sub: string
  'cognito:groups': string[]
  username: string
  profile: string
  preferred_username: string
  scope: string
}

const headers = {
  accept: 'application/json',
  'Content-type': 'application/json',
  'x-locale': Cookies.get('NEXT_LOCALE') ?? 'en-hk',
}

const httpClient = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers,
})

export const setSessionId = () => sessionStorage.setItem(SESSION_ID, uuid())
export const getSessionId = () => {
  let sessionId = sessionStorage.getItem(SESSION_ID)
  if (!sessionId) {
    sessionId = uuid()
    sessionStorage.setItem(SESSION_ID, sessionId)
  }
  return sessionId
}
export function getCookie(key: string) {
  return Cookies.get(key) ?? ''
}

export const getAccessToken = () => {
  const cookieImpersonate = getCookie('impersonated-token')

  if (cookieImpersonate && cookieImpersonate !== '') {
    return cookieImpersonate
  }
  return localStorage.getItem(ACCESS_TOKEN)
}
const getTokenExpirationTime = () => {
  const result = Cookies.get(TOKEN_EXPIRATION)
  if (!result) {
    const newExiprationDate = formatISO(new Date())
    Cookies.set(TOKEN_EXPIRATION, newExiprationDate, { expires: MAX_COOKIES_DAY })
    return newExiprationDate
  }
  return result
}

export const getTokenCognitoGroup = (accessToken: string) => {
  const decodedToken: AccessToken = jwtDecode(accessToken)
  return decodedToken['cognito:groups'].join(',')
}

export const setAccessToken = (accessToken: string, expiresIn: number) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken)
  Cookies.set(TOKEN_EXPIRATION, formatISO(addSeconds(new Date(), expiresIn - 20)), { expires: MAX_COOKIES_DAY })
  Cookies.set(COGNITO_GROUP, getTokenCognitoGroup(accessToken), { expires: MAX_COOKIES_DAY })
  // "Refresh" request interceptor to update it with a new token
  createRequestInterceptor()
}

export const deleteAccessToken = () => {
  Cookies.remove('impersonated-token')
  Cookies.remove(TOKEN_EXPIRATION)
  Cookies.remove(COGNITO_GROUP)
  localStorage.removeItem(ACCESS_TOKEN)
  // Clear request interceptor on logout
  httpClient.interceptors.request.clear()
  createRequestInterceptor()
}

const createRequestInterceptor = () => {
  httpClient.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = getAccessToken()
      config.headers.sessionId = getSessionId()
      if (token) {
        config.headers.Authorization = 'Bearer ' + token
      }
      return config
    },
    (error: any) => {
      return Promise.reject(error)
    }
  )
}

createRequestInterceptor()

export const getNewToken = () =>
  axios.post<LoginResponse>(
    `${BASE_URL}${getRefreshTokenUrl()}`,
    {},
    {
      withCredentials: true, // Passing the cookie api-refresh-token to BE
      headers: {
        sessionId: getSessionId(),
      },
    }
  )

httpClient.interceptors.response.use(
  (res: AxiosResponse) => {
    return res
  },
  async (err: any) => {
    const originalConfig = err?.config as AxiosRequestConfig
    const cookieImpersonate = getCookie('impersonated-token')
    const isImpersonateMode = cookieImpersonate && cookieImpersonate !== '' ? true : false
    const isTokenExpired = isPast(parseISO(getTokenExpirationTime()))
    const isSignInUrl = originalConfig.url === '/brand/auth/signin' || originalConfig.url === '/retailer/auth/signin'
    const isUnauthorized = err?.response?.status === 401

    if (!isImpersonateMode && !isSignInUrl && isUnauthorized && isTokenExpired) {
      try {
        const {
          data: { accessToken, expiresIn },
        } = await getNewToken()

        setAccessToken(accessToken, expiresIn)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`

        // Retry the http request
        return httpClient(originalConfig)
      } catch (error) {
        deleteAccessToken()
        window.location.reload()
        return Promise.reject(error)
      }
    }

    return Promise.reject(err)
  }
)

const getRefreshTokenUrl = () => {
  const accessToken = getAccessToken() ?? ''
  const decodedToken: AccessToken = jwtDecode(accessToken)
  let refreshTokenUrl = '/auth/refresh-token'
  if (decodedToken['cognito:groups'].find((group) => group === 'Brand')) {
    refreshTokenUrl = '/brand' + refreshTokenUrl
  }
  if (decodedToken['cognito:groups'].find((group) => group === 'Guest' || group === 'Retailer')) {
    refreshTokenUrl = '/retailer' + refreshTokenUrl
  }
  if (isSSOLogin(accessToken)) {
    refreshTokenUrl = '/retailer/auth/sso-refresh-token/' + decodedToken.username
  }
  return refreshTokenUrl
}

export const useRefreshToken = (isRefresh = true) =>
  useQuery({
    queryFn: async () => {
      const {
        data: { accessToken, expiresIn },
      } = await getNewToken()
      setAccessToken(accessToken, expiresIn)
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    },
    enabled: isRefresh,
  })

export const getErrorCodes = (error: any): ErrorCode[] => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const errorCodes = (error?.response?.data?.code as ErrorCode[]) ?? []

  if (errorCodes.length) {
    return errorCodes
  } else {
    return ['ERR_000']
  }
}

type ErrorCode = 'AUTH_001' | 'AUTH_003' | 'AUTH_008' | 'ERR_000' | 'AUTH_012'

export default httpClient
