import { SVGProps } from 'react'

const RegularWallet = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16 13.5C16.4333 13.5 16.7917 13.3583 17.075 13.075C17.3583 12.7917 17.5 12.4333 17.5 12C17.5 11.5667 17.3583 11.2083 17.075 10.925C16.7917 10.6417 16.4333 10.5 16 10.5C15.5667 10.5 15.2083 10.6417 14.925 10.925C14.6417 11.2083 14.5 11.5667 14.5 12C14.5 12.4333 14.6417 12.7917 14.925 13.075C15.2083 13.3583 15.5667 13.5 16 13.5ZM5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H19C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V7.5H19V5H5V19H19V16.5H21V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H5ZM13 17C12.45 17 11.9793 16.8043 11.588 16.413C11.196 16.021 11 15.55 11 15V9C11 8.45 11.196 7.979 11.588 7.587C11.9793 7.19567 12.45 7 13 7H20C20.55 7 21.021 7.19567 21.413 7.587C21.8043 7.979 22 8.45 22 9V15C22 15.55 21.8043 16.021 21.413 16.413C21.021 16.8043 20.55 17 20 17H13Z"
      fill="currentColor"
    />
  </svg>
)
export default RegularWallet
