import { MouseEvent, ReactNode, UIEvent, useEffect, useRef } from 'react'

import { fireTrackingEvent } from 'utils/tracking'

import { RegularClose } from '../icon/regular'
import styles from './modal.module.scss'

const Modal = ({
  isOpen,
  onChange,
  children,
  isCloseOnOutsideClick = true,
  trackName = '',
}: {
  isOpen: boolean
  onChange: () => void
  children: ReactNode
  isCloseOnOutsideClick?: boolean
  trackName?: string
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  // The following two lines are necessary because the <dialog> tag does not natively support closing
  // the dialog by clicking outside of it. Since a dialog takes up the whole screen, it registers the
  // click event wherever it is. The <div> inside of it (which encloses the content) simply stops the
  // propagation of that event thus not allowing a click inside to close the dialog.
  const handleOutsideDialogClick = () => (isCloseOnOutsideClick ? onChange() : null)
  const handleInsideDialogClick = (event: MouseEvent<HTMLDivElement>) => event.stopPropagation()

  // Necessary beacuse the default behaviour of the Esc key simply closes the modal while not changing
  // the isOpen value which we depend on. Without this, when the modal was closed by pressing Esc, the
  // next time a user tried to open it, it would not work.
  const handleEscClick = (event: UIEvent<HTMLDialogElement>) => {
    event.stopPropagation()
    isOpen && onChange()
  }

  useEffect(() => {
    if (dialogRef.current) {
      // eslint-disable-next-line
      typeof HTMLDialogElement !== 'function' && (window as any)?.dialogPolyfill.registerDialog(dialogRef.current)
      // eslint-disable-next-line
      isOpen
        ? !dialogRef.current.open && dialogRef.current.showModal()
        : dialogRef.current.open && dialogRef.current.close()
    }
  }, [isOpen, onChange])

  useEffect(() => {
    isOpen &&
      fireTrackingEvent('modal_open', {
        modalName: trackName,
        // triggeredBy: '', // TO-DO: Phase2
      })
  }, [isOpen, trackName])

  return (
    <dialog
      data-test-id={`modal-${trackName}`}
      onClose={handleEscClick}
      onClick={handleOutsideDialogClick}
      className={styles.dialog}
      ref={dialogRef}
    >
      <div className={styles.content} onClick={handleInsideDialogClick} ref={contentRef} tabIndex={0}>
        <RegularClose className={styles.closeBtn} onClick={onChange} data-test-id="btn-modal-close" />
        {children}
      </div>
    </dialog>
  )
}

export default Modal
