import { useMutation } from 'react-query'

import { ErrorResponse } from 'services/error-response'
import httpClient from 'services/http-client'

export const BRAND_LOGOUT = '/brand/auth/signout'
export const RETAILER_LOGOUT = '/retailer/auth/signout'
export const SSO_LOGOUT = '/retailer/auth/sso-signout'

export type LogoutTarget = {
  sessionId: string
  type: 'brand' | 'retailer' | 'sso'
}

const handleLogout = async (logout: LogoutTarget) => {
  let routeUrl = BRAND_LOGOUT
  if (logout.type === 'retailer') {
    routeUrl = RETAILER_LOGOUT
  } else if (logout.type === 'sso') {
    routeUrl = SSO_LOGOUT
  }
  return httpClient.post(`${routeUrl}`)
}

export const usePostLogout = (
  onSuccess: () => Promise<void> | void,
  onError: (errors: ErrorResponse) => Promise<void> | void
) =>
  useMutation(handleLogout, {
    onSuccess,
    onError,
  })

export const usePostSsoLogout = (
  onSuccess: () => Promise<void> | void,
  onError: (errors: ErrorResponse) => Promise<void> | void
) =>
  useMutation(handleLogout, {
    onSuccess,
    onError,
  })
