import { SVGProps } from 'react'

const SvgSolidMonitor = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.216 16.284C6.3595 16.428 6.5375 16.5 6.75 16.5H11.25C11.4625 16.5 11.6407 16.428 11.7847 16.284C11.9283 16.1405 12 15.9625 12 15.75C12 15.5375 11.9283 15.3595 11.7847 15.216C11.6407 15.072 11.4625 15 11.25 15H10.5V13.5H15C15.4125 13.5 15.7657 13.3533 16.0597 13.0597C16.3533 12.7657 16.5 12.4125 16.5 12V3.75C16.5 3.3375 16.3533 2.98425 16.0597 2.69025C15.7657 2.39675 15.4125 2.25 15 2.25H3C2.5875 2.25 2.2345 2.39675 1.941 2.69025C1.647 2.98425 1.5 3.3375 1.5 3.75V12C1.5 12.4125 1.647 12.7657 1.941 13.0597C2.2345 13.3533 2.5875 13.5 3 13.5H7.5V15H6.75C6.5375 15 6.3595 15.072 6.216 15.216C6.072 15.3595 6 15.5375 6 15.75C6 15.9625 6.072 16.1405 6.216 16.284ZM3 4.125C3 3.91789 3.16789 3.75 3.375 3.75H5.625C5.83211 3.75 6 3.91789 6 4.125V6.375C6 6.58211 5.83211 6.75 5.625 6.75H3.375C3.16789 6.75 3 6.58211 3 6.375V4.125ZM7.5 4.125C7.5 3.91789 7.66789 3.75 7.875 3.75H14.625C14.8321 3.75 15 3.91789 15 4.125V6.375C15 6.58211 14.8321 6.75 14.625 6.75H7.875C7.66789 6.75 7.5 6.58211 7.5 6.375V4.125Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSolidMonitor
