import { useEffect, useState } from 'react'

import Image from 'components/image'
import Logo from 'components/logo'
import Modal from 'components/modal'

import { useTranslate } from 'hooks/translation/use-translation'

import css from '../forgot-password/forgot-password.module.scss'
import { CreateNewPassword } from './Steps/create-new-password'

// import { ThankYou } from './Steps/thank-you'

type TempPasswordProps = {
  qs: {
    userType: string
    username: string
  }
  isOpen: boolean
  onChange: () => void
  onNewPasswordIsSet: () => void
}

export const TempPassword = ({ qs, isOpen, onChange, onNewPasswordIsSet: onNewPasswordIsSet }: TempPasswordProps) => {
  const { userType, username } = qs
  const [step, setStep] = useState<number>(0)
  const [session, setSession] = useState('')

  const { t } = useTranslate('registration')

  useEffect(() => {
    setSession(sessionStorage.getItem('NEW_PASSWORD_REQUIRED_SESSION') ?? '')
  }, [isOpen, session])

  const switchSteps = (step: number) => {
    switch (step) {
      // case 1:
      //   return <ThankYou setIsOpen={onChange} setStep={setStep} onLoginClick={onLoginClick} />
      case 0:
      default:
        return (
          <CreateNewPassword
            session={session}
            userType={userType}
            username={username}
            step={step}
            setStep={setStep}
            onNewPasswordIsSet={onNewPasswordIsSet}
          />
        )
    }
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onChange={() => {
          setStep(0)
          onChange()
        }}
        trackName="temp-password"
      >
        <div className={css.modalWrapper}>
          <div className={css.leftSideStyle}>
            <div className={css.logoStyle}>
              <Logo width={130} height={34.92} />
            </div>
            <div className={css.imageStyle}>
              <Image src="/images/reset-password.svg" width={200} height={165} alt="Forgot Password Image" />
            </div>
            <p className={css.titleStyle}>{t('Password reset')}</p>
          </div>
          <div className={css.rightSideStyle}>{switchSteps(step)}</div>
        </div>
      </Modal>
    </div>
  )
}
