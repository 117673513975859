import { useForm } from 'react-hook-form'

import { FORM_MODE } from 'utils/constants'

import { usePostConfirmForgotPassword } from './use-post-confirm-forgot-password'

export type PostForgotPasswordForm = {
  email: string
  confirmationCode: string
  newPassword: string
  confirmPassword: string
}

type ConfirmForgotPassword = {
  username: string
  code: string
  onSuccess: () => void
  onError: (error: string[]) => void
}

export const useConfirmForgotPassword = ({ username, code, onSuccess, onError }: ConfirmForgotPassword) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm<PostForgotPasswordForm>({
    mode: FORM_MODE,
    defaultValues: { confirmationCode: code },
  })

  const { mutateAsync: postConfirmForgotPassword, isLoading } = usePostConfirmForgotPassword(onSuccess, onError)

  const onSubmit = handleSubmit(
    async () =>
      await postConfirmForgotPassword({
        username,
        confirmationCode: getValues().confirmationCode,
        newPassword: getValues().newPassword,
        confirmPassword: getValues().confirmPassword,
      })
  )

  return {
    control,
    register,
    watch,
    errors,
    onSubmit,
    isValid,
    isLoading,
  }
}
