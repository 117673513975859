import router from 'next/router'
import { useMutation } from 'react-query'

import jwtDecode from 'jwt-decode'

import { useDynamoDbUserContext } from 'contexts/DynamoDbUserContext'

import httpClient, { AccessToken, getCookie, setAccessToken } from 'services/http-client'
import { fireTrackingEvent } from 'utils/tracking'

import { REDIRECT_TO_COOKIE } from 'constants/constants'
import { ROUTES } from 'constants/routes'

const USER_POOL = process.env.NEXT_PUBLIC_USER_POOL
const REDIRECT_URI = process.env.NEXT_PUBLIC_REDIRECT_URI
const CLIENT_ID = process.env.NEXT_PUBLIC_CLIENT_ID

export const GOOGLE_URL = `https://${USER_POOL!}.auth.ap-southeast-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=${REDIRECT_URI!}&response_type=CODE&client_id=${CLIENT_ID!}&scope=aws.cognito.signin.user.admin email openid`

const BRAND_LOGIN = '/brand/auth/signin'
const RETAILER_LOGIN = '/retailer/auth/signin'
const RETAILER_LOGIN_SSO = '/retailer/auth/sso-signin'

export type LoginRequest = {
  username: string
  password: string
}

export type SSOLoginRequest = {
  codeGrant: string
}

export type LoginResponse = {
  accessToken: string
  expiresIn: number
  challengeName?: string
  session?: string
  emailConfirmationSent?: boolean
}

const brandLogin = async (form: LoginRequest) => await httpClient.post<LoginResponse>(`${BRAND_LOGIN}`, form)
const retailerLogin = async (form: LoginRequest) => await httpClient.post<LoginResponse>(`${RETAILER_LOGIN}`, form)
const retailerLoginSSO = async (form: SSOLoginRequest) =>
  await httpClient.post<LoginResponse>(`${RETAILER_LOGIN_SSO}`, form)

export const useBrandLogin = () => useMutation(brandLogin)
export const useRetailerLogin = () => useMutation(retailerLogin)
export const useRetailerLoginSSO = () => useMutation(retailerLoginSSO)

export const getUserType = (accessToken: string): UserType | undefined => {
  const decodedToken: AccessToken = jwtDecode(accessToken)
  const cookieImpersonate = getCookie('impersonated-token')
  if (cookieImpersonate) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const decodedCookie = jwtDecode(cookieImpersonate) as AccessToken
    return decodedCookie.profile as UserType | undefined
  }
  let roles = decodedToken['cognito:groups']
  roles = roles.filter((role) => !role.toLowerCase().includes('google'))
  return roles[0] as UserType | undefined
}

export type UserType = 'Guest' | 'Retailer' | 'Brand'

export const isSSOLogin = (accessToken: string) => {
  const decodedToken: AccessToken = jwtDecode(accessToken)
  return decodedToken.username.includes('google') || decodedToken.scope.includes('openid')
}

export const useRetailerLoginSuccess = () => {
  const { getUserFromDynamo } = useDynamoDbUserContext()
  const onRetailerSuccess = async ({
    data: { accessToken, expiresIn },
    onChange,
  }: {
    data: { accessToken: string; expiresIn: number }
    onChange: () => void
  }) => {
    setAccessToken(accessToken, expiresIn)
    onChange()
    if (getUserType(accessToken) === 'Guest') {
      await router.push(ROUTES.RETAILER_ONBOARDING)
    } else {
      getUserFromDynamo((userInfoJson, cognitoId) => {
        const REDIRECT_URL = sessionStorage.getItem(REDIRECT_TO_COOKIE)
        if (REDIRECT_URL) {
          void router.push(REDIRECT_URL)
        } else {
          window.location.reload()
        }
        sessionStorage.removeItem(REDIRECT_TO_COOKIE)
        fireTrackingEvent('success', {
          name: 'success_login_retailer',
          detail: {
            name: `${userInfoJson?.firstName ?? ''} ${userInfoJson?.lastName ?? ''}`,
            email: userInfoJson?.email,
            group: 'retailer',
            cognitoId: cognitoId,
          },
        })
      })
    }
  }

  return { onRetailerSuccess }
}
