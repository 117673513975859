import { ClipLoader } from 'react-spinners'

import css from './spinner.module.scss'

type SpinnerProps = {
  loading?: boolean
  size?: number
} & React.HTMLAttributes<HTMLElement>

const Spinner = (props: SpinnerProps) => {
  const { size = 36, loading, id, ...rest } = props
  return loading ? (
    <div data-test-id={id} role="progressbar" {...rest}>
      <ClipLoader size={`${size}px`} data-cy="spn_Loader" speedMultiplier={0.86} loading={loading} color={css.color} />
    </div>
  ) : null
}

export default Spinner
