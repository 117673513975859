import { useRef, useState } from 'react'

import cn from 'classnames'
import { NavMenuItem } from 'interfaces/navMenu'
import { isEmpty } from 'lodash'

import Link from 'components/link'
import { getCategoryURL } from 'components/molecules/autocomplete/utils'

import { useOnHoverOutside } from 'hooks/common/useOnHoverOutside'

import { NavMenu } from './nav-menu'
import css from './navbar.module.scss'
import { getTopLevelItemName } from './utils'

type NavMenuItemProps = {
  navMenuItem: NavMenuItem
  isNavbarHovering: boolean
}

export const NavMenuItemComponent = ({ navMenuItem, isNavbarHovering }: NavMenuItemProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [showDropdown, setShowDropdown] = useState(false)
  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null)

  const closeHoverMenu = () => {
    setShowDropdown(false)
    if (hoverTimeout) {
      clearTimeout(hoverTimeout)
      setHoverTimeout(null)
    }
  }
  useOnHoverOutside(dropdownRef, closeHoverMenu)

  const { tag, categoryNav, url, textColor } = navMenuItem

  // Force hide the menu item
  if (textColor?.toLocaleLowerCase() === '#fffbf0') {
    return null
  }

  const allowDropdown = !isEmpty(categoryNav) && tag !== 'promotion'

  return (
    <>
      <div ref={dropdownRef}>
        <Link
          href={categoryNav?.nameEn ? getCategoryURL([categoryNav.nameEn]) : url}
          className={cn([
            css.itemWrapper,
            tag === 'promotion' && css.itemWrapperPromotion,
            showDropdown && css.itemWrapperDropdown,
          ])}
          onTouchStart={() => setShowDropdown(!showDropdown)}
          onMouseOver={() => {
            // show dropdown after 100ms to avoid unintentional showing
            if (allowDropdown) {
              if (isNavbarHovering) {
                setShowDropdown(true)
              } else {
                const timeoutId = setTimeout(() => {
                  setShowDropdown(true)
                }, 100)
                setHoverTimeout(timeoutId)
              }
            }
          }}
        >
          {getTopLevelItemName(navMenuItem)}
        </Link>
        {!!allowDropdown && !!showDropdown && (
          <NavMenu navMenuItem={navMenuItem} onCloseMenu={() => setShowDropdown(false)} />
        )}
      </div>

      {!!allowDropdown && <div className={cn([showDropdown && css.backdrop])} />}
    </>
  )
}
