import Link from 'components/link'

import css from './autocomplete-source-item.module.scss'

type SourceItemProps = {
  name: string
  href: string
  langSubPath: string
  imageComponent?: JSX.Element
} & React.ComponentProps<'a'> &
  React.AnchorHTMLAttributes<HTMLAnchorElement>

const AutocompleteSourceItem = ({ langSubPath, href = '#', name, imageComponent, ...props }: SourceItemProps) => {
  return (
    <Link customLangSubPath={langSubPath} href={href} className={css.link} {...props}>
      {imageComponent}
      <p className={css.itemTxt}>{name}</p>
    </Link>
  )
}

export default AutocompleteSourceItem
