import { SVGProps } from 'react'

const SvgRegularReturnClean = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5071 1.13807C12.2017 0.958441 11.8241 0.953792 11.5144 1.12584L2.51436 6.12583C2.20074 6.30006 2.00449 6.62893 2.00008 6.98767C2.00003 6.99179 2 6.99588 2 7V15V17C2 17 2 17 2 17C2.00002 17.3543 2.18756 17.6822 2.49298 17.8619L10.993 22.8619C11.2984 23.0415 11.676 23.0461 11.9857 22.8741L20.9858 17.8741C21.3033 17.6977 21.5001 17.3631 21.5001 17V6.99998C21.5002 6.64594 21.3129 6.31787 21.0072 6.13805L12.5071 1.13807ZM4 15H4.00002V16.428L10.5001 20.2515V12.5719L8.4999 11.3954V14.3957C8.4999 14.5328 8.4077 14.655 8.26938 14.7021C8.23008 14.7155 8.19001 14.7218 8.14919 14.7218L8.14844 14.7225C8.04715 14.7225 7.94813 14.681 7.8801 14.6058L6.99009 13.4576L6.01125 13.7224C5.9145 13.7274 5.82379 13.6922 5.75501 13.6311C5.68698 13.57 5.64844 13.4849 5.64844 13.3964V9.71804L4 8.74838V15ZM11.5143 10.8481L9.30157 9.54657L16.213 5.63832L18.4856 6.97518L11.5143 10.8481ZM6.62876 7.97434L13.6499 4.13065L11.9859 3.15183L5.0145 7.02479L6.62876 7.97434ZM19.5001 8.69949L12.5001 12.5884V20.3004L19.5001 16.4115V8.69949Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgRegularReturnClean
