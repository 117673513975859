import { SVGProps } from 'react'

const RegularTransaction = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.7877 5.34364C15.855 5.41386 15.908 5.49684 15.944 5.58787C16.0183 5.77148 16.0183 5.97767 15.944 6.16128C15.908 6.25231 15.855 6.33529 15.7877 6.4055L13.705 8.52923C13.5669 8.67004 13.3796 8.74915 13.1843 8.74915C12.989 8.74915 12.8017 8.67004 12.6636 8.52923C12.5255 8.38842 12.448 8.19744 12.448 7.9983C12.448 7.79916 12.5255 7.60818 12.6636 7.46737L13.4915 6.62319L2.73938 6.62837C2.64218 6.62877 2.54585 6.60955 2.45596 6.57181C2.36608 6.53406 2.28441 6.47855 2.21568 6.40846C2.14694 6.33837 2.09249 6.25509 2.05548 6.16344C2.01846 6.07179 1.99961 5.97357 2.00001 5.87445C1.99961 5.77533 2.01846 5.67711 2.05548 5.58545C2.09249 5.4938 2.14694 5.41053 2.21568 5.34044C2.28441 5.27035 2.36608 5.21483 2.45597 5.17709C2.54585 5.13934 2.64217 5.12012 2.73938 5.12052L13.4915 5.12596L12.6636 4.28178C12.5255 4.14097 12.448 3.94999 12.448 3.75085C12.448 3.55171 12.5255 3.36073 12.6636 3.21992C12.8017 3.07911 12.989 3 13.1843 3C13.3796 3 13.5669 3.07911 13.705 3.21992L15.7877 5.34364Z"
      fill="currentColor"
    />
    <path
      d="M2.21226 12.5945C2.14504 12.6647 2.09197 12.7477 2.05605 12.8387C1.98166 13.0223 1.98166 13.2285 2.05605 13.4121C2.09197 13.5032 2.14504 13.5861 2.21226 13.6564L4.295 15.7801C4.43309 15.9209 4.62039 16 4.81569 16C5.01098 16 5.19828 15.9209 5.33637 15.7801C5.47447 15.6393 5.55205 15.4483 5.55205 15.2492C5.55205 15.05 5.47447 14.859 5.33637 14.7182L4.50848 13.874L15.2606 13.8792C15.3578 13.8796 15.4542 13.8604 15.544 13.8227C15.6339 13.7849 15.7156 13.7294 15.7843 13.6593C15.8531 13.5892 15.9075 13.5059 15.9445 13.4143C15.9815 13.3226 16.0004 13.2244 16 13.1253C16.0004 13.0262 15.9815 12.928 15.9445 12.8363C15.9075 12.7447 15.8531 12.6614 15.7843 12.5913C15.7156 12.5212 15.6339 12.4657 15.544 12.4279C15.4541 12.3902 15.3578 12.371 15.2606 12.3714L4.50848 12.3768L5.33637 11.5326C5.47447 11.3918 5.55205 11.2008 5.55205 11.0017C5.55205 10.8026 5.47447 10.6116 5.33637 10.4708C5.19828 10.33 5.01098 10.2509 4.81569 10.2509C4.62039 10.2509 4.4331 10.33 4.295 10.4708L2.21226 12.5945Z"
      fill="currentColor"
    />
  </svg>
)

export default RegularTransaction
