import { SVGProps } from 'react'

const SvgSolidArrowChevronLeft = ({ width = '1em', height = '1em', ...props }: SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m13.3 17.3-4.6-4.6a.871.871 0 0 1-.212-.325A1.098 1.098 0 0 1 8.425 12a1.1 1.1 0 0 1 .063-.375.872.872 0 0 1 .212-.325l4.6-4.6a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L10.8 12l3.9 3.9a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSolidArrowChevronLeft
