import { createContext, useContext } from 'react'

/**
 A function to force a developer to follow some rules when creating a new context
 Example to follow after declaration
 */

function createSafeContext<T>(defaultValue?: T): {
  readonly hook: () => T
  readonly Provider: React.Provider<T | undefined>
} {
  const context = createContext<T | undefined>(defaultValue)

  const useSafeContext = () => {
    const value = useContext(context)
    if (!value) {
      throw new Error('useContext must be inside a Provider with a value')
    }

    return value
  }

  return {
    hook: useSafeContext,
    Provider: context.Provider,
  } as const
}

export default createSafeContext
