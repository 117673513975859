import config from '../aws-exports'
import configProd from '../aws-exports-prod'
import configQA from '../aws-exports-qa'
import configStage from '../aws-exports-stage'

export const useEnvironmentConfig = () => {
  const environment = process.env.NEXT_PUBLIC_ENVIRONMENT

  let awsConfig = config
  switch (environment) {
    case 'qa':
      awsConfig = configQA
      break
    case 'stage':
    case 'staging':
      awsConfig = configStage
      break
    case 'prod':
      awsConfig = configProd
      break
    default:
      awsConfig = config
  }
  return { awsConfig }
}
