import { useMutation } from 'react-query'

import { ErrorResponse } from 'services/error-response'
import httpClient from 'services/http-client'

export const CART_BRANDS = '/cart/brands'

type DecreaseItemProps = { brandKey: string; variantKey: string }

const decreaseItemInCart = async ({ brandKey, variantKey }: DecreaseItemProps) => {
  return await httpClient.put(`${CART_BRANDS}/${brandKey}/items/decrease`, { variantKey: variantKey })
}
export const useDecreaseItemInCart = (onError: (errors: ErrorResponse) => void) =>
  useMutation(decreaseItemInCart, { onError })
