// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_appsync_graphqlEndpoint: 'https://v5znxgkax5au5ibrzwckd6jqz4.appsync-api.ap-southeast-1.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-bqlpctq7ezbbdh2v634t2c7mgi',
}

export default awsmobile
