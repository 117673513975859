import { useMutation } from 'react-query'

import { ErrorResponse } from 'services/error-response'
import httpClient from 'services/http-client'

import { RetailerRegistrationFields } from './use-retailer-sign-up-form'

export const REGISTER_RETAILER = '/retailer/auth/signup'

export type RetailerRegisterRequestBody = {
  username: string
  password: string
  confirmPassword: string
}

const registerRetailer = async (form: RetailerRegistrationFields) =>
  httpClient.post(`${REGISTER_RETAILER}`, {
    username: form.email.trim(),
    password: form.password,
    confirmPassword: form.password,
  })

export const useRegisterRetailer = (onSuccess: () => void | Promise<void>, onError: (errors: ErrorResponse) => void) =>
  useMutation(registerRetailer, { onSuccess, onError })
