import { SVGProps } from 'react'

const SvgLightArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.475 19.1a.734.734 0 0 1-.225-.538c0-.208.067-.387.2-.537l5.3-5.275H5.125a.74.74 0 0 1-.525-.213.706.706 0 0 1-.225-.537.71.71 0 0 1 .225-.538.741.741 0 0 1 .525-.212H16.75l-5.3-5.275a.781.781 0 0 1-.2-.537c0-.209.075-.388.225-.538.15-.133.325-.2.525-.2s.375.067.525.2l6.45 6.475c.1.083.171.179.213.287a.943.943 0 0 1 0 .675.705.705 0 0 1-.213.288l-6.45 6.475a.766.766 0 0 1-.525.2.766.766 0 0 1-.525-.2Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightArrowRight
