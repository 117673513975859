import { SVGProps } from 'react'

const SvgLightArrowChevronOutward = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.375 8.15a.748.748 0 0 1-.225-.55c0-.217.075-.4.225-.55l3-3a.824.824 0 0 1 .288-.188.935.935 0 0 1 .675 0 .825.825 0 0 1 .287.188l3.025 3.025c.15.15.221.33.213.538a.781.781 0 0 1-.238.537.71.71 0 0 1-.525.2.798.798 0 0 1-.55-.2L12 5.6 9.45 8.15a.709.709 0 0 1-.55.213.755.755 0 0 1-.525-.213ZM12 20.25a.934.934 0 0 1-.337-.062.823.823 0 0 1-.288-.188l-3-3a.748.748 0 0 1-.225-.55c0-.217.075-.392.225-.525A.669.669 0 0 1 8.9 15.7c.217 0 .4.075.55.225l2.55 2.55 2.55-2.575c.15-.15.333-.22.55-.212a.706.706 0 0 1 .525.237c.15.133.225.308.225.525 0 .217-.075.4-.225.55l-3 3a.824.824 0 0 1-.287.188.941.941 0 0 1-.338.062Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightArrowChevronOutward
