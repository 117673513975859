import { ChangeEvent, useEffect, useState } from 'react'

import cn from 'classnames'
import RegularCheck from 'public/images/icons/checkbox-regular-checked.svg'

import Label from '../label'
import css from './checkbox.module.scss'

export type CheckboxProps = {
  id: string
  label?: string
  name: string
  isChecked?: boolean
  isDisabled?: boolean
  isLabelRight?: boolean
  testId?: string
  errorMessage?: string
  indeterminate?: boolean
  onChange: (isChecked: boolean) => void | Promise<void>
  className?: string
  CheckedIcon?: JSX.Element | null
}

const Checkbox = ({
  id,
  label,
  name,
  isChecked = false,
  isLabelRight,
  isDisabled,
  testId = '',
  onChange,
  indeterminate,
  className,
  CheckedIcon = null,
}: CheckboxProps) => {
  const [checked, setChecked] = useState(isChecked)

  useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  const handleCheck = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    setChecked(changeEvent.target.checked)
    void onChange(changeEvent.target.checked)
  }

  return (
    <div className={cn(css.container, className, isLabelRight && css.reverse)} data-test-id={`${testId}-container`}>
      <Label htmlFor={id}>
        <>
          {!!label && <span className={css.label}>{label}</span>}

          <input
            aria-checked={checked}
            type="checkbox"
            onChange={handleCheck}
            id={id}
            disabled={isDisabled}
            name={name}
            checked={checked}
            data-test-id={testId}
            hidden
          />

          <div
            data-test-id={`${testId}-icon`}
            className={cn(
              css.button,
              checked && css.checked,
              isDisabled && css.disabled,
              indeterminate ? css.indeterminate : ''
            )}
          >
            {checked ? (
              <span className={isDisabled ? css.disabledIcon : css.icon}>{CheckedIcon ?? <RegularCheck />}</span>
            ) : indeterminate ? (
              <span> - </span>
            ) : (
              <span className={isDisabled ? css.disabledIcon : css.iconTransparent}>
                {isDisabled ? null : CheckedIcon ?? <RegularCheck />}
              </span>
            )}
          </div>
        </>
      </Label>
    </div>
  )
}

export default Checkbox
