import { SVGProps } from 'react'

const SvgLightPerson = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5272 3.83824C10.5272 5.25143 9.39615 6.39706 8.00091 6.39706C6.60566 6.39706 5.47459 5.25143 5.47459 3.83824C5.47459 2.42504 6.60566 1.27941 8.00091 1.27941C9.39615 1.27941 10.5272 2.42504 10.5272 3.83824ZM11.7904 3.83824C11.7904 5.95803 10.0938 7.67647 8.00091 7.67647C5.90804 7.67647 4.21143 5.95803 4.21143 3.83824C4.21143 1.71844 5.90804 0 8.00091 0C10.0938 0 11.7904 1.71844 11.7904 3.83824ZM5.89474 9.80882H10.1053C12.2253 9.80882 14.0127 11.2516 14.5625 13.2206H1.4375C1.98734 11.2516 3.77469 9.80882 5.89474 9.80882ZM10.1053 8.52941C12.9273 8.52941 15.2861 10.5379 15.8643 13.2206C15.9532 13.6328 16 14.0608 16 14.5H0C0 14.0608 0.0468109 13.6328 0.135655 13.2206C0.713903 10.5379 3.07274 8.52941 5.89474 8.52941H10.1053Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightPerson
