import { useRouter } from 'next/router'
import { Dispatch, SetStateAction } from 'react'

import Button from 'components/button'
import Image from 'components/image'

import { useTranslate } from 'hooks/translation/use-translation'

import { ROUTES } from 'constants/routes'

import css from '../forgot-password.module.scss'

type ThankYouProps = {
  setIsOpen: () => void
  setStep: Dispatch<SetStateAction<number>>
  onLoginClick: () => void
  setUsername: (username: string) => void
}

export const ThankYou = ({ setIsOpen, setStep, onLoginClick, setUsername }: ThankYouProps) => {
  const { t } = useTranslate('registration')
  const router = useRouter()
  setUsername('')

  return (
    <div className={css.thankYouWrapperStyle}>
      <div className={css.imageWrapperStyle}>
        <Image src="/images/check.svg" alt="Check Icon" width={36} height={36} />
      </div>
      <p className={css.headingStyle} data-test-id="password-changed-success-title">
        {t('Password Changed')}
      </p>
      <p className={css.textStyle}>{t('Please login with the new password')}</p>

      <Button
        onClick={async () => {
          await router.push(ROUTES.HOME)
          setStep(0)
          setIsOpen()
          onLoginClick()
        }}
        style="secondary"
        size="large"
        label={t('Login')}
        className={css.buttonWrapperStyle}
      />
    </div>
  )
}
