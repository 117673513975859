import React from 'react'

import cn from 'classnames'

import css from './menu.module.scss'

type MenuProps = {
  children: JSX.Element[] | JSX.Element
  className?: string
}

const Menu = ({ children, className }: MenuProps) => (
  <div className={cn(css.dropDown, className)} role="menu">
    {children}
  </div>
)

export default Menu
