export { default as RegularArrowChevronDown } from './RegularArrowChevronDown'
export { default as RegularArrowChevronLeft } from './RegularArrowChevronLeft'
export { default as RegularArrowChevronOutward } from './RegularArrowChevronOutward'
export { default as RegularArrowChevronRight } from './RegularArrowChevronRight'
export { default as RegularArrowChevronUp } from './RegularArrowChevronUp'
export { default as RegularArrowDown } from './RegularArrowDown'
export { default as RegularArrowLeft } from './RegularArrowLeft'
export { default as RegularArrowRefresh } from './RegularArrowRefresh'
export { default as RegularArrowRight } from './RegularArrowRight'
export { default as RegularArrowUp } from './RegularArrowUp'
export { default as RegularBell } from './RegularBell'
export { default as RegularCalendarEvent } from './RegularCalendarEvent'
export { default as RegularCall } from './RegularCall'
export { default as RegularCheck } from './RegularCheck'
export { default as RegularCheckCircle } from './RegularCheckCircle'
export { default as RegularClock } from './RegularClock'
export { default as RegularClose } from './RegularClose'
export { default as RegularContentCopy } from './RegularContentCopy'
export { default as RegularContentPaste } from './RegularContentPaste'
export { default as RegularDelete } from './RegularDelete'
export { default as RegularDocument } from './RegularDocument'
export { default as RegularDotsMoreHorizontal } from './RegularDotsMoreHorizontal'
export { default as RegularDotsMoreVertical } from './RegularDotsMoreVertical'
export { default as RegularDownload } from './RegularDownload'
export { default as RegularError } from './RegularError'
export { default as RegularFilter } from './RegularFilter'
export { default as RegularGrid } from './RegularGrid'
export { default as RegularHelp } from './RegularHelp'
export { default as RegularHome } from './RegularHome'
export { default as RegularInfo } from './RegularInfo'
export { default as RegularLines } from './RegularLines'
export { default as RegularLink } from './RegularLink'
export { default as RegularList } from './RegularList'
export { default as RegularLocation } from './RegularLocation'
export { default as RegularLocationPin } from './RegularLocationPin'
export { default as RegularLocationArrow } from './RegularLocationArrow'
export { default as RegularMessageText } from './RegularMessageText'
export { default as RegularMinus } from './RegularMinus'
export { default as RegularBus } from './RegularBus'
export { default as RegularNote } from './RegularNote'
export { default as RegularPlus } from './RegularPlus'
export { default as RegularPrint } from './RegularPrint'
export { default as RegularReorderAlt } from './RegularReorderAlt'
export { default as RegularSearch } from './RegularSearch'
export { default as RegularSettingsGear } from './RegularSettingsGear'
export { default as RegularShoppingCart } from './RegularShoppingCart'
export { default as RegularStar } from './RegularStar'
export { default as RegularUpload } from './RegularUpload'
export { default as RegularWarningTriangle } from './RegularWarningTriangle'
export { default as RegularZoomIn } from './RegularZoomIn'
export { default as RegularZoomOut } from './RegularZoomOut'
export { default as SvgRegularEye } from './RegularEye'
export { default as SvgRegularHideEye } from './RegularHideEye'
export { default as RegularLock } from './RegularLock'
export { default as RegularLockDetailed } from './RegularLockDetailed'
export { default as RegularLogout } from './RegularLogout'
export { default as RegularHeart } from './RegularHeart'
export { default as RegularPerson } from './RegularPerson'
export { default as RegularShoppingBag } from './RegularShoppingBag'
export { default as RegularWallet } from './RegularWallet'
export { default as RegularCoin } from './RegularCoin'
export { default as RegularTransaction } from './RegularTransaction'
export { default as RegularCreditCard } from './RegularCreditCard'
export { default as RegularBank } from './RegularBank'
export { default as RegularPaymentTerms } from './RegularPaymentTerms'
export { default as RegularReturnClean } from './RegularReturnClean'
