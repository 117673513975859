import { useCallback } from 'react'

import cn from 'classnames'
import { NavMenuItem } from 'interfaces/navMenu'
import { isEmpty } from 'lodash'

import Link from 'components/link'
import { getCategoryURL } from 'components/molecules/autocomplete/utils'

import { useTranslate } from 'hooks'

import { Navigation } from 'services/fetches/navigation'

import css from '../navbar.module.scss'
import { getBrandHighlightSection, getMenuTopLeftSection } from '../utils'

export type SubcategoriesProps = {
  navMenuItem: NavMenuItem
  onCloseMenu: () => void
}

const NUMBER_BUCKETS = 3

const navPlaceHolder: Navigation = {
  id: '_placeHolder',
  name: '_placeHolder',
  nameEn: '_placeHolder',
  key: '_placeHolder',
}

export const NavMenu = ({ navMenuItem, onCloseMenu }: SubcategoriesProps) => {
  const { t } = useTranslate('strapi-component-app-header')
  const { children: customMenuItems, categoryNav: category } = navMenuItem

  const menuTopLeftSection = getMenuTopLeftSection(customMenuItems, category)
  const brandHighlightMenuItem = getBrandHighlightSection(customMenuItems)
  const buckets: Navigation[][] = Array.from({ length: NUMBER_BUCKETS }, () => [])

  const subCategories = category?.children ?? []

  // fill up the first bucket for menu's top left section
  if (menuTopLeftSection) {
    const sectionPlaceHolder: Navigation[] = Array.from(
      { length: menuTopLeftSection.children.length },
      () => navPlaceHolder
    )

    buckets[0].push(...sectionPlaceHolder)
  }

  // Sort the array in descending order for bucketing
  const sortedSubCatItems = subCategories.sort(
    (catA, catB) => (catB.children?.length ?? 0) - (catA.children?.length ?? 0)
  )

  // equally distributing (in terms of length of children) each subcategories into the bucket inside buckets
  for (const item of sortedSubCatItems) {
    // Find bucket with the smallest sum
    const minSumBucket = buckets.reduce((minBucket, currentBucket) => {
      const minBucketSum = minBucket.reduce((a, b) => a + (b.children?.length ?? 0) + 1, 0)
      const currentBucketSum = currentBucket.reduce((a, b) => a + (b.children?.length ?? 0) + 1, 0)
      return minBucketSum <= currentBucketSum ? minBucket : currentBucket
    })

    // push the item to the bucket with the smallest sum
    minSumBucket.push(item)
  }

  const renderSubcategory = useCallback(
    (subcategories: Navigation[], onClick: () => void) => {
      return subcategories.map((subcategory) => {
        const categories = [category?.nameEn ?? '', subcategory.nameEn]
        const href = subcategory.href

        if (subcategory.key === navPlaceHolder.key) {
          return null
        }

        const withoutChildren = isEmpty(subcategory.children)
        return (
          <ul key={subcategory.nameEn} className={cn([css.subCategoryList, withoutChildren && css.withoutChildren])}>
            <li key="header">
              <Link
                key={subcategory.id}
                href={href ?? getCategoryURL(categories)}
                onClick={onClick}
                className={css.linkL2Text}
                isNextLink
                prefetch={false}
              >
                {t('Navbar.Navbar_all') + subcategory.name}
              </Link>
            </li>
            {subcategory.children?.map((l3Cat) => {
              const l3Categories = [category?.nameEn ?? '', subcategory.nameEn, l3Cat.nameEn]
              return (
                <li key={l3Cat.id} className={css.l3ListItem}>
                  <Link
                    className={css.l3Link}
                    href={getCategoryURL(l3Categories)}
                    onClick={onClick}
                    isNextLink
                    prefetch={false}
                  >
                    {l3Cat.name.replaceAll('/', ' / ')}
                  </Link>
                </li>
              )
            })}
          </ul>
        )
      })
    },
    [category, t]
  )

  const renderMenuItemLinkSection = useCallback((menuItem: NavMenuItem, onClick: () => void) => {
    const withoutChildren = isEmpty(menuItem.children)
    const withoutUrl = !menuItem.url
    return (
      <ul className={cn([withoutChildren && css.withoutChildren])} key={menuItem.name}>
        <li key={menuItem.name}>
          <Link
            href={withoutUrl ? '' : menuItem.url}
            isNextLink
            className={cn(css.linkL2Text, withoutUrl && css.noUrlText)}
            style={menuItem.textColor ? { color: menuItem.textColor } : undefined}
            disabled={withoutUrl}
            onClick={onClick}
            prefetch={false}
          >
            {menuItem.name}
          </Link>
        </li>
        {menuItem.children.map(({ name, url }) => (
          <li key={name} className={css.l3ListItem}>
            <Link href={url} onClick={onClick} className={css.l3Link} isNextLink prefetch={false}>
              {name}
            </Link>
          </li>
        ))}
      </ul>
    )
  }, [])

  return (
    <div className={css.subcategories}>
      <section className={cn([css.subcontent])}>
        <div className={css.column}>
          {!!menuTopLeftSection && (
            <>
              {menuTopLeftSection.children.map((item) => renderMenuItemLinkSection(item, onCloseMenu))}
              <div className={css.separator} />
            </>
          )}
          {renderSubcategory(buckets[0], onCloseMenu)}
        </div>

        {[buckets[1], buckets[2]].map((subcategories) => {
          if (subcategories.length === 0) return null
          return (
            <div key={subcategories[0].key} className={css.column}>
              {renderSubcategory(subcategories, onCloseMenu)}
            </div>
          )
        })}

        <div className={css.column}>
          {!!brandHighlightMenuItem && renderMenuItemLinkSection(brandHighlightMenuItem, onCloseMenu)}
        </div>
      </section>
      {/* <section className={cn([css.subcontent])}>
        {Array.from({ length: 4 }, () => []).map((_, index) => {
          return (
            <div className={css.column} key="index">
              <ImageComponent
                className={css.image}
                width={160}
                height={160}
                src={'/images/navigation/homeAndLivingCategory.jpg'}
                alt={'test'}
                quality={100}
              />
            </div>
          )
        })}
      </section> */}
    </div>
  )
}
