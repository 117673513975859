import React from 'react'

import cn from 'classnames'

import { SolidCheckCircle, SolidError, SolidThumbUp } from 'components/icon/solid'

import { useTranslate } from 'hooks'

import css from './toast.module.scss'

export type ToastType = 'info' | 'success' | 'warning' | 'alert'

export type ToastProps = {
  type?: ToastType
  messages: string[]
  hidden?: boolean
  timeout?: number
  onClose?: (...props: unknown[]) => void
}

const toastIcon = {
  info: <SolidThumbUp />,
  success: <SolidCheckCircle />,
  warning: <SolidError />,
  alert: <SolidError />,
}

const Toast = ({ type = 'success', messages, hidden = false, ...props }: ToastProps): JSX.Element => {
  const { t } = useTranslate('common')

  return (
    <div role="status" {...props} className={cn(css.container, css[type], hidden ? css.close : '')}>
      {messages.map((message) => (
        <div key={message} className={css.message}>
          <div className={css.icon}>{toastIcon[type]}</div>
          <div className={css.information}>
            <p>{t(message)}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Toast
