export const INSTANT_SEARCH_BRAND_REPLICAS_INDEX = {
  brand: {
    label: 'PageSearch.PageSearch_Filters_relevance',
    name: 'brand',
    query: 'brand',
  },
  brand_new_published: {
    label: 'PageSearch.PageSearch_Filters_new_publish',
    name: 'brand_new_published',
    query: 'brand_new_published',
  },
  brand_mov_asc: {
    label: 'PageSearch.PageSearch_Filters_mov_asc',
    name: 'brand_mov_asc',
    query: 'brand_mov_asc',
  },
  brand_asc: {
    label: 'PageSearch.PageSearch_Filters_sort_by_brand_asc',
    name: 'brand_asc',
    query: 'brand_asc',
  },
  brand_desc: {
    label: 'PageSearch.PageSearch_Filters_sort_by_brand_desc',
    name: 'brand_desc',
    query: 'brand_desc',
  },
}

export const INSTANT_SEARCH_PRIMARY_REPLICAS_INDEX = {
  'variants-from-connector': {
    label: 'PageSearch.PageSearch_Filters_relevance',
    name: 'variants-from-connector',
    query: '',
  },
  'variants-from-connector_new_published': {
    label: 'PageSearch.PageSearch_Filters_new_publish',
    name: 'variants-from-connector_new_published',
    query: 'product_new_published',
  },

  'variants-from-connector_price_asc': {
    label: 'PageSearch.PageSearch_Filters_price_asc',
    name: 'variants-from-connector_price_asc',
    query: 'price_asc',
  },
  'variants-from-connector_price_desc': {
    label: 'PageSearch.PageSearch_Filters_price_desc',
    name: 'variants-from-connector_price_desc',
    query: 'price_desc',
  },
}

export const INSTANT_SEARCH_INDEX = {
  ...INSTANT_SEARCH_PRIMARY_REPLICAS_INDEX,
  ...INSTANT_SEARCH_BRAND_REPLICAS_INDEX,
}

export const PRIMARY_SEARCH_INDEX = INSTANT_SEARCH_INDEX['variants-from-connector'].name
export const INSTANT_SEARCH_QUERY_SUGGESTIONS = 'variants-from-connector_query_suggestions'

export const INSTANT_SEARCH_ATTRIBUTES = {
  BRAND_NAMES: { attr: 'attributes.brand-name', query: 'brand' },
  BRAND_KEYS: { attr: 'attributes.brand-key', query: 'brandKey' },
  BRAND_LOCATION: { attr: 'attributes.brand-location', query: 'origin' },
  MADE_IN: { attr: 'attributes.made-in', query: 'madein' },
  ON_SALE_PERCENTAGE: { attr: 'attributes.on-sale-percentage', query: 'onsale' },
  PRODUCTION_LEAD_TIME: { attr: 'attributes.production-lead-time', query: 'leadtime' },
  UNIVERSAL_PRICE: { attr: 'attributes.universal-price', query: 'wprice' },
  UNIVERSAL_MSRP: { attr: 'attributes.universal-msrp', query: 'rprice' },
  UNIVERSAL_BRAND_MOV: { attr: 'attributes.universal-brand-mov', query: 'mov' },
  BEST_SELLER: { attr: 'attributes.best-seller', query: 'bestseller' },
  'en-hk': {
    CATEGORIES_LV0: { attr: 'categories.en-HK.lvl0', query: 'cat0' },
    CATEGORIES_LV1: { attr: 'categories.en-HK.lvl1', query: 'cat1' },
    CATEGORIES_LV2: { attr: 'categories.en-HK.lvl2', query: 'cat3' },
  },
  'zh-hk': {
    CATEGORIES_LV0: { attr: 'categories.zh-HK.lvl0', query: 'cat0' },
    CATEGORIES_LV1: { attr: 'categories.zh-HK.lvl1', query: 'cat1' },
    CATEGORIES_LV2: { attr: 'categories.zh-HK.lvl2', query: 'cat3' },
  },
  FIRST_PUBLISH_DATE_UNIX: {
    attr: 'attributes.first-publish-date-unix',
    query: 'firstPublish',
  },
  PRIMARY_PRODUCT_TYPE_ID: {
    attr: 'attributes.primary-product-type.id',
    query: 'primaryProductTypeId',
  },
  KEYWORDS: {
    attr: 'attributes.keywords',
    query: 'keywords',
  },
  query: {
    attr: 'query',
    query: 'query',
  },
}

export const QUERY_PARAM_NAME = 'query'
export const QUERY_SORT_BY_PARAM_NAME = 'sortBy'

export const INSTANT_SEARCH_ATTRIBUTE_MAP = {
  query: {
    key: 'query',
    locale: 'PageSearch.PageSearch_Page_search',
  },
  'attributes.brand-name': {
    key: 'attributes.brand-name',
    locale: 'PageSearch.PageSearch_Filters_brand',
  },
  'attributes.brand-location': {
    key: 'attributes.brand-location',
    locale: 'PageSearch.PageSearch_Filters_brand_origin',
  },
  'attributes.on-sale-percentage': {
    key: 'attributes.on-sale-percentage',
    locale: 'PageSearch.PageSearch_Filters_on_sale_percentage',
  },
  'attributes.made-in': {
    key: 'attributes.made-in',
    locale: 'PageSearch.PageSearch_Filters_made_in',
  },
  'attributes.production-lead-time': {
    key: 'attributes.production-lead-time',
    locale: 'PageSearch.PageSearch_Filters_production_lead_time',
  },
  'attributes.universal-price': {
    key: 'attributes.universal-price',
    locale: 'PageSearch.PageSearch_Filters_wholesale_price',
  },
  'attributes.universal-msrp': {
    key: 'attributes.universal-msrp',
    locale: 'PageSearch.PageSearch_Filters_retail_price',
  },
  'categories.zh-HK.lvl0': {
    key: 'categories.zh-HK.lvl0',
    locale: 'PageSearch.PageSearch_Filters_category',
  },
  'categories.zh-HK.lvl1': {
    key: 'categories.zh-HK.lvl1',
    locale: 'PageSearch.PageSearch_Filters_category',
  },
  'categories.zh-HK.lvl2': {
    key: 'categories.zh-HK.lvl2',
    locale: 'PageSearch.PageSearch_Filters_category',
  },
  'categories.en-HK.lvl0': {
    key: 'categories.en-HK.lvl0',
    locale: 'PageSearch.PageSearch_Filters_category',
  },
  'categories.en-HK.lvl1': {
    key: 'categories.en-HK.lvl1',
    locale: 'PageSearch.PageSearch_Filters_category',
  },
  'categories.en-HK.lvl2': {
    key: 'categories.en-HK.lvl2',
    locale: 'PageSearch.PageSearch_Filters_category',
  },
  'attributes.universal-brand-mov': {
    key: 'attributes.universal-brand-mov',
    locale: 'PageSearch.PageSearch_Filters_minimum_order_value',
  },
  'attributes.best-seller': {
    key: 'attributes.best-seller',
    locale: 'PageSearch.PageSearch_Filters_best_seller',
  },
  'attributes.first-publish-date-unix': {
    key: 'attributes.first-publish-date-unix',
    locale: 'PageSearch.PageSearch_Filters_first_publish_date',
  },
  'attributes.keywords': {
    key: 'attributes.keywords',
    locale: 'PageSearch.PageSearch_Filters_Product_Keywords',
  },
}

export const classNamesAutoComplete = {
  input: 'autocomplete-input',
  panel: 'autocomplete-panel',
  form: 'autocomplete-form',
  inputWrapperPrefix: 'autocomplete-inputWrapperPrefix',
  inputWrapperSuffix: 'autocomplete-inputWrapperSuffix',
  label: 'autocomplete-label',
  submitButton: 'autocomplete-submitButton',
  clearButton: 'autocomplete-clearButton',
  detachedSearchButton: 'autocomplete-detachedSearchButton',
  detachedSearchButtonPlaceholder: 'autocomplete-detachedSearchButtonPlaceholder',
  detachedSearchButtonIcon: 'autocomplete-detachedSearchButtonIcon',
  detachedContainer: 'autocomplete-detachedContainer',
  detachedFormContainer: 'autocomplete-detachedFormContainer',
  detachedCancelButton: 'autocomplete-detachedCancelButton',
  inputWrapper: 'autocomplete-inputWrapper',
}
