import { SVGProps } from 'react'

import { SolidThumbUp } from '../index'
import css from './SolidThumbUpBoardedRound.module.scss'

const SolidThumbUpBoardedRound = (props: SVGProps<SVGSVGElement>) => (
  <div className={css.iconWrapper}>
    <SolidThumbUp {...props} />
  </div>
)
export default SolidThumbUpBoardedRound
