import { SVGProps } from 'react'

const SvgSolidArrowRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.475 14.7a6.811 6.811 0 0 1-.362-1.313A7.932 7.932 0 0 1 4 12.05c0-2.233.775-4.133 2.325-5.7C7.875 4.783 9.767 4 12 4h.175l-.9-.9A.948.948 0 0 1 11 2.4c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l2.6 2.6c.1.1.171.208.213.325.041.117.062.242.062.375s-.02.258-.062.375a.883.883 0 0 1-.213.325l-2.6 2.6a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l.9-.9H12c-1.667 0-3.083.587-4.25 1.762C6.583 8.937 6 10.367 6 12.05c0 .333.03.658.088.975.058.317.145.633.262.95a.97.97 0 0 1 .05.537.887.887 0 0 1-.25.488c-.3.3-.62.421-.962.363-.342-.059-.58-.28-.713-.663Zm6.85 7.6-2.6-2.6a.872.872 0 0 1-.212-.325A1.098 1.098 0 0 1 8.45 19a1.1 1.1 0 0 1 .063-.375.872.872 0 0 1 .212-.325l2.6-2.6a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275a.949.949 0 0 1 .275.7.948.948 0 0 1-.275.7l-.9.9H12c1.667 0 3.083-.587 4.25-1.762C17.417 15.063 18 13.633 18 11.95a5.402 5.402 0 0 0-.35-1.925.973.973 0 0 1-.05-.538.887.887 0 0 1 .25-.487c.3-.3.62-.42.962-.363.342.059.58.28.713.663.167.433.288.87.363 1.312.075.442.112.888.112 1.338 0 2.233-.775 4.133-2.325 5.7C16.125 19.217 14.233 20 12 20h-.175l.9.9a.949.949 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSolidArrowRefresh
