import { forwardRef, useMemo } from 'react'
import ReactSelect, {
  ClassNamesConfig,
  GroupBase,
  InputProps,
  MultiValue,
  OptionProps,
  Options,
  Props as ReactSelectProps,
  SingleValue,
  components,
} from 'react-select'

import cn from 'classnames'
import { isArray } from 'lodash'

import { STRING_PREVENT_INPUT_AUTO_FILL } from 'constants/constants'

import ErrorMessage from '../error-message'
import Label from '../label'
import css from './select.module.scss'

export type Option = {
  label: string
  value: string | boolean | number
  disabled?: boolean
}

export type GroupedOption = {
  label: string
  options: Option[]
}

export type SelectOptions = Options<Option> | Options<GroupedOption>
type SelectProps = {
  name: string
  id?: string
  label?: string
  options: SelectOptions
  value?: Option | Option[]
  placeholder?: string
  isDisabled?: boolean
  errorMessage?: string
  wrapperClassName?: string
  maxOptionSelect?: number
  onChange: (value: string | string[] | undefined | number | boolean) => unknown
  defaultValue?: Option | Option[]
  autoComplete?: string
  forceOptionDisable?: (option: Option, selectedValue: Options<Option>) => boolean
  variant?: 'square' | 'rounded'
  isSearchable?: boolean
} & Omit<ReactSelectProps, 'components'>

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      id,
      name,
      options,
      label,
      placeholder = 'Select',
      value,
      errorMessage,
      isDisabled,
      wrapperClassName,
      onChange,
      maxOptionSelect = 1,
      defaultValue,
      autoComplete = STRING_PREVENT_INPUT_AUTO_FILL,
      forceOptionDisable = () => false,
      variant = 'square',
      classNames,
      isSearchable = true,
    },
    ref
  ) => {
    const isMulti = maxOptionSelect > 1

    const handleChange = (option: MultiValue<Option> | SingleValue<Option> | null) => {
      if (Array.isArray(option)) {
        const multiValue: string[] = []
        option.forEach((o: Option) => {
          if (o.value) {
            multiValue.push(String(o.value))
          }
        })
        onChange(multiValue)
      } else {
        onChange((option as Option).value)
      }
    }

    const CustomInput = useMemo(
      () => (props: InputProps<Option, boolean, GroupBase<Option>>) => InputWithAutoCompleteAttr(props, autoComplete),
      [autoComplete]
    )
    const CustomIndicatorSeparator = useMemo(() => () => null, [])
    return (
      <div className={cn(css.select, wrapperClassName, css[variant])} data-test-id={`react-select-${name}-wrapper`}>
        {!!label && <Label htmlFor={name}>{label}</Label>}
        <div data-test-id={`react-select-${name}`}>
          <ReactSelect
            id={id}
            instanceId={name}
            placeholder={placeholder}
            options={options}
            classNamePrefix="select"
            value={value}
            defaultValue={defaultValue}
            isSearchable={isSearchable}
            isDisabled={isDisabled}
            isOptionDisabled={(option, selectValue) =>
              (isMulti && isArray(value) && value.length >= maxOptionSelect) ||
              forceOptionDisable(option, selectValue) ||
              (option.disabled ?? false)
            }
            components={
              isMulti
                ? { Input: CustomInput, Option: CustomOption }
                : { Input: CustomInput, IndicatorSeparator: CustomIndicatorSeparator }
            }
            onChange={handleChange}
            aria-label={label}
            inputId={id}
            isMulti={isMulti}
            hideSelectedOptions={false}
            closeMenuOnSelect={isMulti ? false : true}
            classNames={classNames as ClassNamesConfig<Option, boolean, GroupBase<Option>> | undefined}
          />
        </div>
        {!!errorMessage && <ErrorMessage message={errorMessage} />}
      </div>
    )
  }
)

export default Select
Select.displayName = 'Select'

const InputWithAutoCompleteAttr = (props: InputProps<Option, boolean, GroupBase<Option>>, autoComplete: string) => {
  const inputProps = { ...props, autoComplete: autoComplete }
  return <components.Input {...inputProps} />
}

const CustomOption = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: OptionProps<Option, boolean, GroupBase<Option>>) => {
  const props = {
    ...innerProps,
  }

  return (
    <components.Option
      getStyles={getStyles}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      innerProps={props}
      {...rest}
    >
      <input type="checkbox" checked={isSelected} onChange={() => null} className={css.checkbox} />
      {children}
    </components.Option>
  )
}
