
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-photo-view/dist/react-photo-view.css'
import { QueryClientProvider } from 'react-query'

import Cookies from 'js-cookie'

import AlgoliaInsightsConfigure from 'components/algoliaInsightsConfigure'
import { useModal } from 'components/modal/use-modal'
import { NotificationToast } from 'components/notification-toast'
import SignUpRetailerModal from 'components/retailer/registration/sign-up-retailer-modal'
import WelcomeNewRetailerModal from 'components/retailer/registration/welcome-new-retailer-modal'
import { ForgotPassword } from 'features/forgot-password'
import { Impersonate } from 'features/impersonate'
import { TopBanner } from 'features/landing-page/common/top-banner'
import { isShowBanner } from 'features/landing-page/common/top-banner/utils'
import { Footer } from 'features/layout/footer'
import { Header } from 'features/layout/header'
import { Login, UserType } from 'features/login'
import { ChooseLogin } from 'features/login/choose-login'
import { TempPassword } from 'features/temp-password'

import { BreadcrumbsContextProvider } from 'context/breadcrumbs'
import DynamoDbUserContextProvider from 'contexts/DynamoDbUserContext'
import { useTranslate } from 'hooks'
import { useIsLoggedIn } from 'hooks/use-is-logged-in'

import { setSessionId } from 'services/http-client'
import { queryClient } from 'services/query-client'
import { fireTrackingEvent } from 'utils/tracking'

import { DEFAULT_SOCIAL_IMAGE, REDIRECT_TO_COOKIE } from 'constants/constants'
import { ROUTES } from 'constants/routes'
import { SITEMAP_PROD_URL } from 'constants/sitemap'

import CookieBanner from '../features/landing-page/common/cookie-banner'
import '../styles/globals.scss'

const __Page_Next_Translate__ = function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const [username, setUsername] = useState('')
  const [isFirstVisit, setIsFirstVisit] = useState<boolean>(false)
  const { t, lang } = useTranslate('common')

  const { openLoginModal, openTempPasswordModal } = router.query
  if (typeof window !== 'undefined') {
    setSessionId()
  }

  useEffect(() => {
    !Cookies.get('firstVisit') && setIsFirstVisit(true)
  }, [])

  useEffect(() => {
    if (openTempPasswordModal) {
      openTempPasswordOpen()
      closeLogin()
      closeSignUp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTempPasswordModal])

  const { accessToken } = useIsLoggedIn()

  const {
    isOpen: isChooseLoginOpen,
    close: closeChooseLogin,
    open: openChooseLogin,
    onChange: toggleChooseLoginModal,
  } = useModal()
  const { isOpen: isLoginOpen, open: openLogin, close: closeLogin, onChange: toggleLoginModal } = useModal()
  const { isOpen: isForgotPasswordOpen, open: openForgotPassword, onChange: toggleForgotPasswordModal } = useModal()
  const { isOpen: isSignUpOpen, open: openSignUp, onChange: toggleSignUpModal, close: closeSignUp } = useModal()
  const {
    isOpen: isTempPasswordOpen,
    open: openTempPasswordOpen,
    onChange: toggleTempPasswordModal,
    close: closeTempPassword,
  } = useModal()
  const [userType, setUserType] = useState<UserType>('retailer')

  useEffect(() => {
    if (openLoginModal) {
      if (openLoginModal === 'retailer' || openLoginModal === 'brand') {
        setUserType(openLoginModal)
      }
    }
  }, [openLoginModal])

  const handleOpenLogin = (userType: UserType) => {
    setUserType(userType)
    closeChooseLogin()
    openLogin()
  }

  const openForgotPasswordFromLogin = () => {
    closeLogin()
    openForgotPassword()
  }

  const openSignUpFromLogin = () => {
    closeLogin()
    openSignUp()
  }

  const openLoginFromSignUp = () => {
    closeSignUp()
    openLogin()
  }

  const isPageWithHeader =
    !router.pathname.includes('portal') &&
    !router.pathname.includes('brand-preview') &&
    !router.pathname.includes('registration') &&
    !router.pathname.includes('onboarding') &&
    !router.pathname.includes('retailer/checkout') &&
    !router.pathname.includes('retailer/verification') &&
    !router.pathname.includes('brand/promotions')

  const isPortalApp = router.pathname.includes('portal') && !router.pathname.includes('preview')

  const isPageWithFooter =
    !isPortalApp &&
    !router.pathname.includes('order-summary') &&
    !router.pathname.includes('registration') &&
    !router.pathname.includes('onboarding') &&
    !router.pathname.includes('retailer/checkout') &&
    !router.pathname.includes('retailer/verification') &&
    !router.pathname.includes('brand/promotions') &&
    !router.pathname.includes('retailer/orders')

  const displayTopBanner = isShowBanner(router.pathname, accessToken)
  const showNavbar = !router.pathname.includes('return-request')

  const mounted = useRef('')
  useEffect(() => {
    if (mounted.current !== router.asPath) {
      fireTrackingEvent('page_view', { page_title: document.title, page_location: location.pathname })
    }
    mounted.current = router.asPath
  }, [router])

  const fixUrlEncode = (url: string | undefined) => {
    if (!url) {
      return ''
    }
    return url.replaceAll(' ', '+').replaceAll('%20', '+')
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="og:image" content={DEFAULT_SOCIAL_IMAGE} key="og:image" />
        <meta name="description" content={t('og.desc')} />
        <meta name="og:description" content={t('og.desc')} key="og:description" />
        <link rel="canonical" href={`${SITEMAP_PROD_URL}/${lang}${router.asPath}`} key="canonical" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <BreadcrumbsContextProvider>
          <DynamoDbUserContextProvider>
            <Impersonate />
            <AlgoliaInsightsConfigure />
            <div className="sticky-top">
              {!!displayTopBanner && <TopBanner />}
              {!!isPageWithHeader && (
                <Header
                  displayTopBanner={displayTopBanner}
                  onLoginClick={openChooseLogin}
                  showNavbar={showNavbar}
                  onSignUpClick={openSignUp}
                  accessToken={accessToken}
                />
              )}
            </div>
            <div className="main-wrapper">
              <Component {...pageProps} openSignUp={openSignUp} openLogin={openLogin} />
            </div>
            <NotificationToast />

            {!!isPageWithFooter && <Footer />}
            <SignUpRetailerModal
              isOpen={isSignUpOpen}
              onChange={toggleSignUpModal}
              onLoginClick={openLoginFromSignUp}
            />
            <ChooseLogin isOpen={isChooseLoginOpen} onChange={toggleChooseLoginModal} openLogin={handleOpenLogin} />
            <WelcomeNewRetailerModal />

            <Login
              isOpen={isLoginOpen}
              onChange={() => {
                toggleLoginModal()

                // TO-DO: Refactor it for more readable code e.g. add a onCloseBeforeLogin
                // Remove redirect if user closes the modal
                // If user directly login the modal will close automatically, the setTimeout is for the redirection to work
                setTimeout(() => {
                  sessionStorage.removeItem(REDIRECT_TO_COOKIE)
                }, 2000)
              }}
              onForgotPasswordClick={openForgotPasswordFromLogin}
              onSignUpClick={openSignUpFromLogin}
              onApplyToSellClick={closeLogin}
              brandRegistration={userType === 'brand' ? ROUTES.BRAND_REGISTRATION : undefined}
              userType={userType}
              setUsername={setUsername}
              defaultUsername={fixUrlEncode(router.query.username?.toString())}
              defaultPassword={fixUrlEncode(router.query.password?.toString())}
              openLogin={handleOpenLogin}
            />
            <ForgotPassword
              isOpen={isForgotPasswordOpen}
              onChange={toggleForgotPasswordModal}
              onLoginClick={toggleChooseLoginModal}
              loginUsername={username}
            />
            <TempPassword
              isOpen={isTempPasswordOpen}
              onChange={toggleTempPasswordModal}
              onNewPasswordIsSet={closeTempPassword}
              qs={{
                userType: router.query.userType?.toString() ?? '',
                username: router.query.username?.toString() ?? '',
              }}
            />
          </DynamoDbUserContextProvider>
        </BreadcrumbsContextProvider>
        <div className="cookie-banner">{!!isFirstVisit && <CookieBanner />}</div>
      </QueryClientProvider>
    </>
  )
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  