import Cookies from 'js-cookie'
import { IS_INTERNAL_TRAFFIC } from 'middlewares/clientIp'

import { ProductVariant } from 'features/product-details/types'

export const fireTrackingEvent = (name: string, detail: unknown) => {
  const event = new CustomEvent(name, { detail })
  document.dispatchEvent(event)
}

export const getPriceFromVariant = (variant: ProductVariant) => {
  return parseFloat(
    variant.discountedPrice
      ? variant.discountedPrice.replace(/[^\d.]/g, '')
      : variant.wholesalePrice?.replace(/[^\d.]/g, '') ?? ''
  )
}

export const getPriceWithCurrency = (price: string | null) => {
  return parseFloat((price ?? '').replace(/[^\d.]/g, ''))
}

export const isInternalTraffic = () => {
  return Cookies.get(IS_INTERNAL_TRAFFIC) === 'true'
}
