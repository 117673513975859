import { SVGProps } from 'react'

const SvgSolidThumbsUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 21.0003C9.45 21.0003 8.97933 20.8046 8.588 20.4133C8.196 20.0213 8 19.5503 8 19.0003V8.82532C8 8.55865 8.05433 8.30432 8.163 8.06232C8.271 7.82098 8.41667 7.60865 8.6 7.42532L14.025 2.02532C14.275 1.79198 14.571 1.65032 14.913 1.60032C15.2543 1.55032 15.5833 1.60865 15.9 1.77532C16.2167 1.94198 16.4457 2.17532 16.587 2.47532C16.729 2.77532 16.7583 3.08365 16.675 3.40032L15.55 8.00032H21C21.5333 8.00032 22 8.20032 22.4 8.60032C22.8 9.00032 23 9.46698 23 10.0003V12.0003C23 12.117 22.9873 12.242 22.962 12.3753C22.9373 12.5086 22.9 12.6336 22.85 12.7503L19.85 19.8003C19.7 20.1336 19.45 20.417 19.1 20.6503C18.75 20.8836 18.3833 21.0003 18 21.0003H10ZM4 21.0003C3.45 21.0003 2.97933 20.8046 2.588 20.4133C2.196 20.0213 2 19.5503 2 19.0003V10.0003C2 9.45032 2.196 8.97932 2.588 8.58732C2.97933 8.19598 3.45 8.00032 4 8.00032C4.55 8.00032 5.02067 8.19598 5.412 8.58732C5.804 8.97932 6 9.45032 6 10.0003V19.0003C6 19.5503 5.804 20.0213 5.412 20.4133C5.02067 20.8046 4.55 21.0003 4 21.0003Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSolidThumbsUp
