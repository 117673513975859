import { SVGProps } from 'react'

const SvgSolidTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.2085 1.96843C19.4653 2.30612 18.6771 2.5279 17.8701 2.62643C18.7207 2.10386 19.3574 1.28144 19.6614 0.312427C18.8631 0.800427 17.9879 1.14243 17.0757 1.32743C16.463 0.654005 15.6508 0.20739 14.7655 0.0570113C13.8802 -0.0933671 12.9713 0.0609176 12.1801 0.49588C11.389 0.930842 10.7599 1.62211 10.3907 2.46222C10.0214 3.30233 9.93276 4.24421 10.1384 5.14143C8.51955 5.05808 6.93585 4.62595 5.49013 3.87309C4.04441 3.12023 2.76898 2.06347 1.74666 0.771427C1.38479 1.40988 1.19463 2.13565 1.19565 2.87443C1.19565 4.32443 1.91411 5.60543 3.0064 6.35543C2.35999 6.33452 1.72781 6.15521 1.16255 5.83243V5.88443C1.16274 6.85013 1.48806 7.78604 2.08335 8.53349C2.67864 9.28093 3.50726 9.7939 4.42871 9.98543C3.82865 10.1525 3.19945 10.1771 2.58876 10.0574C2.84856 10.8887 3.35492 11.6156 4.03696 12.1365C4.71899 12.6574 5.54254 12.9462 6.3923 12.9624C5.54776 13.6438 4.58075 14.1474 3.54659 14.4446C2.51242 14.7418 1.43136 14.8267 0.365234 14.6944C2.22631 15.9239 4.39279 16.5765 6.6055 16.5744C14.0948 16.5744 18.1904 10.2014 18.1904 4.67443C18.1904 4.49443 18.1855 4.31243 18.1778 4.13443C18.9749 3.54259 19.663 2.80945 20.2095 1.96943L20.2085 1.96843Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSolidTwitter
