import { SVGProps } from 'react'

const SvgLightError = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 13.075a.728.728 0 0 0 .75-.75V7.8c0-.2-.07-.371-.212-.513A.731.731 0 0 0 12 7.075a.728.728 0 0 0-.75.75v4.525c0 .2.071.37.213.512.141.142.32.213.537.213Zm0 3.65a.779.779 0 0 0 .575-.225.779.779 0 0 0 .225-.575.779.779 0 0 0-.225-.575.779.779 0 0 0-.575-.225.779.779 0 0 0-.575.225.779.779 0 0 0-.225.575c0 .233.075.425.225.575.15.15.342.225.575.225Zm0 4.775a9.263 9.263 0 0 1-3.712-.75 9.58 9.58 0 0 1-3.013-2.025 9.58 9.58 0 0 1-2.025-3.013A9.263 9.263 0 0 1 2.5 12c0-1.317.25-2.554.75-3.713a9.583 9.583 0 0 1 2.025-3.012A9.58 9.58 0 0 1 8.288 3.25 9.263 9.263 0 0 1 12 2.5a9.27 9.27 0 0 1 3.713.75 9.583 9.583 0 0 1 3.012 2.025 9.583 9.583 0 0 1 2.025 3.012A9.27 9.27 0 0 1 21.5 12c0 1.317-.25 2.554-.75 3.712a9.58 9.58 0 0 1-2.025 3.013 9.583 9.583 0 0 1-3.012 2.025A9.27 9.27 0 0 1 12 21.5Zm0-1.5c2.217 0 4.104-.779 5.663-2.337C19.221 16.104 20 14.217 20 12s-.779-4.104-2.337-5.663C16.104 4.779 14.217 4 12 4s-4.104.779-5.662 2.337C4.779 7.896 4 9.783 4 12s.78 4.104 2.338 5.663C7.896 19.221 9.783 20 12 20Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightError
