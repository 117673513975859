import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import useGlobalStore from 'stores'

import Button from 'components/button'
import Google from 'components/icon/solid/Google'
import Input from 'components/input'
import Link from 'components/link'
import Logo from 'components/logo'
import Modal from 'components/modal'
import Or from 'components/or'
import { PasswordField } from 'components/password-field'
import { ERROR_CODE_USER_NOT_CONFIRMED } from 'components/retailer/registration/onboarding-form/hooks/use-retailer-onboarding'
import Toast from 'components/toast'
import Tooltip from 'components/tooltip'

import { useDynamoDbUserContext } from 'contexts/DynamoDbUserContext'
import { useTranslate } from 'hooks'

import { getErrorCodes, setAccessToken } from 'services/http-client'
import { FORM_MODE } from 'utils/constants'
import { fireTrackingEvent } from 'utils/tracking'

import { BG_IMG_CDN_PREFIX, REDIRECT_TO_COOKIE } from 'constants/constants'
import { ROUTES } from 'constants/routes'

import styles from './login.module.scss'
import { GOOGLE_URL, useBrandLogin, useRetailerLogin, useRetailerLoginSuccess } from './use-login'

export type UserType = 'retailer' | 'brand'
export type LoginMethods = 'phone' | 'email' | 'google'
/**
 * Using this instead of LoginRequest type since each DOM element needs to have
 * a unique ID and all modals are always present in the DOM.
 * */
type LoginForm = {
  userName: string
  password: string
}

type LoginProps = {
  userType: UserType
  isOpen: boolean
  onChange: () => void
  onForgotPasswordClick: () => void
  onSignUpClick: () => void
  onApplyToSellClick: () => void
  setUsername: Dispatch<SetStateAction<string>>
  brandRegistration?: string
  defaultUsername?: string
  defaultPassword?: string
  openLogin: (userType: UserType) => void
}

export const Login = ({
  userType,
  isOpen,
  onChange,
  onForgotPasswordClick,
  onSignUpClick,
  onApplyToSellClick,
  setUsername,
  brandRegistration,
  defaultUsername,
  defaultPassword,
  openLogin,
}: LoginProps) => {
  const { t } = useTranslate('common')
  const router = useRouter()
  const {
    register,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({ mode: FORM_MODE })
  const [isShowOnboardingMsg, setIsShowOnboardingMsg] = useState(false)

  const [errorCode, setErrorCode] = useState('')
  const { getUserFromDynamo } = useDynamoDbUserContext()
  useEffect(() => {
    if (isOpen) {
      if (defaultUsername && defaultPassword) {
        reset({ userName: defaultUsername, password: defaultPassword })
      }
      return
    }
    const inputUsername = getValues('userName')
    setUsername(inputUsername)
    reset({ userName: '', password: '' })
    setErrorCode('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, reset])

  const { mutateAsync: retailerLogin, isLoading: isRetailerLoading } = useRetailerLogin()
  const { mutateAsync: brandLogin, isLoading: isBrandLoading } = useBrandLogin()
  const { onRetailerSuccess } = useRetailerLoginSuccess()

  useEffect(() => {
    if (!isOpen) {
      setIsShowOnboardingMsg(false)
      return
    }
  }, [isOpen])

  const signupRetailerStore = useGlobalStore((state) => state.signupRetailer)

  const handleLoginClick = async () => {
    const username = getValues().userName.trim()

    sessionStorage.removeItem('NEW_PASSWORD_REQUIRED_SESSION')

    if (userType === 'retailer') {
      await retailerLogin(
        {
          username,
          password: getValues().password,
        },
        {
          onSuccess: (data) => onRetailerSuccess({ ...data, onChange }),
          onError: async (error) => {
            const errorCodes = getErrorCodes(error)

            if (errorCodes[0] === ERROR_CODE_USER_NOT_CONFIRMED) {
              signupRetailerStore.setCredential(username, getValues().password)
              const isEmail = username.includes('@')
              const type: LoginMethods = isEmail ? 'email' : 'phone'
              await router.push(`${ROUTES.RETAILER_VERIFICATION}/${type}/${username}`)
              onChange()
              return
            }

            setErrorCode(errorCodes[0])
            fireTrackingEvent('failure', {
              name: 'failure_login_retailer',
              errorCode: errorCodes.join(','),
              nativeEvent: error,
            })
          },
        }
      )
    } else {
      await brandLogin(
        {
          username,
          password: getValues().password,
        },
        {
          onSuccess: async (response) => {
            if (response.data.challengeName === 'NEW_PASSWORD_REQUIRED') {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              sessionStorage.setItem('NEW_PASSWORD_REQUIRED_SESSION', response.data.session ?? '')
              return await router.push(
                `${ROUTES.HOME}?userType=brand&openTempPasswordModal=true&username=${encodeURIComponent(username)}`
              )
            } else {
              const {
                data: { accessToken, expiresIn },
              } = response

              // Note: Update on onSuccess in /features/temp-password/Steps/create-new-password as you update this block
              setAccessToken(accessToken, expiresIn)
              onChange()
              getUserFromDynamo((userInfoJson, cognitoId) => {
                fireTrackingEvent('success', {
                  name: 'success_login_brand',
                  detail: {
                    name: userInfoJson?.brandName,
                    email: userInfoJson?.email,
                    group: 'brand',
                    cognitoId: cognitoId,
                  },
                })
              })

              const REDIRECT_URL = sessionStorage.getItem(REDIRECT_TO_COOKIE)
              sessionStorage.removeItem(REDIRECT_TO_COOKIE)
              if (REDIRECT_URL) {
                return await router.push(REDIRECT_URL)
              } else {
                await router.push(ROUTES.PORTAL)
              }
            }
          },
          onError: (error) => {
            const errorCodes = getErrorCodes(error)
            setErrorCode(errorCodes[0])
            fireTrackingEvent('failure', {
              name: 'failure_login_brand',
              errorCode: errorCodes.join(','),
              nativeEvent: error,
            })
          },
        }
      )
    }
  }

  const onSubmit = handleSubmit(async () => {
    await handleLoginClick()
  })

  return (
    <Modal
      isOpen={isOpen}
      onChange={onChange}
      trackName={isShowOnboardingMsg ? `login-${userType}-onboarding` : `login-${userType}`}
    >
      <div className={styles.container}>
        <div
          className={styles.image}
          style={{
            background:
              userType === 'brand'
                ? `linear-gradient(180deg, rgba(26, 26, 36, 0.6) 37.41%, rgba(26, 26, 36, 0) 160.7%), url(${BG_IMG_CDN_PREFIX}/images/brand_login.jpg)`
                : `url(${BG_IMG_CDN_PREFIX}/images/retailer_login.jpg)`,
          }}
        >
          <h3 className={styles.promoTitle} style={userType === 'brand' ? { color: '#fff' } : undefined}>
            {t(`login.${userType}_promo_title`)}
          </h3>
          <p className={styles.promo} style={userType === 'brand' ? { color: '#fff' } : undefined}>
            {t(`login.${userType}_promo`)}
          </p>
        </div>
        <form className={styles.formContainer}>
          <p className={styles.welcomeBack}>{t('login.' + userType)}</p>
          <div className={styles.markato}>
            <Logo width={121} height={32.5} />
          </div>
          {!!isShowOnboardingMsg && <Toast messages={[t('login.one_last_step_reminder')]} type="success" />}
          <Tooltip
            tooltipPlacement="top-start"
            text={t(userType === 'retailer' ? 'login.username.retailer_tooltip' : 'login.username.brand_tooltip')}
          >
            <div className={styles.usernameContainer}>
              <Input
                placeholder={t('login.username.placeholder')}
                label={t('login.username.label')}
                {...register('userName', {
                  required: t('login.requiredField'),
                })}
                type="email"
                autoComplete="username"
                data-test-id="login-username-input"
                errorMessage={errors.userName?.message}
              />
            </div>
          </Tooltip>
          <div className={styles.passwordContainer}>
            <PasswordField
              className={styles.password}
              label={t('login.password.label')}
              placeholder={t('login.password.placeholder')}
              autoComplete="current-password"
              {...register('password', {
                required: t('login.requiredField'),
              })}
              errorMessage={errors.userName?.message}
              data-test-id="login-password-input"
              id="current-password"
            />
            <Link className={styles.forgotPassword} onClick={onForgotPasswordClick} data-test-id="forgot-password-link">
              {t('login.password.forgot')}
            </Link>
          </div>
          {!!errorCode && (
            <div className={styles.errorContainer} data-test-id="login-error-message">
              <Toast type="alert" messages={['login.error.' + errorCode]} />
            </div>
          )}
          <Button
            data-test-id="login-form-button"
            className={styles.loginButton}
            size="large"
            label={t('login.button')}
            type="submit"
            onClick={onSubmit}
            isLoading={isBrandLoading || isRetailerLoading}
          />
          <div className={styles.newContainer}>
            <span className={styles.new}>{t('login.new')}</span>
            <Link onClick={userType === 'retailer' ? onSignUpClick : onApplyToSellClick} href={brandRegistration}>
              {t(userType === 'retailer' ? 'login.signup' : 'login.apply')}
            </Link>
          </div>
          {userType === 'retailer' && (
            <>
              <Or />
              <Button
                size="large"
                style="tertiary"
                iconLeft={<Google width="24px" height="24px" />}
                label={t('login.google_continue')}
                onClick={() => {
                  sessionStorage.setItem(REDIRECT_TO_COOKIE, `${window.location.pathname}${window.location.search}`)
                  void router.push(GOOGLE_URL)
                }}
              />
            </>
          )}
          <div style={{ marginTop: 24, padding: 3, fontSize: 13 }}>
            <span className={styles.new}>{userType === 'retailer' ? t('login.are_you1') : t('login.are_you2')}</span>{' '}
            <Link
              onClick={userType === 'retailer' ? () => openLogin('brand') : () => openLogin('retailer')}
              style={{ fontSize: 13 }}
            >
              {t('login.button')}
            </Link>
          </div>
        </form>
      </div>
    </Modal>
  )
}
