import { SVGProps } from 'react'

const SvgLightDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.3 20.5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 0 1 5.5 18.7V6h-.25a.726.726 0 0 1-.75-.75.726.726 0 0 1 .75-.75H9c0-.25.087-.458.262-.625a.892.892 0 0 1 .638-.25h4.2c.25 0 .463.083.638.25A.828.828 0 0 1 15 4.5h3.75a.728.728 0 0 1 .75.75.728.728 0 0 1-.75.75h-.25v12.7c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H7.3ZM7 6v12.7c0 .083.03.154.088.213A.289.289 0 0 0 7.3 19h9.4a.292.292 0 0 0 .213-.087A.292.292 0 0 0 17 18.7V6H7Zm2.4 10.25a.728.728 0 0 0 .75.75c.217 0 .396-.07.538-.212a.731.731 0 0 0 .212-.538v-7.5a.731.731 0 0 0-.212-.538A.731.731 0 0 0 10.15 8a.728.728 0 0 0-.75.75v7.5Zm3.7 0c0 .217.07.396.212.538a.731.731 0 0 0 .538.212c.217 0 .396-.07.538-.212a.731.731 0 0 0 .212-.538v-7.5a.731.731 0 0 0-.212-.538A.731.731 0 0 0 13.85 8a.731.731 0 0 0-.538.212.731.731 0 0 0-.212.538v7.5ZM7 6v12.7c0 .083.03.154.088.213A.289.289 0 0 0 7.3 19H7V6Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightDelete
