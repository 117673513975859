import { ChangeEvent, InputHTMLAttributes, forwardRef } from 'react'

import cn from 'classnames'
import parse from 'html-react-parser'

import { STRING_PREVENT_INPUT_AUTO_FILL } from 'constants/constants'

import ErrorMessage from '../error-message'
import Label from '../label'
import css from './input.module.scss'

export type InputProps = {
  name: string
  onChange?: (changeEvent: ChangeEvent<HTMLInputElement>) => void
  label?: string
  labels?: string[]
  isDisabled?: boolean
  placeholder?: string
  errorMessage?: string
  className?: string
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
  readonly?: boolean
  suffix?: React.ReactNode
  prefixed?: React.ReactNode
  onBlur?: (changeEvent: React.FocusEvent<HTMLInputElement>) => void
  autoCapitalize?: string
  autoComplete?: string
  containerClassName?: string
} & InputHTMLAttributes<HTMLInputElement>

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      onChange,
      label,
      labels,
      isDisabled,
      placeholder,
      errorMessage,
      onBlur,
      className,
      icon,
      iconPosition = 'right',
      readonly = false,
      suffix,
      prefixed,
      autoCapitalize = 'none',
      autoComplete = STRING_PREVENT_INPUT_AUTO_FILL,
      containerClassName,
      ...rest
    },
    ref
  ) => (
    <div className={cn([css.container, containerClassName])}>
      {!!label && (
        <Label className={css.label} htmlFor={name}>
          {parse(label)}
        </Label>
      )}
      {!!labels && (
        <div className={css.labelsWrapper}>
          {labels.map((label) => (
            <Label className={css.label} htmlFor={name} key={label}>
              {parse(label)}
            </Label>
          ))}
        </div>
      )}

      <div className={cn([css.inputWrapper, suffix ? css.suffixed : '', prefixed ? css.prefixed : ''])}>
        {!!icon && iconPosition === 'left' && <div className={css.iconLeft}>{icon}</div>}
        {!!prefixed && <div className={css.prefix}>{prefixed}</div>}
        <input
          readOnly={readonly}
          id={name}
          className={cn(css.input, className, icon && iconPosition === 'left' ? css.inputIconLeft : '')}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          placeholder={placeholder}
          disabled={isDisabled}
          ref={ref}
          autoCapitalize={autoCapitalize}
          autoComplete={autoComplete}
          {...rest}
        />

        {!!suffix && <div className={css.suffix}>{suffix}</div>}
        {!!icon && iconPosition === 'right' && <div className={css.iconRight}>{icon}</div>}
      </div>

      {!!errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  )
)

export default Input
Input.displayName = 'Input'
