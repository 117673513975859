import { useRouter } from 'next/router'

import { useGetTopBanner } from 'hooks/common/use-get-top-banner'
import { useTranslate } from 'hooks/translation/use-translation'

import { getCookie } from 'services/http-client'

import css from './top-banner.module.scss'

export const TopBanner = () => {
  const { lang } = useTranslate('')
  let { data: topBanner } = useGetTopBanner(lang)

  const router = useRouter()

  if (!topBanner) {
    topBanner = {
      name: '',
      text: '',
      url: '',
      backgroundColor: '#FFF2d8',
      textColor: '#33333A',
    }
  }

  const cookieImpersonate = getCookie('impersonated-token')
  const handleNavigate = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    if (topBanner?.url) {
      if (!/^https?:\/\//i.test(topBanner.url)) {
        topBanner.url = 'https://' + topBanner.url
      }

      void router.push(topBanner.url)
    }
  }
  return (
    <div
      className={css.container}
      style={{ backgroundColor: topBanner.backgroundColor, top: cookieImpersonate ? '36px' : '0px' }}
    >
      <p style={{ color: topBanner.textColor }}>
        {topBanner.text ? topBanner.text : <>&nbsp;</>}

        {topBanner.linkText ? (
          <a
            href={topBanner.url}
            onClick={handleNavigate}
            style={{ color: topBanner.textColor, textDecoration: 'underline' }}
            className={css.link}
          >
            {topBanner.linkText}
          </a>
        ) : null}
      </p>
    </div>
  )
}
