export const encodeURIComp = (str: string) =>
  encodeURIComponent(str)
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, '%2D')
    // eslint-disable-next-line no-useless-escape
    .replace(/\_/g, '%5F')
    .replace(/\./g, '%2E')
    // eslint-disable-next-line no-useless-escape
    .replace(/\!/g, '%21')
    // eslint-disable-next-line no-useless-escape
    .replace(/\~/g, '%7E')
    .replace(/\*/g, '%2A')
    // eslint-disable-next-line no-useless-escape
    .replace(/\'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')

export const decodeURIComp = (str: string) =>
  decodeURIComponent(
    str
      .replace(/\\%2D/g, '-')
      .replace(/\\%5F/g, '_')
      .replace(/\\%2E/g, '.')
      .replace(/\\%21/g, '!')
      .replace(/\\%7E/g, '~')
      .replace(/\\%2A/g, '*')
      .replace(/\\%27/g, "'")
      .replace(/\\%28/g, '(')
      .replace(/\\%29/g, ')')
  )
