import { useState } from 'react'

import { NavMenuItem } from 'interfaces/navMenu'

import Link from 'components/link'
import { getCategoryURL } from 'components/molecules/autocomplete/utils'

import { useTranslate } from 'hooks'

import AccordionTab, { AccordionTabClasses } from '../../../../../components/molecules/accordion-tab'
import { getBrandHighlightSection, getMenuTopLeftSection, getTopLevelItemName } from '../../navbar/utils'
import css from '../app-header.module.scss'
import MenuHeader from '../menu-header'

type TabItemLinkProps = {
  name: string
  url: string
  onClick: () => void
  textColor?: string | null
}

type NavCategoryItemProps = {
  navMenuItem: NavMenuItem
  onCloseMenu: () => void
}

const TabItemLink = ({ name, url, onClick, textColor }: TabItemLinkProps) => {
  return (
    <li className={css.tabContentItem}>
      <Link
        href={url}
        onClick={onClick}
        style={textColor ? { color: textColor } : undefined}
        isNextLink
        prefetch={false}
      >
        {name}
      </Link>
    </li>
  )
}

const accordionTabClasses: AccordionTabClasses = {
  classNameTab: css.tab,
  classNameTabLabel: css.tabLabel,
  classNameTabContent: css.tabContent,
  classNameTabContentInnerWrapper: css.tabContentInnerWrapper,
}

const CategoryAccordionMenuItem = ({ navMenuItem, onCloseMenu }: NavCategoryItemProps) => {
  const { t } = useTranslate('strapi-component-app-header')

  const { children: subMenuItems, name, categoryNav: l1Category } = navMenuItem
  const [isMobileCategoriesOpened, setIsMobileCategoriesOpened] = useState(false)

  const menuTopLeftSection = getMenuTopLeftSection(subMenuItems, l1Category)
  const brandHightLightSection = getBrandHighlightSection(subMenuItems)

  const handleCloseAllMenu = () => {
    onCloseMenu()
    setIsMobileCategoriesOpened(false)
  }

  return (
    <>
      <div className={css.tab} onClick={() => setIsMobileCategoriesOpened(true)}>
        <label className={css.tabLabel} htmlFor={name}>
          {getTopLevelItemName(navMenuItem)}
        </label>
      </div>
      {!!isMobileCategoriesOpened && (
        <div className={css.slideMenu}>
          <MenuHeader onClose={handleCloseAllMenu} onBack={() => setIsMobileCategoriesOpened(false)} />
          <section className={css.topLevelSection}>
            <h1 className={css.navTitle}> {getTopLevelItemName(navMenuItem)}</h1>
            <ul className={css.topLevelLinkList}>
              {menuTopLeftSection?.children.map(({ name, url, textColor }) => (
                <TabItemLink key={name} name={name} url={url} onClick={handleCloseAllMenu} textColor={textColor} />
              ))}
            </ul>
          </section>
          <section className={css.bottomSection}>
            {l1Category?.children?.map((l2Category) => {
              const { children: l3Categories } = l2Category
              return (
                <AccordionTab
                  title={l2Category.name}
                  id={l2Category.name}
                  key={l2Category.name}
                  {...accordionTabClasses}
                >
                  <TabItemLink
                    key={l2Category.name}
                    name={t('Navbar.Navbar_all') + l2Category.name}
                    url={getCategoryURL([l1Category.nameEn, l2Category.nameEn])}
                    onClick={handleCloseAllMenu}
                  />
                  {l3Categories?.map((l3Category) => (
                    <TabItemLink
                      key={l3Category.nameEn}
                      name={l3Category.name}
                      url={getCategoryURL([l1Category.nameEn, l2Category.nameEn, l3Category.nameEn])}
                      onClick={handleCloseAllMenu}
                    />
                  ))}
                </AccordionTab>
              )
            })}
            {!!brandHightLightSection && (
              <AccordionTab
                title={brandHightLightSection.name}
                id={brandHightLightSection.name + navMenuItem.name}
                {...accordionTabClasses}
              >
                {brandHightLightSection.children.map((brandItem) => (
                  <TabItemLink
                    key={brandItem.name}
                    name={brandItem.name}
                    url={brandItem.url}
                    onClick={handleCloseAllMenu}
                    textColor={brandItem.textColor}
                  />
                ))}
              </AccordionTab>
            )}
          </section>
        </div>
      )}
    </>
  )
}

export default CategoryAccordionMenuItem
