import { useMutation } from 'react-query'

import { ErrorResponse } from 'services/error-response'
import httpClient from 'services/http-client'

import { RetailerRegistrationFormType } from './use-retailer-onboarding-form'

export const RETAILER_REGISTER = '/retailers/register'

export const ERROR_CODE_USER_NOT_CONFIRMED = 'AUTH_012'

const registerRetailer = async (registrationForm: RetailerRegistrationFormType) =>
  httpClient.post(`${RETAILER_REGISTER}`, registrationForm)

export const useSubmitRetailerOnboardingInfos = (
  onSuccess: () => Promise<void> | void,
  onError: (data: ErrorResponse) => Promise<void> | void
) =>
  useMutation(registerRetailer, {
    onSuccess,
    onError,
  })
