import { SVGProps } from 'react'

const SvgLightUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 15.625a.706.706 0 0 1-.537-.225.74.74 0 0 1-.213-.525v-7.65L9.325 9.15a.822.822 0 0 1-.525.213c-.2.008-.383-.071-.55-.238a.69.69 0 0 1-.212-.538.736.736 0 0 1 .212-.512l3.125-3.125a.822.822 0 0 1 .288-.188.935.935 0 0 1 .675 0 .825.825 0 0 1 .287.188l3.125 3.125c.15.15.221.325.213.525a.822.822 0 0 1-.213.525.864.864 0 0 1-.55.238.678.678 0 0 1-.525-.213L12.75 7.225v7.65c0 .2-.07.375-.212.525a.71.71 0 0 1-.538.225ZM6.3 19.5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 0 1 4.5 17.7v-1.95a.727.727 0 0 1 .75-.75.727.727 0 0 1 .75.75v1.95c0 .067.033.133.1.2s.133.1.2.1h11.4c.067 0 .133-.033.2-.1s.1-.133.1-.2v-1.95c0-.217.07-.396.212-.538A.73.73 0 0 1 18.75 15a.73.73 0 0 1 .538.212.731.731 0 0 1 .212.538v1.95c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H6.3Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightUpload
