// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link'

import cn from 'classnames'

import { useTranslate } from 'hooks'

import { fireTrackingEvent } from 'utils/tracking'

import css from './link.module.scss'

type LinkProps = {
  href?: string
  children?: React.ReactNode
  prefetch?: boolean
  className?: string
  target?: '_self' | '_blank' | '_parent' | '_top' | 'framename' | string
  variant?: 'link' | 'button'
  size?: 'small' | 'medium' | 'large'
  linkStyle?: 'primary' | 'secondary' | 'tertiary'
  isUnderlined?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  customLangSubPath?: string
  trackName?: string
  disabled?: boolean
  isNextLink?: boolean
  nativeAnchorProps?: React.ComponentProps<'a'>
  nextLinkProps?: React.ComponentProps<typeof NextLink>
  ariaLabel?: string
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

const Link = ({
  href = '',
  children,
  className,
  target = '_self',
  variant = 'link',
  size = 'medium',
  linkStyle = 'primary',
  isUnderlined = true,
  disabled = false,
  onClick,
  customLangSubPath,
  trackName = '',
  isNextLink = false,
  nativeAnchorProps,
  nextLinkProps,
  ariaLabel,
  ...commonLinkProps
}: LinkProps) => {
  const { langSubPath } = useTranslate('common')
  const isRelative = href.startsWith('/')

  const commonProps = {
    href: isRelative ? `${customLangSubPath ?? langSubPath}${href}` : href,
    target: target,
    className: cn([
      css[variant],
      css[size],
      css[linkStyle],
      !isUnderlined && css.noUnderline,
      className ?? css.text,
      disabled && css.disabled,
    ]),
    onClick: (ev: React.MouseEvent<HTMLAnchorElement>) => {
      if (disabled) {
        ev.preventDefault()
        return
      }
      if (!href) {
        ev.preventDefault()
      }
      onClick?.(ev)
      trackName &&
        fireTrackingEvent('button', {
          name: trackName,
        })
    },
    ...commonLinkProps,
  }

  return isNextLink ? (
    <NextLink {...commonProps} {...nextLinkProps}>
      {children}
    </NextLink>
  ) : (
    <a {...commonProps} {...nativeAnchorProps} aria-label={ariaLabel}>
      {children}
    </a>
  )
}

export default Link
