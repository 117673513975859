import { SVGProps } from 'react'

const SvgRegularBank = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.05 7.61876C18.1748 7.61879 18.2983 7.59418 18.4136 7.54633C18.5289 7.49848 18.6336 7.42832 18.7218 7.33988C18.81 7.25143 18.88 7.14643 18.9278 7.03086C18.9755 6.9153 19 6.79144 19 6.66636V3.80915C19.0001 3.60931 18.9374 3.41452 18.8208 3.25241C18.7043 3.09031 18.5397 2.96912 18.3506 2.90604L9.80059 0.0488291C9.60546 -0.0162764 9.39454 -0.0162764 9.19941 0.0488291L0.64941 2.90604C0.460269 2.96912 0.295743 3.09031 0.179176 3.25241C0.062608 3.41452 -8.1825e-05 3.60931 8.01555e-08 3.80915V6.66636C-3.23714e-05 6.79144 0.0245181 6.9153 0.0722485 7.03086C0.119979 7.14643 0.189954 7.25143 0.278176 7.33988C0.366398 7.42832 0.471138 7.49848 0.586412 7.54633C0.701686 7.59418 0.825235 7.61879 0.95 7.61876H1.9V14.4611C1.3459 14.6568 0.865797 15.0196 0.525604 15.4997C0.185411 15.9799 0.00180735 16.5538 8.01555e-08 17.1428V19.0476C-3.23714e-05 19.1727 0.0245181 19.2965 0.0722485 19.4121C0.119979 19.5277 0.189954 19.6327 0.278176 19.7211C0.366398 19.8096 0.471138 19.8797 0.586412 19.9276C0.701686 19.9754 0.825235 20 0.95 20H18.05C18.1748 20 18.2983 19.9754 18.4136 19.9276C18.5289 19.8797 18.6336 19.8096 18.7218 19.7211C18.81 19.6327 18.88 19.5277 18.9278 19.4121C18.9755 19.2965 19 19.1727 19 19.0476V17.1428C18.9982 16.5538 18.8146 15.9799 18.4744 15.4997C18.1342 15.0196 17.6541 14.6568 17.1 14.4611V7.61876H18.05ZM17.1 18.0952H1.9V17.1428C1.90025 16.8903 2.00042 16.6482 2.17853 16.4696C2.35663 16.2911 2.59812 16.1906 2.85 16.1904H16.15C16.4019 16.1906 16.6434 16.2911 16.8215 16.4696C16.9996 16.6482 17.0997 16.8903 17.1 17.1428V18.0952ZM3.8 14.2856V7.61876H5.7V14.2856H3.8ZM7.6 14.2856V7.61876H11.4V14.2856H7.6ZM13.3 14.2856V7.61876H15.2V14.2856H13.3ZM1.9 5.71395V4.49554L9.5 1.95548L17.1 4.49554V5.71395H1.9Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgRegularBank
