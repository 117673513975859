export const regionOptions = [
  {
    value: 'hk',
    label: 'Hong Kong SAR · HKD $',
  },
  {
    value: 'sg',
    label: 'Singapore · SGD $',
  },
  {
    value: 'my',
    label: 'Malaysia · MYR RM',
  },
]

export const langOptions = {
  hk: [
    {
      value: 'en',
      label: 'English (Hong Kong SAR)',
    },
    {
      value: 'zh',
      label: '繁體中文 (香港特別行政區)',
    },
  ],
  sg: [
    {
      value: 'en',
      label: 'English (Singapore)',
    },
    {
      value: 'zh',
      label: '简体中文 (新加坡)',
    },
  ],
  my: [
    {
      value: 'en',
      label: 'English (Malaysia)',
    },
    {
      value: 'zh',
      label: '简体中文 (马来西亚)',
    },
  ],
}
