import { useRouter } from 'next/router'

import { getUserType } from 'features/login/use-login'

import { useDynamoDbUserContext } from 'contexts/DynamoDbUserContext'
import { useTranslate } from 'hooks'
import { useIsLoggedIn } from 'hooks/use-is-logged-in'

import { fireTrackingEvent } from 'utils/tracking'

import { ROUTES } from 'constants/routes'

import LangSwitcher from '../../lang-switcher'
import css from '../app-header.module.scss'
import CategoriesMenu from '../categories-menu'
import MenuHeader, { renderMarkatoLogo } from '../menu-header'
import UserMenu from '../user-menu'

type NavMenuMobileProps = {
  onCloseMenu: () => void
  onLoginClick?: () => void
  onSignUpClick?: () => void
}

const NavMenuMobile = ({ onCloseMenu, onLoginClick, onSignUpClick }: NavMenuMobileProps) => {
  const { dynamoDbUser } = useDynamoDbUserContext()
  const { t } = useTranslate('common')
  const router = useRouter()
  const { isLoggedIn, accessToken } = useIsLoggedIn()
  const userType = accessToken ? getUserType(accessToken) : 'Guest'

  return (
    <div className={css.mobileMenu}>
      <MenuHeader leftComponent={renderMarkatoLogo()} onClose={onCloseMenu} />
      <LangSwitcher placement="bottom-start" />
      {dynamoDbUser && userType === 'Retailer' ? <UserMenu user={dynamoDbUser} isMobile /> : null}
      <CategoriesMenu onCloseMenu={onCloseMenu} />
      {!isLoggedIn && (
        <ul className={css.offSessionMenu}>
          <li
            onClick={(ev) => {
              fireTrackingEvent('button', {
                name: 'cta_mobile_menu_signup',
              })
              return onSignUpClick?.()
            }}
          >
            {t('header.signup')}
          </li>
          <li
            onClick={() => {
              void router.push(ROUTES.BRAND_REGISTRATION)
              fireTrackingEvent('button', {
                name: 'cta_mobile_menu_sell',
              })
            }}
          >
            {t('header.apply')}
          </li>
          <li
            onClick={() => {
              fireTrackingEvent('button', {
                name: 'cta_mobile_menu_login',
              })
              return onLoginClick?.()
            }}
          >
            {t('header.login')}
          </li>
        </ul>
      )}
    </div>
  )
}

export default NavMenuMobile
