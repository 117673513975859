import { getUserType } from 'features/login/use-login'

import { ROUTES } from 'constants/routes'

export const isShowBanner = (pathname: string, accessToken: string | null): boolean => {
  if (
    pathname.includes(ROUTES.RETAILER_VERIFICATION) ||
    pathname.includes(ROUTES.BRAND_REGISTRATION_CONFIRMATION) ||
    pathname.includes(ROUTES.RETAILER_THANK_YOU) ||
    pathname.includes(ROUTES.RETAILER_REGISTRATION) ||
    pathname.includes(ROUTES.RETAILER_ONBOARDING) ||
    pathname.includes(ROUTES.BRAND_REGISTRATION) ||
    pathname.includes(ROUTES.BRAND_REGISTRATION_CONFIRMATION)
  )
    return false
  if (!accessToken) return true

  const userType = getUserType(accessToken)
  return (
    (pathname === '/' ||
      pathname.includes(ROUTES.SEARCH) ||
      pathname.includes(ROUTES.RETAILER_SETTINGS) ||
      pathname === ROUTES.PRODUCT ||
      pathname === ROUTES.BRAND ||
      pathname === ROUTES.RETAILER ||
      pathname === ROUTES.RETAILER_CART ||
      pathname === ROUTES.RETAILER_FAVOURITES ||
      pathname === ROUTES.RETAILER_CHECKOUT) &&
    userType !== 'Brand'
  )
}
