import cn from 'classnames'
import CheckboxIcon from 'public/images/icons/checkbox-checked.svg'

import Avatar from 'components/avatar'
import Checkbox from 'components/checkbox'
import Link from 'components/link'
import { errorToast } from 'components/notification-toast'

import { useTranslate } from 'hooks/translation/use-translation'

import { ErrorResponse } from 'services/error-response'

import { useSelectItemInCart } from '../hooks/use-put-select-item'
import { MinimumOrderStatus } from '../minimum-order-status'
import css from './brand-details.module.scss'

type BrandDetailsProps = {
  image: string
  name: string
  min?: number
  currency?: string
  spent?: number
  big?: boolean
  country?: string
  numberOfItems?: string
  brandKey: string
  isSelectedForCheckout?: boolean
  isDisabled?: boolean
}

export const BrandDetails = ({
  name,
  min,
  currency,
  spent,
  image,
  country,
  big = false,
  brandKey,
  numberOfItems,
  isSelectedForCheckout,
  isDisabled = false,
}: BrandDetailsProps) => {
  const { t } = useTranslate('cart')
  const onError = (errors: ErrorResponse) => {
    if (errors.response.data.code[0]) {
      errorToast(t(errors.response.data.code[0]))
    }
  }
  const { mutateAsync: selectItemInCart, isLoading: isLoadingSelectItemInCart } = useSelectItemInCart(onError)

  return (
    <div className={css.container}>
      <div className={css.brandData}>
        {typeof isSelectedForCheckout === 'boolean' && (
          <Checkbox
            id={name}
            testId={`select-brand-${brandKey}`}
            name={name}
            className={css.checkboxInput}
            isChecked={isSelectedForCheckout}
            onChange={(isChecked) => void selectItemInCart({ isSelected: isChecked, brandKey: brandKey })}
            CheckedIcon={<CheckboxIcon fill="#33333a" />}
            isDisabled={isLoadingSelectItemInCart || isDisabled}
          />
        )}
        <Avatar imageWidth={big ? 42 : 32} imageHeight={big ? 42 : 32} src={image} alt={name} />
        <div>
          <div className={cn(css.brandName, big ? css.big : '')}>
            <Link href={`/brand/${brandKey}`}>{name}</Link>{' '}
            {!!numberOfItems && <span style={{ fontWeight: 400 }}>{numberOfItems}</span>}
          </div>

          {!!country && <span className={css.country}>{country}</span>}
        </div>
      </div>
      {min !== undefined && !!currency && spent !== undefined && (
        <MinimumOrderStatus min={min} currency={currency} spent={spent} />
      )}
    </div>
  )
}
