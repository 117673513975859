import { SVGProps } from 'react'

const SvgRegularHome = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6 19h3v-6h6v6h3v-9l-6-4.5L6 10v9Zm0 2c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 4 19v-9c0-.317.071-.617.213-.9.141-.283.337-.517.587-.7l6-4.5a2.11 2.11 0 0 1 .575-.3c.2-.067.408-.1.625-.1.217 0 .425.033.625.1s.392.167.575.3l6 4.5A1.99 1.99 0 0 1 20 10v9c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 18 21h-5v-6h-2v6H6Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgRegularHome
