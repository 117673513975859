import { SVGProps } from 'react'

const SvgSolidNote = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.75 16.25C3.3375 16.25 2.98425 16.1033 2.69025 15.8097C2.39675 15.5157 2.25 15.1625 2.25 14.75V4.25C2.25 3.8375 2.39675 3.48425 2.69025 3.19025C2.98425 2.89675 3.3375 2.75 3.75 2.75H14.25C14.6625 2.75 15.0157 2.89675 15.3097 3.19025C15.6033 3.48425 15.75 3.8375 15.75 4.25V11.75L11.25 16.25H3.75ZM8.25 11C8.4625 11 8.64075 10.928 8.78475 10.784C8.92825 10.6405 9 10.4625 9 10.25C9 10.0375 8.92825 9.85925 8.78475 9.71525C8.64075 9.57175 8.4625 9.5 8.25 9.5H6C5.7875 9.5 5.60925 9.57175 5.46525 9.71525C5.32175 9.85925 5.25 10.0375 5.25 10.25C5.25 10.4625 5.32175 10.6405 5.46525 10.784C5.60925 10.928 5.7875 11 6 11H8.25ZM12 8C12.2125 8 12.3905 7.928 12.534 7.784C12.678 7.6405 12.75 7.4625 12.75 7.25C12.75 7.0375 12.678 6.85925 12.534 6.71525C12.3905 6.57175 12.2125 6.5 12 6.5H6C5.7875 6.5 5.60925 6.57175 5.46525 6.71525C5.32175 6.85925 5.25 7.0375 5.25 7.25C5.25 7.4625 5.32175 7.6405 5.46525 7.784C5.60925 7.928 5.7875 8 6 8H12ZM10.5 14.75L14.25 11H11.25C11.0375 11 10.8595 11.0717 10.716 11.2153C10.572 11.3593 10.5 11.5375 10.5 11.75V14.75Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSolidNote
