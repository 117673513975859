import { useState } from 'react'
import { FaInstagram, FaLinkedin, FaSquareFacebook } from 'react-icons/fa6'

import cn from 'classnames'

import Link from 'components/link'

import { useTranslate } from 'hooks'

import { ROUTES } from 'constants/routes'

import FooterLinkGroupRow from './footer-link-group-row'
import css from './footer.module.scss'
import NewsletterInput from './newsletter-input'

const MARKATO_LINKEDIN_URL = 'https://hk.linkedin.com/company/markato-wholesale'
const MARKATO_INSTAGRAM_URL = 'https://www.instagram.com/markato_wholesale'
const MARKATO_FACEBOOK_URL = 'https://www.facebook.com/markatowholesale'
const MARKATO_RETAILERS_SUPPORT_URL = 'https://support.markato.com/hc/en-us/sections/7196765327503-New-as-a-Retailer'
const MARKATO_BRANDS_SUPPORT_URL = 'https://support.markato.com/hc/en-us/sections/7196790478479-New-as-a-Brand'
const MARKATO_CAREERS_URL = 'https://www.linkedin.com/company/markato-wholesale/jobs'

export const Footer = () => {
  const { t } = useTranslate('common')
  const [submitted, setSubmitted] = useState(false)

  const handleNewsletter = (email: string) => {
    fetch('/api/newsletter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then(() => {
        setSubmitted(true)
      })
      .catch(() => {
        setSubmitted(true)
      })
  }

  return (
    <div className={css.container}>
      <div className={css.directory}>
        <div className={css.subscriptionWrapper}>
          <h5 className={cn(css.emailSubscriptionTitle, css.brandFont)}>{t('get_on_the_list')}</h5>
          <p className={css.emailSubscriptionDesc}>{t('get_on_the_list_description')}</p>
          <div className={css.newsLetterAction}>
            {submitted ? (
              <p style={{ marginTop: '40px', marginBottom: '40px' }}>{t('thank_you')}!</p>
            ) : (
              <NewsletterInput placeholder={t('type_your_email')} onSubmit={handleNewsletter} />
            )}
          </div>
          <div className={css.sns}>
            <Link className={css.link} target="_blank" href={MARKATO_INSTAGRAM_URL} ariaLabel="Instagram">
              <FaInstagram />
            </Link>
            <Link className={css.link} target="_blank" href={MARKATO_FACEBOOK_URL} ariaLabel="Facebook">
              <FaSquareFacebook />
            </Link>
            <Link className={css.link} target="_blank" href={MARKATO_LINKEDIN_URL} ariaLabel="LinkedIn">
              <FaLinkedin />
            </Link>
          </div>
        </div>
        <div className={css.linkGroupsWrapper}>
          <div className={css.linkGroupColumn}>
            <h5 className={cn(css.linkGroupTitle, css.brandFont)}>{t('company')}</h5>
            <ul className={css.linkGroup}>
              <li>
                <Link className={css.link} href={ROUTES.ABOUT_US}>
                  {t('about_us')}
                </Link>
              </li>
              <li>
                <Link className={css.link} href={ROUTES.QUICK_START_GUIDE}>
                  {t('how_it_works')}
                </Link>
              </li>
              <li>
                <Link className={css.link} href={ROUTES.MEDIA_COVERAGE}>
                  {t('media_coverage')}
                </Link>
              </li>
              <li>
                <Link className={css.link} target="_blank" href={MARKATO_CAREERS_URL}>
                  {t('careers')}
                </Link>
              </li>
            </ul>
          </div>
          <div className={css.linkGroupColumn}>
            <h5 className={cn(css.linkGroupTitle, css.brandFont)}>{t('helpful_links')}</h5>
            <ul className={css.linkGroup}>
              <li>
                <Link className={css.link} target="_blank" href={MARKATO_RETAILERS_SUPPORT_URL}>
                  {t('support_for_retailers')}
                </Link>
              </li>
              <li>
                <Link className={css.link} target="_blank" href={MARKATO_BRANDS_SUPPORT_URL}>
                  {t('support_for_brands')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={css.footerLinkGroupRowWrapper}>
        <FooterLinkGroupRow />
      </div>
    </div>
  )
}
