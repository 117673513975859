import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import Image from 'components/image'
import Logo from 'components/logo'
import Modal from 'components/modal'

import { useTranslate } from 'hooks/translation/use-translation'

import { CreateNewPassword } from './Steps/create-new-password'
import { EnterUsername } from './Steps/enter-username'
import { ThankYou } from './Steps/thank-you'
import css from './forgot-password.module.scss'

type ForgotPasswordProps = { isOpen: boolean; onChange: () => void; onLoginClick: () => void; loginUsername: string }

export const ForgotPassword = ({ isOpen, onChange, onLoginClick, loginUsername }: ForgotPasswordProps) => {
  const [step, setStep] = useState<number>(0)
  const [username, setUsername] = useState<string>('')
  const [code, setCode] = useState<string>('')

  const router = useRouter()

  const { t } = useTranslate('registration')

  useEffect(() => {
    if (router.query.username && router.query.code && !router.query.type) {
      setStep(1)
      setUsername(router.query.username as string)
      setCode(router.query.code as string)
      onChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  const switchSteps = (step: number) => {
    switch (step) {
      case 1:
        return <CreateNewPassword username={username} code={code} step={step} setStep={setStep} />
      case 2:
        return <ThankYou setIsOpen={onChange} setStep={setStep} setUsername={setUsername} onLoginClick={onLoginClick} />
      case 0:
      default:
        return <EnterUsername username={username || loginUsername} setUsername={setUsername} setStep={setStep} />
    }
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onChange={() => {
          setStep(0)
          setUsername('')
          setCode('')
          onChange()
        }}
        trackName="forgot-password"
      >
        <div className={css.modalWrapper}>
          <div className={css.leftSideStyle}>
            <div className={css.logoStyle}>
              <Logo width={130} height={34.92} />
            </div>
            <div className={css.imageStyle}>
              <Image src="/images/reset-password.svg" width={200} height={165} alt="Forgot Password Image" />
            </div>
            <p className={css.titleStyle}>{t('Password reset')}</p>
          </div>
          <div className={css.rightSideStyle}>{switchSteps(step)}</div>
        </div>
      </Modal>
    </div>
  )
}
