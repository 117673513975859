import { StateMapping, UiState } from 'instantsearch.js'
import { isEmpty, isString } from 'lodash'

import { BESTSELLERS_QUERY, NEW_PUBLISHED_QUERY } from 'components/_pages/search/refinement-by-query/constants'

import { INSTANT_SEARCH_ATTRIBUTES, INSTANT_SEARCH_INDEX } from 'constants/instantSearch'
import { ROUTES } from 'constants/routes'

export const handleCategoriesSplit = (categories: string) => {
  // handle case for some category with ', '
  return categories
    .replaceAll(', ', '\\t')
    .split(',')
    .map((str) => str.replaceAll('\\t', ', '))
}

export type SearchRouteState = {
  query: string | undefined
  cat: string | undefined
  brand: string[] | undefined
  origin: string[] | undefined
  madein: string[] | undefined
  keywords: string[] | undefined
  wprice: string | undefined
  rprice: string | undefined
  leadtime: string | undefined
  onsale: string | undefined
  sortBy: string | undefined
  mov: string | undefined
  page: number | undefined
  ['best-sellers']: string | undefined
  ['new-published']: string | undefined
}

export type ExtendedSearchUIState = UiState

export const getStateMapping = (): StateMapping<ExtendedSearchUIState, SearchRouteState> => {
  return {
    stateToRoute(uiState) {
      const indexUiState = uiState[INSTANT_SEARCH_INDEX['variants-from-connector'].name]

      const sortByKey = indexUiState.sortBy as keyof typeof INSTANT_SEARCH_INDEX

      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      if (isDiscoverPage()) {
        return {} as SearchRouteState
      }

      return {
        query: indexUiState.query,
        cat: indexUiState.hierarchicalMenu?.[INSTANT_SEARCH_ATTRIBUTES['en-hk'].CATEGORIES_LV0.attr].toString(),
        brand: indexUiState.refinementList?.[INSTANT_SEARCH_ATTRIBUTES.BRAND_NAMES.attr],
        origin: indexUiState.refinementList?.[INSTANT_SEARCH_ATTRIBUTES.BRAND_LOCATION.attr],
        madein: indexUiState.refinementList?.[INSTANT_SEARCH_ATTRIBUTES.MADE_IN.attr],
        keywords: indexUiState.refinementList?.[INSTANT_SEARCH_ATTRIBUTES.KEYWORDS.attr],
        wprice: indexUiState.range?.[INSTANT_SEARCH_ATTRIBUTES.UNIVERSAL_PRICE.attr],
        rprice: indexUiState.range?.[INSTANT_SEARCH_ATTRIBUTES.UNIVERSAL_MSRP.attr],
        leadtime: indexUiState.range?.[INSTANT_SEARCH_ATTRIBUTES.PRODUCTION_LEAD_TIME.attr],
        onsale: indexUiState.numericMenu?.[INSTANT_SEARCH_ATTRIBUTES.ON_SALE_PERCENTAGE.attr],
        mov: indexUiState.range?.[INSTANT_SEARCH_ATTRIBUTES.UNIVERSAL_BRAND_MOV.attr],
        sortBy: isEmpty(INSTANT_SEARCH_INDEX[sortByKey]) ? undefined : INSTANT_SEARCH_INDEX[sortByKey].query,
        page: indexUiState.page,
        ['best-sellers']:
          urlParams.get(BESTSELLERS_QUERY.name) === BESTSELLERS_QUERY.queryOnValue
            ? BESTSELLERS_QUERY.queryOnValue
            : undefined,
        ['new-published']:
          urlParams.get(NEW_PUBLISHED_QUERY.name) === NEW_PUBLISHED_QUERY.queryOnValue
            ? NEW_PUBLISHED_QUERY.queryOnValue
            : undefined,
      }
    },
    routeToState(routeState) {
      // Fix ?srsltid= from Google
      const discoverQuery = location.pathname.split('/discover/')[1]
      if (discoverQuery && !routeState.query) {
        routeState.query = discoverQuery
      }

      return {
        [INSTANT_SEARCH_INDEX['variants-from-connector'].name]: {
          query: routeState.query,
          hierarchicalMenu: {
            [INSTANT_SEARCH_ATTRIBUTES['en-hk'].CATEGORIES_LV0.attr]: isString(routeState.cat)
              ? handleCategoriesSplit(routeState.cat)
              : [],
          },
          refinementList: {
            [INSTANT_SEARCH_ATTRIBUTES.BRAND_NAMES.attr]: routeState.brand ?? [],
            [INSTANT_SEARCH_ATTRIBUTES.BRAND_LOCATION.attr]: routeState.origin ?? [],
            [INSTANT_SEARCH_ATTRIBUTES.MADE_IN.attr]: routeState.madein ?? [],
            [INSTANT_SEARCH_ATTRIBUTES.KEYWORDS.attr]: routeState.keywords ?? [],
          },
          range: {
            [INSTANT_SEARCH_ATTRIBUTES.PRODUCTION_LEAD_TIME.attr]: routeState.leadtime ?? '',
            [INSTANT_SEARCH_ATTRIBUTES.UNIVERSAL_PRICE.attr]: routeState.wprice ?? '',
            [INSTANT_SEARCH_ATTRIBUTES.UNIVERSAL_BRAND_MOV.attr]: routeState.mov ?? '',
            [INSTANT_SEARCH_ATTRIBUTES.UNIVERSAL_MSRP.attr]: routeState.rprice ?? '',
          },
          numericMenu: {
            [INSTANT_SEARCH_ATTRIBUTES.ON_SALE_PERCENTAGE.attr]: routeState.onsale ?? '',
          },
          sortBy: Object.values(INSTANT_SEARCH_INDEX).find((value) => value.query === routeState.sortBy)?.name,
          page: routeState.page,
        },
      }
    },
  }
}

export const isDiscoverPage = () => location.pathname.includes(ROUTES.DISCOVER)

export const decodeDiscoverQuery = (query: string) => {
  if (!query) return query
  return decodeURI(query.replaceAll('-', ' '))
}
