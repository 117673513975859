import cn from 'classnames'

import css from './notification.module.scss'

type NotificationProps = {
  numberOfNotifications: number
  variant?: 'primary' | 'secondary' | 'tertiary'
}

export const Notification = ({ numberOfNotifications, variant = 'primary' }: NotificationProps) => {
  return <span className={cn(css.container, css[variant])}>{numberOfNotifications}</span>
}
