import { ReactElement } from 'react'

import { ButtonProps } from '../button'
import Menu from '../menu'
import PopUp, { PopUpPlacementType } from '../popup'
import css from './button-menu.module.scss'

export type ButtonMenuProps = {
  children: JSX.Element[] | JSX.Element
  button: ReactElement<ButtonProps>
  isWithWidth?: boolean
  placement?: string
}

const ButtonMenu = ({ children, button, isWithWidth = true, placement = 'bottom-end' }: ButtonMenuProps) => (
  <PopUp placement={placement as PopUpPlacementType}>
    {button}
    <Menu className={isWithWidth ? css.menu : ''}>{children}</Menu>
  </PopUp>
)

export default ButtonMenu
