import { MarkatoMiddleware } from './lib/types'

const OFFICE_IP = '220.246.127.69'
const FIGURIST_AGENCY = '42.200.200.187'
const ASSEMBLY_AGENCY = '42.200.136.78'

const EXCLUDED_IP_LIST = [OFFICE_IP, FIGURIST_AGENCY, ASSEMBLY_AGENCY]

export const IS_INTERNAL_TRAFFIC = 'isInternalTraffic'

export const clientIp: MarkatoMiddleware = (_req, _response) => {
  const clientIp =
    _req.headers.get('x-forwarded-for') ??
    _req.headers.get('x-real-ip') ??
    _req.headers.get('cf-connection-ip') ??
    'Unknown'

  const isInternalTraffic = EXCLUDED_IP_LIST.some((excludedIp) => clientIp.toString().includes(excludedIp))

  _response.cookies.set(IS_INTERNAL_TRAFFIC, isInternalTraffic.toString())

  return _response
}
