import { useMutation } from 'react-query'

import httpClient from '../../../services/http-client'

export const POST_TEMP_PASSWORD_BRAND = '/brand/auth/temp-password'

type TempPasswordRequest = {
  email: string
  session: string
  newPassword: string
  confirmPassword: string
}

type ErrorResponse = {
  statusCode: number
  code: string[]
}

export type ErrorMessages = {
  response: {
    data: ErrorResponse
  }
}

export type TempPasswordResponse = {
  accessToken: string
  expiresIn: number
}

const PostTempPasswordBanrd = async (form: TempPasswordRequest) => {
  return await httpClient.put<TempPasswordResponse>(`${POST_TEMP_PASSWORD_BRAND}`, form)
}

// TO-DO: Easy extend to retailer for future
const getPostTempPassword = (userType: string) =>
  userType.toLocaleLowerCase() === 'brand' ? PostTempPasswordBanrd : PostTempPasswordBanrd

export const usePostTempPassword = (
  userType: string,
  onSuccess: (response: TempPasswordResponse) => void,
  onError: (errors: string[]) => void
) => {
  return useMutation(getPostTempPassword(userType), {
    onError: (error: ErrorMessages) => {
      onError(error.response.data.code)
    },
    onSuccess: (response) => {
      onSuccess(response.data)
    },
  })
}
