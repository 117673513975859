import cn from 'classnames'

import Link from 'components/link'

import { useTranslate } from 'hooks'

import css from './index.module.scss'

type FooterLinkGroupRowProps = {
  className?: string
}

const FooterLinkGroupRow = ({ className }: FooterLinkGroupRowProps) => {
  const { t } = useTranslate('common')

  return (
    <ul className={cn([css.linkGroupRow, className])}>
      <li>
        <span>{t('all_rights_reserved', { currYear: new Date().getFullYear() })}</span>
      </li>
      <li>
        <Link href={'/brand-terms'} target="_blank" className={css.link}>
          {t('brand_terms_and_conditions')}
        </Link>
      </li>
      <li>
        <Link href={'/retailer-terms'} target="_blank" className={css.link}>
          {t('retailer_terms_and_conditions')}
        </Link>
      </li>
      <li>
        <Link href={'/privacy-policy'} target="_blank" className={css.link}>
          {t('privacy_policy')}
        </Link>
      </li>
    </ul>
  )
}

export default FooterLinkGroupRow
