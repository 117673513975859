import type { UserType } from '.'

import Button from 'components/button'
import Logo from 'components/logo'
import Modal from 'components/modal'

import { useTranslate } from 'hooks'

import styles from './choose-login.module.scss'

export const ChooseLogin = ({
  isOpen,
  onChange,
  openLogin,
}: {
  isOpen: boolean
  onChange: () => void
  openLogin: (userType: UserType) => void
}) => {
  const { t } = useTranslate('common')

  return (
    <Modal isOpen={isOpen} onChange={onChange} trackName="login-choose">
      <div className={styles.container}>
        <p className={styles.loginTo}>{t('login.login_to')}</p>
        <div className={styles.markato}>
          <Logo width={121} height={32.5} />
        </div>
        <Button
          data-test-id="continue-retailer-login-button"
          className={styles.loginButton}
          size="large"
          label={t('login.continue_retailer')}
          onClick={() => openLogin('retailer')}
        />
        <Button
          data-test-id="continue-brand-login-button"
          className={styles.brandButton}
          style="tertiary"
          size="large"
          label={t('login.continue_brand')}
          onClick={() => openLogin('brand')}
        />
      </div>
    </Modal>
  )
}
