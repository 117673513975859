import { ClipLoader } from 'react-spinners'

import cn from 'classnames'

import { fireTrackingEvent } from 'utils/tracking'

import css from './button.module.scss'

export type ButtonProps = {
  type?: 'button' | 'submit'
  style?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'rounded' | 'favouriteButton'
  size?: 'small' | 'medium' | 'large'
  color?: 'blue' | 'red' | 'green' | 'secondary-yellow-1' | 'white' | 'whiteLink'
  label?: string
  disabled?: boolean
  icon?: JSX.Element
  isLoading?: boolean
  hoverIcon?: JSX.Element
  iconLeft?: JSX.Element
  hasIconRight?: boolean
  classNameIconLeft?: string
  className?: string
  isActive?: boolean
  forceActive?: boolean
  forceInactive?: boolean
  trackName?: string
  testId?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button = ({
  style = 'primary',
  size = 'medium',
  label,
  disabled = false,
  icon,
  isLoading,
  iconLeft,
  hasIconRight,
  className,
  classNameIconLeft,
  hoverIcon,
  color = 'blue',
  type = 'button',
  forceActive = false,
  forceInactive = false,
  trackName = '',
  testId = '',
  onClick,
  ...props
}: ButtonProps) => (
  <button
    type={type}
    disabled={disabled || isLoading}
    data-test-id={testId}
    className={cn(
      className,
      css.button,
      css[size],
      css[style],
      css[color],
      css[disabled && !isLoading ? 'disabled' : ''],
      css[forceActive ? `${style}--active` : ''],
      css[forceInactive ? `${style}--inactive` : ''],
      !label ? css.noLabel : '',
      hasIconRight && css.reverse,
      hoverIcon && css.hasHoverIcon
    )}
    onClick={(ev) => {
      onClick?.(ev)
      trackName &&
        fireTrackingEvent('button', {
          name: trackName,
        })
    }}
    {...props}
  >
    {isLoading ? (
      <ClipLoader color="white" size={24} />
    ) : (
      <>
        {!!iconLeft && <span className={cn(css.icon, css.iconLeft, classNameIconLeft)}>{iconLeft}</span>}
        <span>{label}</span>
        {!!icon && <span className={cn(css.icon, hasIconRight && css.iconRight)}>{icon}</span>}
      </>
    )}
    {!!hoverIcon && <span className={css.hoverIcon}>{hoverIcon}</span>}
  </button>
)

export default Button
