import cn from 'classnames'

import css from './label.module.scss'

type LabelProps = {
  htmlFor: string
  children?: React.ReactNode
  className?: string
}

const Label = ({ children, htmlFor, className }: LabelProps) => (
  <label htmlFor={htmlFor} className={cn(css.container, className)}>
    {children}
  </label>
)

export default Label
