/* eslint-disable @typescript-eslint/no-explicit-any */
export const useZendesk = () => {
  const zendesk = (type: string, settings: any, retries = 3) => {
    // eslint-disable-next-line
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (typeof window.zE === 'function') {
      // eslint-disable-next-line
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.zE('webWidget', type, settings)
    } else if (retries > 0) {
      setTimeout(() => zendesk(type, settings, retries - 1), 3000)
    }
  }

  const updateSettings = (settings: any) => {
    zendesk('updateSettings', settings)
  }

  const prefill = (settings: any) => {
    zendesk('prefill', settings)
  }

  return {
    updateSettings,
    prefill,
  }
}
