import { useRouter } from 'next/router'
import { useState } from 'react'

import Button from 'components/button'
import ButtonMenu from 'components/button-menu'
import { LightLanguageGlobe } from 'components/icon/light'
import { PopUpPlacementType } from 'components/popup'
import Select from 'components/select'
import { getUserType } from 'features/login/use-login'

import { useTranslate } from 'hooks'
import { useIsLoggedIn } from 'hooks/use-is-logged-in'

import css from '../app-header/app-header.module.scss'
import { langOptions, regionOptions } from './constants'

type LangSwitcherProps = {
  placement?: PopUpPlacementType
}

const LangSwitcher = ({ placement }: LangSwitcherProps) => {
  const getAsPath = (asPath: string) => asPath.split('?')[0] + location.search
  const { t, lang } = useTranslate('common')
  const { accessToken } = useIsLoggedIn()
  const userType = accessToken ? getUserType(accessToken) : 'Guest'

  const [region, setRegion] = useState(lang.toLowerCase().split('-')[1])
  const [language, setLanguage] = useState(lang.toLowerCase().split('-')[0])
  const router = useRouter()

  return (
    <ButtonMenu
      isWithWidth={false}
      button={
        <Button
          className={[css.button, css.langSwitcher, css.borderlessButton].join(' ')}
          size="large"
          classNameIconLeft={css.langText}
          iconLeft={<LightLanguageGlobe fontSize={28} />}
          label={lang.toLocaleUpperCase()}
        />
      }
      placement={placement}
    >
      <Select
        name="region"
        label={t('header.region')}
        options={regionOptions}
        onChange={(value) => {
          setRegion(value as string)
          setLanguage(langOptions[value as keyof typeof langOptions][0].value)
        }}
        value={regionOptions.filter((option) => option.value === region)}
        isDisabled={accessToken !== null && (userType === 'Retailer' || userType === 'Guest')}
      />
      <Select
        name="language"
        label={t('header.language')}
        options={langOptions[region as keyof typeof langOptions]}
        onChange={(value) => {
          setLanguage(value as string)
        }}
        value={langOptions[region as keyof typeof langOptions].filter((option) => option.value === language)}
      />
      <Button
        style="primary"
        label={t('header.save')}
        className={css.saveButton}
        onClick={() => (window.location.href = `/${language}-${region}${getAsPath(router.asPath)}`)}
      />
    </ButtonMenu>
  )
}

export default LangSwitcher
