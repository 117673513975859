import { forwardRef, useEffect, useState } from 'react'

import cn from 'classnames'

import { RegularMinus, RegularPlus } from 'components/icon/regular'
import Input from 'components/input'

import css from './counter.module.scss'

export type CounterProps = {
  name: string
  value?: number
  label?: string
  labels?: string[]
  increment?: number
  min?: number
  max?: number
  errorMessage?: string
  totalUnit?: string
  small?: boolean
  isDisabled?: boolean
  isDisabledPlus?: boolean
  isDisabledMinus?: boolean
  minusTestId?: string
  plusTestId?: string
  onChange: (value: number) => void
  formatValue?: (value: number, increment: number, small: boolean, totalUnit?: string) => string
}

const defaultFormatValue = (value: number, increment: number, small: boolean, totalUnit = '') => {
  if (small) {
    return value ? `${value}` : '0'
  }
  return value ? `${value} (${Number(value) * increment}${totalUnit ? ' ' + totalUnit : ''})` : '0'
}

const Counter = forwardRef<HTMLInputElement, CounterProps>(
  (
    {
      name,
      label,
      labels,
      onChange,
      min = 0,
      max,
      increment = 1,
      value = 0,
      errorMessage = '',
      totalUnit = '',
      small = false,
      isDisabled,
      isDisabledPlus,
      isDisabledMinus,
      minusTestId,
      plusTestId,
      formatValue = defaultFormatValue,
    },
    ref
  ) => {
    const [formattedValue, setFormattedValue] = useState<string>('')
    const [displayValue, setDisplayValue] = useState(0)

    useEffect(() => {
      setDisplayValue(Number(value))
      setFormattedValue(formatValue(value, increment, small, totalUnit))
    }, [formatValue, increment, small, value, totalUnit])

    const handleChange = (newVal: number) => {
      if (typeof max !== 'undefined' && newVal > max) {
        return
      }
      if (typeof min !== 'undefined' && newVal < min) {
        return
      }
      onChange(newVal)
    }

    return (
      <div className={cn(css.container, small ? css.small : '')}>
        <Input
          name={name}
          label={label}
          labels={labels}
          value={formattedValue}
          ref={ref}
          readOnly
          errorMessage={errorMessage}
          prefixed={
            <button
              data-test-id={minusTestId}
              disabled={isDisabled ?? isDisabledMinus}
              onClick={() => handleChange(displayValue - 1)}
              style={{ cursor: 'pointer' }}
            >
              <RegularMinus />
            </button>
          }
          suffix={
            <button
              data-test-id={plusTestId}
              disabled={isDisabled ?? isDisabledPlus}
              onClick={() => handleChange(displayValue + 1)}
              style={{ cursor: 'pointer' }}
            >
              <RegularPlus />
            </button>
          }
        />
      </div>
    )
  }
)

export default Counter
Counter.displayName = 'Counter'
