import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import Button from 'components/button'
import Input from 'components/input'
import { PasswordField } from 'components/password-field'
import PasswordValidation from 'components/password-validation'
import { StatusInfo } from 'components/status-info'
import Toast from 'components/toast'

import { useTranslate } from 'hooks/translation/use-translation'

import css from '../forgot-password.module.scss'
import { useConfirmForgotPassword } from '../hooks/use-confirm-forgot-password'
import { usePostForgotPassword } from '../hooks/use-post-forgot-password'

type CreateNewPasswordProps = {
  username: string
  code: string
  step: number
  setStep: Dispatch<SetStateAction<number>>
}

const TIMER_TIMEOUT = 2000

export const CreateNewPassword = ({ username, code, step, setStep }: CreateNewPasswordProps) => {
  const { t } = useTranslate('registration')
  const [isCodeCorrect, setIsCodeCorrect] = useState<boolean>(false)
  const [showResendStatus, setShowResendStatus] = useState(false)
  const [errorM, setErrorM] = useState<string[]>([])
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)

  const onSuccess = () => {
    setStep(step + 1)
  }
  const onError = (errorMessages: string[]) => {
    setErrorM(errorMessages)
  }

  const { register, onSubmit, watch, errors, isLoading } = useConfirmForgotPassword({
    username,
    code,
    onSuccess,
    onError,
  })

  const { mutateAsync: postForgotPassword } = usePostForgotPassword()

  const codeValues = watch('confirmationCode')

  useEffect(() => {
    if (!codeValues) return
    if (codeValues.length > 5) {
      setIsCodeCorrect(true)
    }
  }, [codeValues])

  const handleClickOnResend = async () => {
    setShowResendStatus(true)
    await postForgotPassword({ username })
    setTimeout(() => {
      setShowResendStatus(false)
    }, TIMER_TIMEOUT)
  }

  const getMessages = (errorMessages: string[]) => {
    return errorMessages.map((message) => {
      return t(message)
    })
  }

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    void onSubmit()
  }

  const passwordValues = watch('newPassword')
  const confirmPasswordValue = watch('confirmPassword')

  return (
    <>
      <p className={css.descriptionStyle}>
        {t('We have sent')}
        <b> {username}</b>
      </p>
      <form onSubmit={handleSubmit} style={{ position: 'relative', height: '100% ' }}>
        <Input
          label={t('Enter the code')}
          {...register('confirmationCode')}
          data-test-id="forget-password-code-input"
        />
        <div className={css.resendWrapperStyle}>
          {showResendStatus ? (
            <StatusInfo text="Successfully sent" imagePath="/images/icons/check.svg" />
          ) : (
            <div className={css.resendAction}>
              <p className={css.textStyle}>{t('Didnt get it?')}</p>
              <Button
                label={t('Resend')}
                size="small"
                className={css.buttonResendStyle}
                onClick={handleClickOnResend}
              />
            </div>
          )}
          {!isCodeCorrect && <p className={css.remindTextStyle}>{t('Verify Code Remind')}</p>}
        </div>

        {!!isCodeCorrect && (
          <div data-test-id="enter-new-password-div">
            <p className={css.titleStyle}>{t('Create New Password')}</p>
            <PasswordField
              data-test-id="new-password-input"
              placeholder={t('Create password')}
              label={t('new_password')}
              errorMessage={errors.newPassword?.message?.toString()}
              {...register('newPassword', {
                required: t('password_required'),
                pattern: {
                  // eslint-disable-next-line no-useless-escape
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/,
                  message: t('wrong_password_format'),
                },
              })}
            />

            <PasswordValidation
              passwordValue={passwordValues}
              isPasswordValid={isPasswordValid}
              onPasswordValidChange={setIsPasswordValid}
            />

            <PasswordField
              data-test-id="repeat-new-password-input"
              placeholder={t('Repeat password')}
              label={t('confirm_new_password')}
              {...register('confirmPassword')}
            />

            <div className={css.errorMsgs}>
              {errorM.length > 0 && <Toast type="alert" messages={getMessages(errorM)} />}
            </div>

            <Button
              data-test-id="set-new-password-button"
              type="submit"
              style="secondary"
              size="large"
              label={t('Set New Password')}
              className={css.buttonWrapperStyle}
              disabled={!isPasswordValid || !confirmPasswordValue}
              isLoading={isLoading}
            />
          </div>
        )}
      </form>
    </>
  )
}
