import cn from 'classnames'
import { throttle } from 'lodash'

import Counter from 'components/counter'
import { RegularClose } from 'components/icon/regular'
import ImageComponent from 'components/image'
import Link from 'components/link'
import Toast from 'components/toast'

import { useTranslate } from 'hooks'

import { formatPrice } from 'utils/functions'

import css from './cart-item.module.scss'

type CartItemProps = {
  sku: string
  brandKey: string
  productKey: string
  picture: string
  name: string
  caseQuantity: number
  quantity?: number
  price: number
  currency: string
  minOrderQuantity: number
  maxOrderQuantity: number
  onChange: (brandKey: string, sku: string, currentQuantity: number, newQuantity: number) => void
  onRemove: (brandKey: string, sku: string) => void
  isClickDisabled: boolean
  variant?: string | null
  variantKey: string
  isOutOfStock?: boolean
}

export const CartItem = ({
  brandKey,
  sku,
  picture,
  name,
  caseQuantity,
  quantity = 1,
  onChange,
  onRemove,
  price,
  currency,
  isClickDisabled,
  minOrderQuantity,
  maxOrderQuantity,
  variant,
  variantKey,
  productKey,
  isOutOfStock = false,
}: CartItemProps) => {
  const { t } = useTranslate('common')
  return (
    <div className={cn(css.container)}>
      <div className={css.image}>
        <ImageComponent src={picture} alt={name} width={60} height={60} />
      </div>
      <div className={css.content}>
        <div>
          <h4>
            <Link href={`/product/${productKey}`}>
              {name}
              {variant ? `- ${variant}` : ''}
            </Link>
          </h4>
          {!!isOutOfStock && <Toast type={'alert'} messages={[t('cart.remove_from_cart')]} hidden={false} />}
          {!isOutOfStock && (
            <div className={css.counter}>
              <Counter
                name="quantity"
                small
                min={minOrderQuantity}
                max={maxOrderQuantity}
                value={quantity}
                isDisabled={isClickDisabled}
                onChange={throttle((val: number) => onChange(brandKey, variantKey, quantity, val), 300)}
              />
              <span>{t('case_quantity', { caseQuantity })}</span>
            </div>
          )}
        </div>
        <div className={css.rightContent}>
          <RegularClose
            onClick={() => {
              if (!isClickDisabled) {
                onRemove(brandKey, variantKey)
              }
            }}
            style={{ cursor: 'pointer' }}
          />
          {!isOutOfStock && <span>{formatPrice(price, currency)}</span>}
        </div>
      </div>
    </div>
  )
}
