import classNames from 'classnames'
import { NavMenuItem } from 'interfaces/navMenu'

import Link from 'components/link'

import { useIsLoggedIn } from 'hooks/use-is-logged-in'

import { useGetNavMenu } from '../../navbar/use-get-nav-menu'
import css from '../app-header.module.scss'
import CategoryAccordionMenuItem from '../category-accordion-menu-item'

type AccordionTabItemLinkProps = {
  name: string
  url: string
  onCloseMenu: () => void
}

type CategoriesMenuProps = {
  onCloseMenu: () => void
}

const AccordionTabItemLink = ({ name, url, onCloseMenu }: AccordionTabItemLinkProps) => {
  return (
    <div className={css.tab}>
      <Link href={url} onClick={onCloseMenu} isNextLink prefetch={false}>
        <div className={css.tabLabel}>{name}</div>
      </Link>
    </div>
  )
}

const CategoriesMenu = ({ onCloseMenu }: CategoriesMenuProps) => {
  const { data: navMenuItemList } = useGetNavMenu()
  const { isLoggedIn } = useIsLoggedIn()

  return (
    <div className={classNames([css.mobileNavigation, isLoggedIn && css.extraPadding])}>
      <ul className={css.tabs}>
        {navMenuItemList
          ?.slice()
          ?.sort((navMenu) => (navMenu.tag === 'promotion' ? -1 : 1))
          ?.map((navMenuItem: NavMenuItem) => {
            const { name, categoryNav, url, tag } = navMenuItem

            return (
              <li key={name}>
                {categoryNav && tag !== 'promotion' ? (
                  <CategoryAccordionMenuItem navMenuItem={navMenuItem} onCloseMenu={onCloseMenu} />
                ) : (
                  <AccordionTabItemLink name={name} url={url} onCloseMenu={onCloseMenu} />
                )}
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default CategoriesMenu
