import { SVGProps } from 'react'

const SvgLightArrowChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.875 14.5a.656.656 0 0 1-.225-.513c0-.208.075-.387.225-.537l4.5-4.475c.083-.1.18-.171.288-.213a.935.935 0 0 1 .675 0 .706.706 0 0 1 .287.213l4.525 4.5c.133.15.2.32.2.512a.707.707 0 0 1-.225.513.72.72 0 0 1-.525.225.72.72 0 0 1-.525-.225L12 10.45l-4.1 4.075a.641.641 0 0 1-.5.225.687.687 0 0 1-.525-.25Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightArrowChevronUp
