import { SVGProps } from 'react'

const RegularLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5272 4.58824C10.5272 6.00143 9.39615 7.14706 8.00091 7.14706C6.60566 7.14706 5.47459 6.00143 5.47459 4.58824C5.47459 3.17504 6.60566 2.02941 8.00091 2.02941C9.39615 2.02941 10.5272 3.17504 10.5272 4.58824ZM11.7904 4.58824C11.7904 6.70803 10.0938 8.42647 8.00091 8.42647C5.90804 8.42647 4.21143 6.70803 4.21143 4.58824C4.21143 2.46844 5.90804 0.75 8.00091 0.75C10.0938 0.75 11.7904 2.46844 11.7904 4.58824ZM5.89474 10.5588H10.1053C12.2253 10.5588 14.0127 12.0016 14.5625 13.9706H1.4375C1.98734 12.0016 3.77469 10.5588 5.89474 10.5588ZM10.1053 9.27941C12.9273 9.27941 15.2861 11.2879 15.8643 13.9706C15.9532 14.3828 16 14.8108 16 15.25H0C0 14.8108 0.0468109 14.3828 0.135655 13.9706C0.713903 11.2879 3.07274 9.27941 5.89474 9.27941H10.1053Z"
      fill="currentColor"
    />
  </svg>
)
export default RegularLogout
