import { UseQueryResult, useQuery } from 'react-query'

import { NavMenuItem } from 'interfaces/navMenu'

import { useTranslate } from 'hooks/translation/use-translation'

import httpClient from 'services/http-client'

import { TEN_MINUTES_STALE_TIME } from 'constants/apis'

const GET_NAV_MENU = 'NAV_MENU_QK'

export const useGetNavMenu = (): UseQueryResult<NavMenuItem[]> => {
  const { lang } = useTranslate('common')

  return useQuery({
    /* eslint-disable @tanstack/query/exhaustive-deps */
    queryKey: [GET_NAV_MENU, lang],
    queryFn: async () => {
      const { data: navMenus } = await httpClient.get<NavMenuItem[]>(`${location.origin}/api/nav-menu?lang=${lang}`)
      return navMenus
    },
    staleTime: TEN_MINUTES_STALE_TIME,
  })
}
