/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUserStore = /* GraphQL */ `
  subscription OnCreateUserStore($DOC: AWSJSON, $TTL: Int, $cognitoUserId: String) {
    onCreateUserStore(DOC: $DOC, TTL: $TTL, cognitoUserId: $cognitoUserId) {
      DOC
      TTL
      cognitoUserId
    }
  }
`
export const onDeleteUserStore = /* GraphQL */ `
  subscription OnDeleteUserStore($DOC: AWSJSON, $cognitoUserId: String) {
    onDeleteUserStore(DOC: $DOC, cognitoUserId: $cognitoUserId) {
      DOC
      TTL
      cognitoUserId
    }
  }
`
export const onUpdateUserStore = /* GraphQL */ `
  subscription OnUpdateUserStore($DOC: AWSJSON, $TTL: Int, $cognitoUserId: String) {
    onUpdateUserStore(DOC: $DOC, TTL: $TTL, cognitoUserId: $cognitoUserId) {
      DOC
      TTL
      cognitoUserId
    }
  }
`
