import { Option } from 'components/select'

export const STRING_PREVENT_INPUT_AUTO_FILL = 'one-time-code'

export const MAX_COOKIES_DAY = 400

export const DEFAULT_SOCIAL_IMAGE = 'https://www.markato.com/images/og.png'

export const DEFAULT_EEI_REFERENCE_NUMBER = 'NO EEI 30.37(a)'

export const KG_TO_LB_MULTIPLIER = 2.20462

export const LB_TO_KG_MULTIPLIER = 0.453592

export const CM_TO_IN_MULTIPLIER = 0.393701

export const IN_TO_CM_MULTIPLIER = 2.54

export const UNITED_STATES = 'USA'
export const CANADA = 'Canada'
export const AUSTRALIA = 'Australia'

export const customBox = [
  {
    label: 'Custom Box',
    value: 'custom-box',
  },
]

export const MAX_DIMENSIONAL_WEIGHT = 65

export const localeCookieName = 'NEXT_LOCALE'

export const REDIRECT_TO_COOKIE = 'redirectTo'

export const STORAGE_KEY = {
  RETAILER_FIRST_TIME_LOGIN: 'retailerFirstTimeLogin',
}

export const DROP_OFF_OPTION: Option = {
  label: 'Drop Off',
  value: 'drop_off',
}

export const BG_IMG_CDN_PREFIX = 'https://www.markato.com/cdn-cgi/image/quality=80,format=auto/'
