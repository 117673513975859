import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { HiGift } from 'react-icons/hi2'

import Button from 'components/button'
import {
  RegularHeart,
  RegularLogout,
  RegularPaymentTerms,
  RegularPerson,
  RegularReturnClean,
  RegularSettingsGear,
  RegularShoppingBag,
  RegularUpload,
  RegularWallet,
} from 'components/icon/regular'
import Link from 'components/link'
import { errorToast } from 'components/notification-toast'
import PopUp from 'components/popup'
import Spinner from 'components/spinner'
import { isSSOLogin } from 'features/login/use-login'
import { useGetPaymentTerms } from 'features/retailer/wallet/payment-terms/hooks/useGetPaymentTerms'

import { DynamoDbUser, useDynamoDbUserContext } from 'contexts/DynamoDbUserContext'
import { useTranslate } from 'hooks'
import { useDeleteImpersonateCookie } from 'hooks/common/use-delete-impersonate-cookie'
import { usePostLogout, usePostSsoLogout } from 'hooks/common/use-post-logout'

import { ErrorResponse } from 'services/error-response'
import { deleteAccessToken, getAccessToken, getCookie, getSessionId } from 'services/http-client'
import { fireTrackingEvent } from 'utils/tracking'

import { ROUTES } from 'constants/routes'

import css from '../app-header.module.scss'
import UserMenuItem from './user-menu-item'
import UserMenuLineSeparator from './user-menu-line-separator'

type UserMenuProps = {
  user: DynamoDbUser | undefined
  isMobile?: boolean
}

const iconSize = 20
const UserMenu = ({ user, isMobile = false }: UserMenuProps) => {
  const { t } = useTranslate('common')
  const { dynamoDbUser, unsetDynamoDbUser } = useDynamoDbUserContext()

  const router = useRouter()

  const onSuccess = () => {
    deleteAccessToken()
    unsetDynamoDbUser()
    fireTrackingEvent('success', {
      name: `success_logout`,
    })
    void router.push(ROUTES.HOME)
  }

  const onError = (errors: ErrorResponse) => {
    if (window.location.host.includes('localhost')) {
      onSuccess()
    } else {
      for (const error of errors.response.data.code) {
        errorToast(t(`error.${error}`))
      }

      // Handle unknown cookies error
      // Also Update: /hooks/common/use-retailer-logout.ts
      onSuccess()
    }
  }

  const { mutateAsync: deleteImpersonateCookie } = useDeleteImpersonateCookie(onSuccess)
  const { mutateAsync: postLogout } = usePostLogout(onSuccess, onError)
  const { mutateAsync: postSsoLogout } = usePostSsoLogout(onSuccess, onError)

  const [isVisible, setIsVisible] = useState(false)

  const handleLogout = async () => {
    const cookieImpersonate = getCookie('impersonated-token')
    if (cookieImpersonate && cookieImpersonate !== '') {
      await deleteImpersonateCookie()
      window.location.reload()
    } else {
      const token = getAccessToken()
      const isSSo = isSSOLogin(token ?? '')
      if (!isSSo) {
        await postLogout({ type: 'retailer', sessionId: getSessionId() })
      } else {
        await postSsoLogout({ type: 'sso', sessionId: getSessionId() })
      }
    }
  }

  useEffect(() => {
    setIsVisible(false)
  }, [router.pathname])

  const Menu = () => {
    const { data: terms, isLoading: isLoadingTerms } = useGetPaymentTerms()

    return (
      <div className={css.userMenu}>
        <UserMenuLineSeparator />
        <UserMenuItem>
          <div>
            <span className={css.iconWrapper}>
              <RegularPerson height={18} width={18} />
            </span>
            <span className={css.linkText}>
              {user?.info.firstName} {user?.info.lastName}
            </span>
          </div>
          <div className={css.menuEmail}>{user?.info.email}</div>
        </UserMenuItem>
        <UserMenuLineSeparator />
        <UserMenuItem>
          {!!isLoadingTerms && <Spinner loading />}
          {!isLoadingTerms &&
            dynamoDbUser !== undefined &&
            (terms?.paymentTerms.approved ? (
              <Link href={ROUTES.PAYMENT_TERMS_APPLY} className={css.paymentTermsLink}>
                <span className={css.iconWrapper}>
                  <RegularPaymentTerms className={css.paymentTermsIcon} height={iconSize} width={iconSize} />
                </span>
                <span className={css.linkText}>{t('header.increase')}</span>
              </Link>
            ) : (
              <Link
                href={ROUTES.PAYMENT_TERMS_APPLY}
                className={css.unlockTerms}
                dangerouslySetInnerHTML={{
                  __html: t('header.unlock', {
                    value: `${dynamoDbUser.info.currency} ${dynamoDbUser.info.autoBuyNowPayLaterAmount}`,
                    days: dynamoDbUser.info.buyNowPayLaterDelay,
                  }),
                }}
              />
            ))}
        </UserMenuItem>
        <UserMenuLineSeparator />
        <UserMenuItem marginBottom={false}>
          <Link href={ROUTES.RETAILER_FAVOURITES}>
            <span className={css.iconWrapper}>
              <RegularHeart height={iconSize} width={iconSize} />
            </span>
            <span className={css.linkText}>{t('header.favourites')}</span>
          </Link>
        </UserMenuItem>
        <UserMenuItem marginBottom={false}>
          <Link href={ROUTES.RETAILER_ORDERS}>
            <span className={css.iconWrapper}>
              <RegularShoppingBag height={iconSize} width={iconSize} />
            </span>
            <span className={css.linkText}>{t('header.my_orders')}</span>
          </Link>
        </UserMenuItem>
        <UserMenuItem marginBottom={false}>
          <Link href={ROUTES.RETAILER_RETURNS}>
            <span className={css.iconWrapper}>
              <RegularReturnClean height={iconSize} width={iconSize} />
            </span>
            <span className={css.linkText}>{t('header.returns')}</span>
          </Link>
        </UserMenuItem>
        <UserMenuItem marginBottom={false}>
          <Link href={ROUTES.RETAILER_WALLET}>
            <span className={css.iconWrapper}>
              <RegularWallet height={iconSize} width={iconSize} />
            </span>
            <span className={css.linkText}>{t('header.my_wallet')}</span>
          </Link>
        </UserMenuItem>
        <UserMenuItem marginBottom={false}>
          <Link href={ROUTES.RETAILER_REWARDS}>
            <span className={css.iconWrapper}>
              <HiGift height={iconSize} width={iconSize} fontSize={18} />
            </span>
            <span className={css.linkText}>{t('header.rewards')}</span>
          </Link>
        </UserMenuItem>
        <UserMenuItem marginBottom={false}>
          <Link href={ROUTES.RETAILER_NOTIFICATIONS}>
            <span className={css.iconWrapper}>
              <RegularSettingsGear height={iconSize} width={iconSize} />
            </span>
            <span className={css.linkText}>{t('header.settings')}</span>
          </Link>
        </UserMenuItem>
        <UserMenuItem>
          <Link href={ROUTES.RETAILER_UPLOAD}>
            <span className={css.iconWrapper}>
              <RegularUpload height={iconSize} width={iconSize} />
            </span>
            <span>{t('header.br')}</span>
          </Link>
        </UserMenuItem>
        <UserMenuLineSeparator />
        <UserMenuItem>
          <button onClick={handleLogout}>
            <span className={css.iconWrapper}>
              <RegularLogout height={iconSize} width={iconSize} />
            </span>
            <span className={css.linkText}>{t('header.logout')}</span>
          </button>
        </UserMenuItem>
      </div>
    )
  }

  const PopUpWrapper = ({ children }: { children: JSX.Element }) => (
    <PopUp placement="bottom-end" isVisible={isVisible} onVisibleChange={(value) => setIsVisible(value)}>
      <Button
        iconLeft={<RegularPerson height={20} width={20} />}
        style="tertiary"
        size="small"
        className={[css.borderlessButton, css.regularPerson].join(' ')}
      />
      {children}
    </PopUp>
  )

  return isMobile ? (
    <Menu />
  ) : (
    <PopUpWrapper>
      <Menu />
    </PopUpWrapper>
  )
}

export default UserMenu
