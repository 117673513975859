import { SVGProps } from 'react'

const SvgRegularEye = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 -5 14 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.99997 7.38333C7.75552 7.38333 8.39708 7.11933 8.92463 6.59133C9.45263 6.06378 9.71663 5.42222 9.71663 4.66667C9.71663 3.91111 9.45263 3.26956 8.92463 2.742C8.39708 2.214 7.75552 1.95 6.99997 1.95C6.24441 1.95 5.60286 2.214 5.0753 2.742C4.5473 3.26956 4.2833 3.91111 4.2833 4.66667C4.2833 5.42222 4.5473 6.06378 5.0753 6.59133C5.60286 7.11933 6.24441 7.38333 6.99997 7.38333ZM6.99997 6.46667C6.49997 6.46667 6.07508 6.29155 5.7253 5.94133C5.37508 5.59156 5.19997 5.16667 5.19997 4.66667C5.19997 4.16667 5.37508 3.74156 5.7253 3.39133C6.07508 3.04156 6.49997 2.86667 6.99997 2.86667C7.49997 2.86667 7.92508 3.04156 8.2753 3.39133C8.62508 3.74156 8.79997 4.16667 8.79997 4.66667C8.79997 5.16667 8.62508 5.59156 8.2753 5.94133C7.92508 6.29155 7.49997 6.46667 6.99997 6.46667ZM6.99997 9.33333C5.54441 9.33333 4.20819 8.95 2.9913 8.18333C1.77486 7.41667 0.844412 6.37778 0.199967 5.06667C0.177745 5.02222 0.161079 4.96378 0.149967 4.89133C0.138856 4.81933 0.133301 4.74444 0.133301 4.66667C0.133301 4.58889 0.138856 4.51378 0.149967 4.44133C0.161079 4.36933 0.177745 4.31111 0.199967 4.26667C0.844412 2.95556 1.77486 1.91667 2.9913 1.15C4.20819 0.383333 5.54441 0 6.99997 0C8.45552 0 9.79152 0.383333 11.008 1.15C12.2249 1.91667 13.1555 2.95556 13.8 4.26667C13.8222 4.31111 13.8389 4.36933 13.85 4.44133C13.8611 4.51378 13.8666 4.58889 13.8666 4.66667C13.8666 4.74444 13.8611 4.81933 13.85 4.89133C13.8389 4.96378 13.8222 5.02222 13.8 5.06667C13.1555 6.37778 12.2249 7.41667 11.008 8.18333C9.79152 8.95 8.45552 9.33333 6.99997 9.33333ZM6.99997 8.33333C8.25552 8.33333 9.40841 8.00267 10.4586 7.34133C11.5084 6.68044 12.3111 5.78889 12.8666 4.66667C12.3111 3.54444 11.5084 2.65267 10.4586 1.99133C9.40841 1.33044 8.25552 1 6.99997 1C5.74441 1 4.59152 1.33044 3.5413 1.99133C2.49152 2.65267 1.68886 3.54444 1.1333 4.66667C1.68886 5.78889 2.49152 6.68044 3.5413 7.34133C4.59152 8.00267 5.74441 8.33333 6.99997 8.33333Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgRegularEye
