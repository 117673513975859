import { SVGProps } from 'react'

function RegularLockDetailed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={10} height={14} viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 5.275h9.103v8.54H0v-8.54zm7.918 1.186H1.185v6.17h6.733V6.46zM4.552.184a3.424 3.424 0 013.42 3.42V6.46h-6.84V3.604a3.424 3.424 0 013.42-3.42zm2.234 5.091V3.604a2.234 2.234 0 00-4.469 0v1.671h4.469zM4.552 7.54a1.156 1.156 0 110 2.312 1.156 1.156 0 010-2.312zm.592 3.989H3.96V9.264h1.185v2.264z"
        fill="#5D82A4"
      />
    </svg>
  )
}

export default RegularLockDetailed
