import { useRouter } from 'next/router'

import { AutocompleteComponents } from '@algolia/autocomplete-js'
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'
import { AutocompleteQuerySuggestionsHit } from '@algolia/autocomplete-plugin-query-suggestions/dist/esm/types'
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches'
import { RecentSearchesItem } from '@algolia/autocomplete-plugin-recent-searches/dist/esm/types'

import AutocompleteItemAction from 'components/atoms/autocomplete-action'
import AutocompleteItem, { AutocompleteItemProps } from 'components/atoms/autocomplete-item'

import { useTranslate } from 'hooks'
import { useLazyRef } from 'hooks/use-lazy-ref'

import { searchClient } from 'utils/search/search-client'

import { INSTANT_SEARCH_QUERY_SUGGESTIONS } from 'constants/instantSearch'

import { getProductSearchURL } from '../utils'

export type ItemProps = {
  item: RecentSearchesItem | AutocompleteQuerySuggestionsHit
  components: AutocompleteComponents
  attributes: string[]
}

export const renderAutocompleteItem = ({
  item,
  components,
  attributes,
  ...autocompleteItemProps
}: ItemProps & AutocompleteItemProps) => {
  return (
    <AutocompleteItem {...autocompleteItemProps}>
      <components.ReverseHighlight hit={item} attribute={attributes} />
    </AutocompleteItem>
  )
}

const useAutoCompletePlugins = () => {
  const router = useRouter()
  const { langSubPath } = useTranslate('common')

  const getRecentSearchesPlugin = useLazyRef(() =>
    createLocalStorageRecentSearchesPlugin({
      key: 'RECENT_SEARCH', // key store in local storage
      limit: 3, // how many to show
      transformSource({ source, onRemove }) {
        return {
          ...source,
          templates: {
            item({ item, components }) {
              return renderAutocompleteItem({
                langSubPath,
                router,
                item,
                components,
                href: getProductSearchURL(item.label),
                attributes: ['label'],
                iconSrc: '/images/icons/light-clock.svg',
                actions: (
                  <AutocompleteItemAction
                    src="/images/icons/mobile-close.svg"
                    title="Remove this search"
                    onClick={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      onRemove(item.label)
                    }}
                  />
                ),
              })
            },
          },
        }
      },
    })
  )

  const getQuerySuggestionsPlugin = useLazyRef(() =>
    createQuerySuggestionsPlugin({
      searchClient,
      indexName: INSTANT_SEARCH_QUERY_SUGGESTIONS,
      transformSource({ source }) {
        return {
          ...source,
          getItemUrl({ item }) {
            return getProductSearchURL(item.query)
          },
          templates: {
            ...source.templates,
            item({ item, components }) {
              return renderAutocompleteItem({
                langSubPath,
                router,
                item,
                components,
                attributes: ['query'],
                href: getProductSearchURL(item.query),
              })
            },
          },
        }
      },
    })
  )

  const getQuerySuggestionsInCategoryPlugin = useLazyRef(() =>
    createQuerySuggestionsPlugin({
      searchClient,
      indexName: INSTANT_SEARCH_QUERY_SUGGESTIONS,
      transformSource({ source }) {
        return {
          ...source,
          getItemUrl({ item }) {
            return getProductSearchURL(item.query)
          },
          templates: {
            ...source.templates,
            item({ item, components }) {
              return renderAutocompleteItem({
                langSubPath,
                router,
                item,
                components,
                attributes: ['query'],
                href: getProductSearchURL(item.query),
              })
            },
          },
        }
      },
    })
  )

  return [getRecentSearchesPlugin(), getQuerySuggestionsPlugin(), getQuerySuggestionsInCategoryPlugin()]
}

export default useAutoCompletePlugins
