import { SVGProps } from 'react'

const SvgLightReorderAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.938 18.837c.291.292.645.438 1.062.438.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0 0 9 16.275c-.417 0-.77.146-1.062.437a1.447 1.447 0 0 0-.438 1.063c0 .417.146.77.438 1.062ZM7.938 13.062c.291.292.645.438 1.062.438.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062A1.444 1.444 0 0 0 9 10.5c-.417 0-.77.146-1.062.438A1.444 1.444 0 0 0 7.5 12c0 .417.146.77.438 1.062ZM7.938 7.287c.291.292.645.438 1.062.438.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0 0 9 4.725c-.417 0-.77.146-1.062.437A1.447 1.447 0 0 0 7.5 6.225c0 .417.146.77.438 1.062ZM13.938 18.837c.291.292.645.438 1.062.438.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0 0 15 16.275c-.417 0-.77.146-1.062.437a1.447 1.447 0 0 0-.438 1.063c0 .417.146.77.438 1.062ZM13.938 13.062c.291.292.645.438 1.062.438.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062A1.444 1.444 0 0 0 15 10.5c-.417 0-.77.146-1.062.438A1.444 1.444 0 0 0 13.5 12c0 .417.146.77.438 1.062ZM13.938 7.287c.291.292.645.438 1.062.438.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0 0 15 4.725c-.417 0-.77.146-1.062.437a1.447 1.447 0 0 0-.438 1.063c0 .417.146.77.438 1.062Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightReorderAlt
