import ImageComponent from 'components/image'
import Link from 'components/link'
import Logo from 'components/logo'

import { useTranslate } from 'hooks'

import css from './nav-header-modile.module.scss'

type MenuHeaderProps = {
  onClose?: () => void
  onBack?: () => void

  leftComponent?: React.ReactNode
  rightComponent?: React.ReactNode
}

type BackButtonProps = {
  onClick?: () => void
}

export const BackButton = ({ onClick }: BackButtonProps) => {
  const { t } = useTranslate('strapi-component-app-header')
  return (
    <button className={css.backButton} onClick={onClick}>
      <ImageComponent
        alt="Go back icon"
        width={24}
        height={24}
        src="/images/icons/arrow-chevrons/chevron-smooth-left.svg"
      />
      <span className={css.btnTitle}>{t('Navbar.Navbar_back')}</span>
    </button>
  )
}

export const renderMarkatoLogo = () => (
  <Link href={'/'}>
    <Logo width={130} height={35} noEmbed variant="primary" />
  </Link>
)

const MenuHeader = ({ leftComponent, rightComponent, onClose, onBack }: MenuHeaderProps) => {
  return (
    <section className={css.header}>
      {leftComponent ?? <BackButton onClick={onBack} />}
      {rightComponent ?? (
        <ImageComponent
          className={css.closeButton}
          src="/images/icons/mobile-close.svg"
          alt={''}
          width={24}
          height={24}
          onClick={onClose}
        />
      )}
    </section>
  )
}

export default MenuHeader
