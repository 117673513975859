import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { getAccessToken } from 'services/http-client'

export const useIsLoggedIn = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const router = useRouter()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setAccessToken(getAccessToken())
    }
  }, [router.pathname])

  return {
    accessToken,
    isLoggedIn: accessToken ? true : false,
  }
}
