import { useRouter } from 'next/router'
import { useState } from 'react'

import LayoutHalfImageModal from 'layouts/modals/half-image-modal'

import Button from 'components/button'
import Google from 'components/icon/solid/Google'
import Image from 'components/image'
import { ImageWithTextCard } from 'components/image-with-text-card'
import Input from 'components/input'
import Link from 'components/link'
import Logo from 'components/logo'
import Or from 'components/or'
import { PasswordField } from 'components/password-field'
import PasswordValidation from 'components/password-validation'
import Toast from 'components/toast'
import { GOOGLE_URL } from 'features/login/use-login'

import { useTranslate } from 'hooks'

import { EMAIL_REGEX } from 'utils/constants'

import { BG_IMG_CDN_PREFIX, REDIRECT_TO_COOKIE } from 'constants/constants'
import { ROUTES } from 'constants/routes'

import { useRetailerSignupForm } from './hooks/use-retailer-sign-up-form'
import css from './index.module.scss'

type SignUpRetailerModalProps = {
  isOpen: boolean
  onChange: () => void
  onLoginClick: () => void
}

const SignUpRetailerModal = ({ isOpen, onChange, onLoginClick }: SignUpRetailerModalProps) => {
  const router = useRouter()
  const { t } = useTranslate('retailer')
  const { register, errors, onSubmit, isLoading, apiErrors, watch } = useRetailerSignupForm(onChange)
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)

  let errorResources: string[] = []
  if (apiErrors.length > 0) {
    errorResources = apiErrors.map((error) => 'sign_up_error.' + error)
  }

  const renderLeftComponent = () => {
    return (
      <section className={css.leftContainer}>
        <ImageWithTextCard
          headingText={t('signup.start')}
          description={t('signup.unlock_the_power')}
          image={<Image fill src="/images/phoneSignUp.svg" alt="email sign up" />}
        />
      </section>
    )
  }

  const handleApplyToSellClick = () => {
    onChange()
    void router.push(ROUTES.BRAND_REGISTRATION)
  }

  const renderRightComponent = () => {
    return (
      <section className={css.rightSideContainer}>
        <p className={css.signUpText}>{t('signup.signup_to')}</p>
        <div className={css.markatoText}>
          <Logo width={121} height={32.5} />
        </div>
        <form className={css.signupForm}>
          <Input
            data-test-id="retailer-signup-email-input"
            label={t('signup.email')}
            placeholder={t('signup.enter_email')}
            type="email"
            autoComplete="email"
            errorMessage={errors.email?.message}
            {...register('email', {
              required: t('signup.email_required'),
              pattern: {
                value: EMAIL_REGEX,
                message: t('signup.email_invalid'),
              },
              maxLength: {
                value: 50,
                message: t('signup.email_invalid'),
              },
            })}
          />

          <PasswordField
            data-test-id="retailer-signup-password-input"
            label={t('signup.create_password')}
            placeholder={t('signup.min_char')}
            errorMessage={errors.password?.message}
            autoComplete="new-password"
            {...register('password', {
              required: t('signup.password_required'),
            })}
            id="new-password"
          />

          <PasswordValidation
            passwordValue={watch('password')}
            isPasswordValid={isPasswordValid}
            onPasswordValidChange={setIsPasswordValid}
          />

          <Button
            data-test-id="retailer-signup-button"
            label={t('signup.create_account')}
            size="large"
            onClick={onSubmit}
            isLoading={isLoading}
            type="submit"
            disabled={errors.email?.message !== undefined || !isPasswordValid}
          />
        </form>
        <p className={css.haveAccount}>
          {t('signup.have_account')} <Link onClick={onLoginClick}>{t('signup.login')}</Link>
        </p>

        <Or />

        <Button
          className={css.google}
          size="large"
          style="tertiary"
          iconLeft={<Google width="24px" height="24px" />}
          label={t('signup.google_continue')}
          onClick={() => {
            sessionStorage.setItem(REDIRECT_TO_COOKIE, `${window.location.pathname}${window.location.search}`)
            void router.push(GOOGLE_URL)
          }}
        />

        <div className={css.applyToSellContainer}>
          <span className={css.brand}>{t('signup.are_you')}</span>
          <span className={css.applyToSell} onClick={handleApplyToSellClick}>
            {t('signup.sell')}
          </span>
        </div>

        <div className={css.toasts}>
          {apiErrors.length > 0 && <Toast messages={errorResources} data-test-id="signup-error-toast" type="alert" />}
        </div>
      </section>
    )
  }

  return (
    <LayoutHalfImageModal
      isOpen={isOpen}
      onChange={onChange}
      leftComponent={renderLeftComponent()}
      rightComponent={renderRightComponent()}
      classNameRightContainer={css.rightContainer}
      imgBackgroundCss={`url(${BG_IMG_CDN_PREFIX}/images/retailer_signup.jpg)`}
      trackName="signup-retailer"
    />
  )
}

export default SignUpRetailerModal
