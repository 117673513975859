import { SVGProps } from 'react'

const SvgLightShoppingCart = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.15 21.55c-.483 0-.896-.17-1.237-.512A1.687 1.687 0 0 1 5.4 19.8c0-.483.171-.896.513-1.238a1.685 1.685 0 0 1 1.237-.512c.483 0 .896.17 1.237.512.342.342.513.755.513 1.238s-.171.896-.513 1.238a1.685 1.685 0 0 1-1.237.512Zm9.7 0c-.483 0-.896-.17-1.237-.512A1.687 1.687 0 0 1 15.1 19.8c0-.483.171-.896.513-1.238a1.685 1.685 0 0 1 1.237-.512c.483 0 .896.17 1.237.512.342.342.513.755.513 1.238s-.171.896-.513 1.238a1.685 1.685 0 0 1-1.237.512ZM6.025 5.75l2.525 5.3h7l2.775-5.025a.187.187 0 0 0 0-.187.162.162 0 0 0-.15-.088H6.025Zm1.125 10.8c-.667 0-1.167-.287-1.5-.862-.333-.575-.342-1.146-.025-1.713L7.05 11.4 3.4 3.75H2.225A.728.728 0 0 1 1.5 3c0-.217.07-.396.212-.538a.731.731 0 0 1 .538-.212h1.525a.933.933 0 0 1 .825.525l.7 1.475h13.875c.467 0 .796.17.988.512.191.342.187.705-.013 1.088l-3.225 5.8c-.15.283-.358.504-.625.662a1.726 1.726 0 0 1-.9.238H8.1l-1.15 2.125a.244.244 0 0 0-.012.25c.041.083.112.125.212.125h10.725c.2 0 .371.075.513.225.141.15.212.325.212.525a.728.728 0 0 1-.75.75H7.15Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightShoppingCart
