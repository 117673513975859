import { useMutation } from 'react-query'

import httpClient, { getSessionId } from 'services/http-client'

export const DELETE_IMPERSONATE_COOKIE = '/admin/auth/impersonate'

const deleteImpersonateCookie = async () => {
  return await httpClient.delete(DELETE_IMPERSONATE_COOKIE, {
    headers: {
      sessionId: getSessionId(),
    },
  })
}
export const useDeleteImpersonateCookie = (onSuccess: () => Promise<void> | void) =>
  useMutation(deleteImpersonateCookie, { onSuccess })
