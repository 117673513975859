import { encodeURIComp } from 'utils/uriEncode'

import { ROUTES } from 'constants/routes'

export const getProductSearchURL = (query: string) => `${ROUTES.SEARCH}/products?query=${encodeURIComp(query)}`

export const getBrandPageURL = (param: string) => ROUTES.BRAND.replace('[brandKey]', encodeURIComp(param))

export const convertIndexLang = (lang: string) => lang.replace('-hk', '-HK').replace('-sg', '-HK')

export const getCategoryURL = (categories: string[], isXml = false, baseRoute = ROUTES.SEARCH_PRODUCTS) => {
  const convertCategoryToURLCode = (str: string) => {
    return encodeURIComp(
      str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()) // capitalize first letter of each word
    )
  }

  const catLevels = categories.map((cat) => (cat ? convertCategoryToURLCode(cat) : cat))

  return `${baseRoute}?cat=${catLevels.join(isXml ? '%2C' : '%2C')}`
}
