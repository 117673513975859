import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { FORM_MODE } from 'utils/constants'

import { usePostForgotPassword } from './use-post-forgot-password'

export type PostForgotPasswordForm = {
  username: string
}

export const useForgotPasswordForm = (username: string) => {
  const {
    control,
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<PostForgotPasswordForm>({
    mode: FORM_MODE,
  })

  useEffect(() => {
    reset({ username })
  }, [reset, username])

  const { mutateAsync: postForgotPassword, isLoading } = usePostForgotPassword()

  const onSubmit = handleSubmit(async () => {
    return await postForgotPassword({
      username: getValues().username.trim(),
    })
  })

  return {
    control,
    errors,
    getValues,
    isLoading,
    isValid,
    onSubmit,
    register,
    setError,
    username: getValues().username,
  }
}
