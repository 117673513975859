import { useState } from 'react'

import Cookies from 'js-cookie'

import { useTranslate } from 'hooks'

import { MAX_COOKIES_DAY } from 'constants/constants'

import Button from '../../../../components/button/index'
import css from './cookie-banner.module.scss'

const CookieBanner = () => {
  const { t, langSubPath } = useTranslate('common')

  const [isShow, setIsShow] = useState<boolean>(true)

  const removeCookieBanner = () => {
    Cookies.set('firstVisit', 'false', { expires: MAX_COOKIES_DAY })
    setIsShow(false)
  }

  const output = isShow ? (
    <div className={css.container}>
      <div className={css.content}>
        <p
          className={css.cookieText}
          dangerouslySetInnerHTML={{
            __html: t('cookie_banner.text').replace(
              '{{privacy_policy}}',
              `<a target='_blank' href='${langSubPath}/privacy-policy'>${t('privacy_policy')}</a>`
            ),
          }}
        ></p>
        <div className={css.cookieButtons}>
          <Button label={t('cookie_banner.button')} onClick={() => removeCookieBanner()} />
        </div>
      </div>
    </div>
  ) : null

  return output
}

export default CookieBanner
