import { format, isFuture, isSameMonth, isSameYear, parseISO } from 'date-fns'

export const removeSpacings = (str: string): string => str.replace(/ /g, '')

export const capitalizeWords = (str = '') => {
  const words = str.toLocaleLowerCase().split(' ')
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
  }
  return words.join(' ')
}

export const arraysEqual = (array1: unknown[], array2: unknown[]): boolean => {
  const arr1 = array1.map((el) => el).sort()
  const arr2 = array2.map((el) => el).sort()

  if (arr1.length !== arr2.length) {
    return false
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }

  return true
}

export const formatPrice = (value: number, currency: string, minimumFractionDigits = 2) => {
  const currencies: Record<string, string> = {
    $: 'USD',
    '€': 'EUR',
    HK$: 'HKD',
    A$: 'AUD',
    NZ$: 'NZD',
    S$: 'SGD',
  }

  let currencyCode = currency

  if (Object.keys(currencies).includes(currency)) {
    currencyCode = currencies[currency]
  }

  return Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode, minimumFractionDigits }).format(value)
}

export const formatFullPriceWithSpace = (fullPrice: string) => {
  return (
    fullPrice
      // if the price begins with digit, place the space after the digit
      .replace(/^([\d,.]+)/, '$1 ')
      // if the price ends with digit, place the space before the digit
      .replace(/([\d,.]+)$/, ' $1')
  )
}

export const DATE_FORMAT = 'dd-MM-yyyy'
export const DATE_FORMAT_MEDIUM = 'MMM dd, yyyy'
export const DATE_FORMAT_LONG = 'MMMM, dd, yyyy'
export const DATE_AND_TIME_FORMAT = 'dd-MM-yyyy HH:mm'
export const DATE_AND_TIME_FORMAT_LONG = 'MMM dd, yyyy HH:mmaaa'
export const DATE_AND_TIME_WITH_SECONDS_FORMAT = 'dd-MM-yyyy HH:mm:ss'
export const DATE_MONTH_YEAR = 'MMMM yy'

type DateFormatType =
  | typeof DATE_FORMAT
  | typeof DATE_AND_TIME_FORMAT
  | typeof DATE_AND_TIME_WITH_SECONDS_FORMAT
  | typeof DATE_FORMAT_LONG
  | typeof DATE_FORMAT_MEDIUM
  | typeof DATE_AND_TIME_FORMAT_LONG
  | typeof DATE_MONTH_YEAR

export const formatDate = (date: string | Date, dateFormat: DateFormatType = DATE_FORMAT): string => {
  let dateToFormat: Date
  if (typeof date === 'string') {
    dateToFormat = parseISO(date)
  } else {
    dateToFormat = date
  }
  return format(dateToFormat, dateFormat)
}

export const createTextExcerpt = (text: string, limit: number): string => `${text.substring(0, limit).trim()}...`

export const getVideoEmbedUrl = (src: string): string | undefined => {
  let embedUrl: string | undefined

  // YouTube URL pattern
  const youTubePattern = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/
  const youTubeMatch = src.match(youTubePattern)
  if (youTubeMatch) {
    embedUrl = `https://youtube.com/embed/${youTubeMatch[3]}`
  }

  // Vimeo URL pattern
  const vimeoPattern =
    /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com)\/(?:channels\/|groups\/[^/]*\/videos\/|album\/\d+\/video\/|video\/|)(\d+)(?:$|\/|\?)/
  const vimeoMatch = src.match(vimeoPattern)
  if (vimeoMatch) {
    embedUrl = `https://player.vimeo.com/video/${vimeoMatch[1]}`
  }

  return embedUrl
}

export const isValidUUID = (uuid: string) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  return uuidRegex.test(uuid)
}
export const isFutureDate = (date: number | Date): boolean => {
  return isFuture(date)
}

// TO-DO: Move it to markato-share (This is from markato-ui-admin)
// TO-DO: Add unit test
// TO-DO: Find a way to support multi-language say Chinese by pass down {{M}}, {{D}}, {{Y}}
export const formatDateRange = (startDate: Date, endDate: Date) => {
  const fullDate = 'MMMM dd, yyyy'

  let formattedDate = ''
  if (isSameMonth(startDate, endDate)) {
    formattedDate = `${format(startDate, 'MMMM dd')} to ${format(endDate, 'dd, yyyy')}`
  } else {
    if (isSameYear(startDate, endDate)) {
      formattedDate = `${format(startDate, 'MMMM dd')} to ${format(endDate, fullDate)}`
    } else {
      formattedDate = `${format(startDate, fullDate)} to ${format(endDate, fullDate)}`
    }
  }

  return formattedDate
}

type FileType = 'A4' | '4x6' | 'A5'
// Trick to change user received label ft until ES responds.
export const changeLabelFileType = (courierName: string, labelUrl: string, pageSize: FileType = '4x6') => {
  const parsedUrl = new URL(labelUrl)
  if (courierName.includes('NinjaVan')) {
    parsedUrl.searchParams.set('page_size', 'a4')
    return parsedUrl.toString()
  }
  parsedUrl.searchParams.set('page_size', pageSize)
  return parsedUrl.toString()
}
