// Docs: https://developers.cloudflare.com/images/url-format
export default function cloudflareLoader({ src, width, quality }) {
  const scaleUpFactor = 1.5
  const defaultQuailty = 60
  const params = [`width=${width * scaleUpFactor}`, `quality=${quality || defaultQuailty}`, 'format=auto']

  // Non-production related images should not be proxied
  if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'prod' && !src.startsWith('http')) {
    return src
  }

  return `https://www.markato.com/cdn-cgi/image/${params.join(',')}/${src}`
}
