import { ToastBar, Toaster, toast } from 'react-hot-toast'

import SvgRegularClose from 'components/icon/regular/RegularClose'
import SvgSolidCheckCircle from 'components/icon/solid/SolidCheckCircle'
import SvgSolidError from 'components/icon/solid/SolidError'
import SvgSolidInfo from 'components/icon/solid/SolidInfo'

import css from './notification-toast.module.scss'

const FOUR_SECOND_TOAST_DURATION = 4 * 1000
export const EIGHT_SECOND_TOAST_DURATION = 8 * 1000

export const successToast = (message: string, duration?: number) => {
  return toast.success(message, {
    icon: <SvgSolidCheckCircle />,
    className: css.success,
    duration,
  })
}
export const errorToast = (message: string, duration?: number) => {
  return toast.error(message, {
    icon: <SvgSolidError className={css.svgError} />,
    className: css.error,
    duration,
  })
}
export const warningToast = (message: string, duration: number = FOUR_SECOND_TOAST_DURATION) => {
  return toast(message, {
    icon: <SvgSolidInfo className={css.svgWarning} />,
    className: css.warning,
    duration,
  })
}

export const NotificationToast = () => {
  return (
    <Toaster containerClassName={css.container}>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <button onClick={() => toast.dismiss(t.id)} className={css.dismissToast}>
                  <SvgRegularClose fontSize={16} color={t.type === 'success' ? 'white' : 'black'} />
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}
