import { SVGProps } from 'react'

const SvgSolidYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.5997 2.49898C20.0446 4.28098 20.0446 8.00098 20.0446 8.00098C20.0446 8.00098 20.0446 11.721 19.5997 13.503C19.3525 14.488 18.6291 15.263 17.7131 15.525C16.0493 16.001 10.3094 16.001 10.3094 16.001C10.3094 16.001 4.57247 16.001 2.9058 15.525C1.98582 15.259 1.26347 14.485 1.01912 13.503C0.574219 11.721 0.574219 8.00098 0.574219 8.00098C0.574219 8.00098 0.574219 4.28098 1.01912 2.49898C1.26639 1.51398 1.98972 0.738976 2.9058 0.476976C4.57247 0.000976384 10.3094 0.000976562 10.3094 0.000976562C10.3094 0.000976562 16.0493 0.000976384 17.7131 0.476976C18.633 0.742976 19.3554 1.51698 19.5997 2.49898ZM8.36239 11.501L14.2035 8.00098L8.36239 4.50098V11.501Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSolidYoutube
