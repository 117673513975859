import { useMutation } from 'react-query'

import { ErrorResponse } from 'services/error-response'
import httpClient from 'services/http-client'

export const CART_BRANDS = '/cart/brands'

type DeleteItemProps = { brandKey: string; variantKey: string }

const deleteItemFromCart = async ({ brandKey, variantKey }: DeleteItemProps) => {
  return await httpClient.put(`${CART_BRANDS}/${brandKey}/items/remove`, { variantKey: variantKey })
}
export const useDeleteItemFromCart = (onError: (errors: ErrorResponse) => void, onSuccess: () => void) =>
  useMutation(deleteItemFromCart, { onError, onSuccess })
