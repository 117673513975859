import { ReactNode } from 'react'

import css from './index.module.scss'

export type ImageWithTextCardProps = {
  headingText: string
  description: string
  image: ReactNode
}
export const ImageWithTextCard = ({ headingText, description, image }: ImageWithTextCardProps) => {
  return (
    <div className={css.leftSideContainer}>
      <div className={css.sideImage}>{image}</div>
      <div className={css.sideText}>
        <p className={css.sideTextHeading}>{headingText}</p>
        <p className={css.sideTextDescription}>{description}</p>
      </div>
    </div>
  )
}
