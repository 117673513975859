import { useMutation } from 'react-query'

import httpClient from '../../../services/http-client'

export const POST_FORGOT_PASSWORD = '/retailer/auth/forgot-password'

type ForgotPasswordRequest = {
  username: string
}

const postForgotPassword = async (form: ForgotPasswordRequest) => {
  return await httpClient.post(`${POST_FORGOT_PASSWORD}`, form)
}

export const usePostForgotPassword = () => useMutation(postForgotPassword)
