import { SVGProps } from 'react'

const SvgGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.6001 10.2275C19.6001 9.51837 19.5365 8.83655 19.4183 8.18201H10.0001V12.0502H15.3819C15.1501 13.3002 14.4456 14.3593 13.3865 15.0684V17.5775H16.6183C18.5092 15.8366 19.6001 13.2729 19.6001 10.2275Z"
      fill="#4285F4"
    />
    <path
      d="M9.99996 20C12.7 20 14.9636 19.1046 16.6181 17.5773L13.3863 15.0682C12.4909 15.6682 11.3454 16.0228 9.99996 16.0228C7.39542 16.0228 5.19087 14.2637 4.40451 11.9H1.0636V14.4909C2.70905 17.7591 6.09087 20 9.99996 20Z"
      fill="#34A853"
    />
    <path
      d="M4.40455 11.9002C4.20455 11.3002 4.09091 10.6593 4.09091 10.0002C4.09091 9.3411 4.20455 8.70019 4.40455 8.10019V5.50928H1.06364C0.386364 6.85928 0 8.38655 0 10.0002C0 11.6138 0.386364 13.1411 1.06364 14.4911L4.40455 11.9002Z"
      fill="#FBBC05"
    />
    <path
      d="M9.99996 3.9774C11.4681 3.9774 12.7863 4.48194 13.8227 5.47285L16.6909 2.60467C14.9591 0.991031 12.6954 0.00012207 9.99996 0.00012207C6.09087 0.00012207 2.70905 2.24103 1.0636 5.50921L4.40451 8.10012C5.19087 5.73649 7.39542 3.9774 9.99996 3.9774Z"
      fill="#EA4335"
    />
  </svg>
)
export default SvgGoogle
