import { QueryClient } from 'react-query'

import { DEFAULT_CACHE_TIME, DEFAULT_STALE_TIME } from 'constants/apis'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: DEFAULT_CACHE_TIME,
      staleTime: DEFAULT_STALE_TIME,
      refetchOnWindowFocus: false,
    },
  },
})
