import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import useGlobalStore from 'stores'

import { useRetailerLogin, useRetailerLoginSuccess } from 'features/login/use-login'

import { ErrorResponse } from 'services/error-response'
import { fireTrackingEvent } from 'utils/tracking'

import { REDIRECT_TO_COOKIE } from 'constants/constants'
import { ROUTES } from 'constants/routes'

import { useRegisterRetailer } from './use-retailer-sign-up'

export type RetailerRegistrationFields = {
  email: string
  password: string
}

export const useRetailerSignupForm = (closeModal: () => void) => {
  const [apiErrors, setApiErrors] = useState<string[]>([])
  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    getValues,
    watch,
  } = useForm<RetailerRegistrationFields>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  })
  const router = useRouter()
  const signupRetailerStore = useGlobalStore((state) => state.signupRetailer)

  const onSuccess = async () => {
    const email = getValues().email
    if (!email) return

    signupRetailerStore.setCredential(getValues().email, getValues().password)
    signupRetailerStore.setHasCompleteSignUpCreation(true)

    sessionStorage.setItem(REDIRECT_TO_COOKIE, `${window.location.pathname}${window.location.search}`)

    await router.push(`${ROUTES.RETAILER_VERIFICATION}/email/${email}`)
    closeModal()
    fireTrackingEvent('success', {
      name: `success_signup_creation_retailer`,
    })
  }

  const { mutateAsync: retailerLogin } = useRetailerLogin()
  const { onRetailerSuccess } = useRetailerLoginSuccess()

  const onError = useCallback(
    (errors: ErrorResponse) => {
      const codes = errors.response.data.code
      const showErrors = () => {
        setApiErrors(codes)
        fireTrackingEvent('failure', {
          name: `failure_signup_creation_retailer`,
          errorCode: codes.join(','),
          nativeEvent: errors,
        })
      }

      if (codes.includes('AUTH_002')) {
        const data = JSON.parse(errors.response.config?.data ?? '') as Record<string, unknown>
        const username = data.username as string
        const password = data.password as string
        const onChange = () => closeModal()
        void retailerLogin(
          {
            username,
            password,
          },
          {
            onSuccess: (data) => onRetailerSuccess({ ...data, onChange }),
            onError: showErrors,
          }
        )
      } else {
        showErrors()
      }
    },
    [closeModal, onRetailerSuccess, retailerLogin]
  )

  const { mutateAsync: registerRetailer, isLoading } = useRegisterRetailer(onSuccess, onError)

  const isFormValid = useMemo(() => {
    return !isDirty || !isValid
  }, [isDirty, isValid])

  const onSubmit = handleSubmit((form: RetailerRegistrationFields) => {
    setApiErrors([])
    return registerRetailer(form)
  })

  return {
    control,
    register,
    errors,
    onSubmit,
    isFormValid,
    isLoading,
    apiErrors,
    watch,
  }
}
