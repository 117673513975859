import { AppHeader, HeaderProps } from './app-header'
import css from './header.module.scss'
import { Navbar } from './navbar'

export const Header = ({
  displayTopBanner,
  onLoginClick,
  onSignUpClick,
  accessToken,
  showNavbar = true,
}: HeaderProps) => {
  return (
    <div className={[css.container, displayTopBanner ? css.displayTopBanner : ''].join(' ')}>
      <AppHeader
        displayTopBanner={displayTopBanner}
        onLoginClick={onLoginClick}
        onSignUpClick={onSignUpClick}
        accessToken={accessToken}
      />
      {!!showNavbar && (
        <div className={css.navbar}>
          <Navbar />
        </div>
      )}
    </div>
  )
}
