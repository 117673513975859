import { SVGProps } from 'react'

const SvgLightThumbUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.00033 31.1665V11.1665L17.7087 1.54155C18.042 1.20822 18.4242 1.01377 18.8553 0.958216C19.2853 0.90266 19.7087 0.972105 20.1253 1.16655C20.5142 1.38877 20.8059 1.69433 21.0003 2.08322C21.1948 2.4721 21.2364 2.88877 21.1253 3.33322L19.3337 11.1665H30.5003C31.2781 11.1665 31.9725 11.4721 32.5837 12.0832C33.1948 12.6943 33.5003 13.3888 33.5003 14.1665V16.8749C33.5003 17.0415 33.4792 17.2288 33.437 17.4365C33.3959 17.6454 33.3475 17.8332 33.292 17.9999L28.5003 29.2499C28.2781 29.8054 27.8825 30.2638 27.3137 30.6249C26.7437 30.986 26.1531 31.1665 25.542 31.1665H8.00033ZM10.5003 12.2499V28.6665H25.542C25.6531 28.6665 25.7714 28.6321 25.897 28.5632C26.0214 28.4932 26.1253 28.3888 26.2087 28.2499L31.0003 16.9999V14.1665C31.0003 14.0277 30.952 13.9093 30.8553 13.8115C30.7575 13.7149 30.6392 13.6665 30.5003 13.6665H16.167L18.2503 4.54155L10.5003 12.2499ZM3.16699 31.1665C2.33366 31.1665 1.62533 30.8749 1.04199 30.2915C0.458659 29.7082 0.166992 28.9999 0.166992 28.1665V14.1665C0.166992 13.3332 0.458659 12.6249 1.04199 12.0415C1.62533 11.4582 2.33366 11.1665 3.16699 11.1665H8.00033V13.6665H3.16699C3.0281 13.6665 2.91033 13.7149 2.81366 13.8115C2.71588 13.9093 2.66699 14.0277 2.66699 14.1665V28.1665C2.66699 28.3054 2.71588 28.4238 2.81366 28.5215C2.91033 28.6182 3.0281 28.6665 3.16699 28.6665H8.00033V31.1665H3.16699Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightThumbUp
