import { useMutation } from 'react-query'

import httpClient from '../../../services/http-client'

export const POST_CONFIRM_FORGOT_PASSWORD = '/retailer/auth/confirm-forgot-password'

type ForgotPasswordRequest = {
  username: string
  confirmationCode: string
  newPassword: string
  confirmPassword: string
}

type ErrorResponse = {
  statusCode: number
  code: string[]
}

export type ErrorMessages = {
  response: {
    data: ErrorResponse
  }
}

const postConfirmForgotPassword = async (form: ForgotPasswordRequest) => {
  return await httpClient.post(`${POST_CONFIRM_FORGOT_PASSWORD}`, form)
}

export const usePostConfirmForgotPassword = (onSuccess: () => void, onError: (errors: string[]) => void) =>
  useMutation(postConfirmForgotPassword, {
    onError: (error: ErrorMessages) => {
      onError(error.response.data.code)
    },
    onSuccess,
  })
