import { SVGProps } from 'react'

const SvgLightHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 17.625c.283 0 .521-.1.713-.3.191-.2.287-.442.287-.725a1.01 1.01 0 0 0-.287-.725.949.949 0 0 0-.713-.3c-.3 0-.546.1-.737.3-.192.2-.288.442-.288.725s.096.525.288.725c.191.2.437.3.737.3Zm.075-9.9c.517 0 .929.137 1.237.412.309.275.463.646.463 1.113 0 .3-.088.596-.263.887-.175.292-.437.596-.787.913-.483.417-.833.812-1.05 1.187a2.512 2.512 0 0 0-.35 1.163.506.506 0 0 0 .175.437.67.67 0 0 0 .475.188.716.716 0 0 0 .488-.188.788.788 0 0 0 .262-.462c.05-.283.158-.546.325-.788.167-.241.433-.545.8-.912.517-.483.875-.917 1.075-1.3.2-.383.3-.8.3-1.25 0-.8-.292-1.454-.875-1.963-.583-.508-1.333-.762-2.25-.762-.617 0-1.183.129-1.7.387A2.891 2.891 0 0 0 9.175 7.95a.794.794 0 0 0-.087.488c.025.158.12.287.287.387.15.1.33.133.538.1a.766.766 0 0 0 .512-.325c.2-.267.43-.48.688-.638.258-.158.579-.237.962-.237ZM12 21.5c-1.317 0-2.55-.25-3.7-.75a9.782 9.782 0 0 1-3.013-2.025 9.478 9.478 0 0 1-2.037-3.013A9.263 9.263 0 0 1 2.5 12c0-1.317.25-2.554.75-3.713a9.482 9.482 0 0 1 2.037-3.012A9.782 9.782 0 0 1 8.3 3.25c1.15-.5 2.383-.75 3.7-.75 1.333 0 2.575.25 3.725.75a9.782 9.782 0 0 1 3.013 2.025 9.32 9.32 0 0 1 2.025 3.012A9.412 9.412 0 0 1 21.5 12a9.405 9.405 0 0 1-.737 3.712 9.318 9.318 0 0 1-2.025 3.013 9.782 9.782 0 0 1-3.013 2.025c-1.15.5-2.392.75-3.725.75Zm0-1.5c2.233 0 4.125-.779 5.675-2.337C19.225 16.104 20 14.217 20 12s-.775-4.104-2.325-5.663C16.125 4.779 14.233 4 12 4c-2.2 0-4.083.779-5.65 2.337C4.783 7.896 4 9.783 4 12s.783 4.104 2.35 5.663C7.917 19.221 9.8 20 12 20Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightHelp
