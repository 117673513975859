import { createBrowserLocalStorageCache } from '@algolia/cache-browser-local-storage'
import { createFallbackableCache } from '@algolia/cache-common'
import { createInMemoryCache } from '@algolia/cache-in-memory'
import algoliasearch from 'algoliasearch'

export const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? '',
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ?? '',
  {
    responsesCache: createFallbackableCache({
      caches: [
        createBrowserLocalStorageCache({
          key: `algolia-responses-${process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? ''}-${1}`,
          timeToLive: 30,
        }),
        createInMemoryCache(),
      ],
    }),
  }
)
