import { useRouter } from 'next/router'
import React, { createContext, useCallback, useState } from 'react'

export const filterLastNBreadcrumbs = (crumbs: Crumbs[], maxCrumbs: number) =>
  crumbs.slice(Math.max(crumbs.length - maxCrumbs, 0), crumbs.length)

const useBreadcrumbs = (breadcrumbsList: Crumbs[] = []) => {
  const router = useRouter()
  const [breadcrumbs, setBreadcrumbs] = useState<Crumbs[]>(breadcrumbsList)

  const addBreadcrumb = useCallback(
    (title: string) => {
      let href: string = router.asPath
      const dynamicRouteRegexp = new RegExp(/\[(.+?)\]/)
      if (href.match(dynamicRouteRegexp)) {
        href = window.location.pathname.split('/').pop() ?? ''
      }

      const crumbs = [
        ...breadcrumbs,
        {
          text: title,
          href,
        },
      ]
      setBreadcrumbs(filterLastNBreadcrumbs(crumbs, 8))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [breadcrumbs, router.asPath, router.pathname]
  )

  return {
    breadcrumbs,
    setBreadcrumbs,
    addBreadcrumb,
  }
}

export type Crumbs = {
  text: string
  href: string
}

type BreadcrumbsContextProps = {
  breadcrumbs: Crumbs[]
  setBreadcrumbs: React.Dispatch<React.SetStateAction<Crumbs[]>>
  addBreadcrumb: (crumb: string) => void
}

const BreadcrumbsContext = createContext<BreadcrumbsContextProps>({
  breadcrumbs: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setBreadcrumbs: () => {},
  // eslint-disable-next-line
  addBreadcrumb: (crumb: string) => {},
})

type BreadcrumbsContextProviderProps = {
  children: React.ReactNode
}

const BreadcrumbsContextProvider = ({ children }: BreadcrumbsContextProviderProps) => {
  const { breadcrumbs, setBreadcrumbs, addBreadcrumb } = useBreadcrumbs()

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs,
        setBreadcrumbs,
        addBreadcrumb,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export { BreadcrumbsContext, BreadcrumbsContextProvider, useBreadcrumbs }
