import { useState } from 'react'

import { NavMenuItem } from 'interfaces/navMenu'

import { useGetNavMenu } from 'features/layout/header/navbar/use-get-nav-menu'

import { NavMenuItemComponent } from './nav-menu-item'
import css from './navbar.module.scss'

export const Navbar = () => {
  const { data: navMenuItemList } = useGetNavMenu()
  const [isNavbarHovering, setIsNavbarHovering] = useState(false)

  return (
    <div
      className={css.container}
      onMouseOver={() => setIsNavbarHovering(true)}
      onMouseOut={() => setIsNavbarHovering(false)}
    >
      {(navMenuItemList ?? []).map((navMenuItem: NavMenuItem) => (
        <NavMenuItemComponent key={navMenuItem.name} navMenuItem={navMenuItem} isNavbarHovering={isNavbarHovering} />
      ))}
    </div>
  )
}
