import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useState } from 'react'

import Button from 'components/button'
import { PasswordField } from 'components/password-field'
import PasswordValidation from 'components/password-validation'
import Toast from 'components/toast'

import { useDynamoDbUserContext } from 'contexts/DynamoDbUserContext'
import { useTranslate } from 'hooks/translation/use-translation'

import { setAccessToken } from 'services/http-client'

import { REDIRECT_TO_COOKIE } from 'constants/constants'
import { ROUTES } from 'constants/routes'

import css from '../../forgot-password/forgot-password.module.scss'
import { TempPasswordResponse } from '../hooks/use-post-temp-password'
import { useTempPassword } from '../hooks/use-temp-password'

type CreateNewPasswordProps = {
  userType: string
  username: string
  session: string
  step: number
  setStep: Dispatch<SetStateAction<number>>
  onNewPasswordIsSet: () => void
}

export const CreateNewPassword = ({
  session,
  userType,
  username,
  step,
  setStep,
  onNewPasswordIsSet: onNewPasswordIsSet,
}: CreateNewPasswordProps) => {
  const { t } = useTranslate('registration')

  const [errorM, setErrorM] = useState<string[]>([])
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)
  const { getUserFromDynamo } = useDynamoDbUserContext()
  const router = useRouter()

  const onSuccess = (response: TempPasswordResponse) => {
    if (userType.toLocaleLowerCase() === 'brand') {
      // Callback: Close the modal
      onNewPasswordIsSet()

      // Handle access token
      const { accessToken, expiresIn } = response
      setAccessToken(accessToken, expiresIn)
      getUserFromDynamo()

      // Redirect
      sessionStorage.removeItem('NEW_PASSWORD_REQUIRED_SESSION')
      sessionStorage.removeItem(REDIRECT_TO_COOKIE)
      void router.push(ROUTES.PORTAL)
    }
  }
  const onError = (errorMessages: string[]) => {
    setErrorM(errorMessages)
  }

  const { register, onSubmit, watch, errors, isLoading } = useTempPassword({
    session,
    userType,
    username,
    onSuccess,
    onError,
  })

  const getMessages = (errorMessages: string[]) => {
    return errorMessages.map((message) => {
      return t(message)
    })
  }

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    void onSubmit()
  }

  const passwordValues = watch('newPassword')
  const confirmPasswordValue = watch('confirmPassword')

  return (
    <form onSubmit={handleSubmit} style={{ position: 'relative', height: '100% ' }}>
      <div data-test-id="enter-new-password-div">
        <p className={css.titleStyle}>{t('Create New Password')}</p>
        <PasswordField
          data-test-id="new-password-input"
          placeholder={t('Create password')}
          label={t('new_password')}
          errorMessage={errors.newPassword?.message?.toString()}
          {...register('newPassword', {
            required: t('password_required'),
            pattern: {
              // eslint-disable-next-line no-useless-escape
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/,
              message: t('wrong_password_format'),
            },
          })}
        />

        <PasswordValidation
          passwordValue={passwordValues}
          isPasswordValid={isPasswordValid}
          onPasswordValidChange={setIsPasswordValid}
        />

        <PasswordField
          data-test-id="repeat-new-password-input"
          placeholder={t('Repeat password')}
          label={t('confirm_new_password')}
          {...register('confirmPassword')}
        />

        <div className={css.errorMsgs}>
          {errorM.length > 0 && <Toast type="alert" messages={getMessages(errorM)} />}
        </div>

        <Button
          data-test-id="set-new-password-button"
          type="submit"
          style="secondary"
          size="large"
          label={t('Set New Password')}
          className={css.buttonWrapperStyle}
          disabled={!isPasswordValid || !confirmPasswordValue}
          isLoading={isLoading}
        />
      </div>
    </form>
  )
}
