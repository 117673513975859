import { useRouter } from 'next/router'
import React, { Fragment, createElement, useEffect, useMemo, useRef } from 'react'
import { Root, createRoot } from 'react-dom/client'

import { autocomplete, getAlgoliaFacets } from '@algolia/autocomplete-js'
import type { AutocompleteOptions } from '@algolia/autocomplete-js'

import AutocompleteSourceItem from 'components/atoms/autocomplete-source-item'
import ImageComponent from 'components/image'
import { getBrandPageURL, getCategoryURL, getProductSearchURL } from 'components/molecules/autocomplete/utils'

import { useTranslate } from 'hooks'
import { Brand, useBrands } from 'hooks/common/use-brands'

import { getMapFromArrayByKey } from 'utils/data-structure'
import { getInstantSearchHierarchicalAttributesLvs } from 'utils/search/attributes'
import { searchClient } from 'utils/search/search-client'
import { decodeDiscoverQuery, isDiscoverPage } from 'utils/search/search-url-mapping'

import { INSTANT_SEARCH_ATTRIBUTES, INSTANT_SEARCH_INDEX, classNamesAutoComplete } from 'constants/instantSearch'

import css from './autocomplete.module.scss'
import useAutoCompletePlugins from './hooks/use-autocomplete-plugins'

type SourceHeaderProps = {
  title: string
}

const SourceHeader = ({ title }: SourceHeaderProps) => {
  return <p className={css.sourceHeader}>{title}</p>
}

export function Autocomplete<TItem extends Record<string, unknown>>(
  props: Partial<AutocompleteOptions<TItem>> & Pick<React.ComponentProps<'div'>, 'className'>
) {
  const { t, langSubPath } = useTranslate('strapi-component-app-header')
  const router = useRouter()
  const plugins = useAutoCompletePlugins()
  const inputWrapperRef = useRef<HTMLDivElement | null>(null)
  const panelRootRef = useRef<Root | null>(null)
  const panelWrapperRef = useRef<HTMLDivElement | null>(null)
  const rootRef = useRef<HTMLElement | null>(null)

  const { data: brands } = useBrands()

  const brandsByName = useMemo(() => getMapFromArrayByKey<Brand>(brands ?? [], 'storeName'), [brands])

  useEffect(() => {
    if (!inputWrapperRef.current || !panelWrapperRef.current) {
      return undefined
    }

    const search = autocomplete({
      insights: true,
      container: inputWrapperRef.current,
      panelContainer: panelWrapperRef.current,
      renderer: { createElement, Fragment, render: () => null },
      initialState: {
        query: isDiscoverPage()
          ? decodeDiscoverQuery(router.query.query as string) || ''
          : (router.query.query as string) || '',
      },
      navigator: {
        async navigate({ itemUrl }) {
          await router.push(itemUrl)
        },
      },
      onSubmit: ({ state }) => {
        void router.push(getProductSearchURL(state.query))
      },
      plugins,
      getSources({ query }) {
        if (!query) {
          return []
        }

        return [
          {
            sourceId: 'brands',
            getItems({ query }) {
              return getAlgoliaFacets({
                searchClient,
                queries: [
                  {
                    indexName: INSTANT_SEARCH_INDEX['variants-from-connector'].name,
                    facet: INSTANT_SEARCH_ATTRIBUTES.BRAND_NAMES.attr,
                    params: {
                      facetQuery: query,
                      maxFacetHits: 3,
                      minWordSizefor1Typo: 8,
                      minWordSizefor2Typos: 10,
                    },
                  },
                ],
              })
            },
            templates: {
              header() {
                return <SourceHeader title={t('Autocomplete.Autocomplete_search_for_brands')} />
              },
              item({ item }) {
                const label = item.label as string
                const brandInfo = brandsByName[label] as Brand | undefined
                return (
                  <AutocompleteSourceItem
                    langSubPath={langSubPath}
                    href={brandInfo ? getBrandPageURL(brandInfo.storeKey) : getProductSearchURL(label)}
                    name={label}
                    data-test-id="autocomplete-source-item-brand"
                    imageComponent={
                      brandInfo?.brandPage?.logoRef ? (
                        <ImageComponent width={50} height={50} src={brandInfo.brandPage.logoRef} alt={label} />
                      ) : (
                        <span className={css.brandIconHolder} />
                      )
                    }
                  />
                )
              },
            },
          },
          {
            sourceId: 'productsCategories',
            getItems({ query }) {
              return getAlgoliaFacets({
                searchClient,
                queries: [
                  {
                    indexName: INSTANT_SEARCH_INDEX['variants-from-connector'].name,
                    facet: getInstantSearchHierarchicalAttributesLvs()[2],
                    params: {
                      facetQuery: query,
                      maxFacetHits: 2,
                    },
                  },
                ],
              })
            },
            templates: {
              header() {
                return <SourceHeader title={t('Autocomplete.Autocomplete_product_categories')} />
              },
              item({ item }) {
                const label = item.label as string
                const categories = label.toLowerCase().split(' > ')

                return (
                  <AutocompleteSourceItem
                    langSubPath={langSubPath}
                    href={getCategoryURL(categories)}
                    name={label}
                    data-test-id="autocomplete-source-item-category"
                    imageComponent={
                      <ImageComponent
                        width={16}
                        height={16}
                        src={'/images/icons/light-search.svg'}
                        alt={label}
                        className={css.image}
                      />
                    }
                  />
                )
              },
            },
          },
        ]
      },
      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root
          panelRootRef.current?.unmount()
          panelRootRef.current = createRoot(root)
        }
        panelRootRef.current.render(children)
      },
      classNames: classNamesAutoComplete,
      openOnFocus: true,
      placeholder: t('Autocomplete.Autocomplete_search_for'),
      detachedMediaQuery: '(max-width: 100px)',
      ...props,
    })
    return () => {
      search.destroy()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, plugins, langSubPath, brandsByName])

  return (
    <div className={css.container}>
      <div ref={inputWrapperRef} className={css.inputWrapper} />
      <div ref={panelWrapperRef} className={css.panelWrapper} />
    </div>
  )
}
