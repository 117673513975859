import { ChangeEvent, useState } from 'react'

import Button from 'components/button'
import Input from 'components/input'

import css from './newsletter-input.module.scss'

type NewsletterInputProps = {
  placeholder: string
  onSubmit: (email: string) => void
}

const NewsletterInput = ({ placeholder, onSubmit }: NewsletterInputProps) => {
  const [email, setEmail] = useState('')
  const onChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    setEmail(changeEvent.target.value || '')
  }

  const validateEmail = (email: string) => {
    return (
      email.match(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) === null
    )
  }

  return (
    <div className={css.container}>
      <Input
        className={css.underLineInput}
        onChange={onChange}
        type="email"
        autoComplete="email"
        value={email}
        placeholder={placeholder}
        name="newsletter"
      />
      <Button
        className={css.roundedBtn}
        size="medium"
        style="tertiary"
        onClick={() => onSubmit(email)}
        disabled={validateEmail(email)}
        aria-label="Submit"
      />
    </div>
  )
}

export default NewsletterInput
