import { useEffect, useState } from 'react'

import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

import Button from 'components/button'
import { SvgRegularEye } from 'components/icon/regular'
import { SolidClose } from 'components/icon/solid'

import { useDeleteImpersonateCookie } from 'hooks/common/use-delete-impersonate-cookie'

import { ADMIN_DEFAULT_DOMAIN, COGNITO_GROUP, deleteAccessToken, getCookie } from 'services/http-client'

import css from './impersonate.module.scss'

export const Impersonate = () => {
  const onSuccess = () => {
    deleteAccessToken()

    const adminDomain = process.env.NEXT_PUBLIC_ADMIN_URL ?? ADMIN_DEFAULT_DOMAIN
    setIsImpersonateCookie(false)
    window.location.replace(`${adminDomain}/user-management`)
  }
  const { mutateAsync: deleteImpersonateCookie, isLoading } = useDeleteImpersonateCookie(onSuccess)

  const [isImpersonateCookie, setIsImpersonateCookie] = useState(false)

  const handleClose = async () => {
    await deleteImpersonateCookie()
  }

  useEffect(() => {
    const cookieImpersonate = getCookie('impersonated-token')

    if (cookieImpersonate) {
      const { profile }: { profile: string } = jwtDecode(cookieImpersonate)
      Cookies.set(COGNITO_GROUP, profile)
    }

    setIsImpersonateCookie(cookieImpersonate && cookieImpersonate !== '' ? true : false)
  }, [])

  if (!isImpersonateCookie) {
    return null
  }
  return (
    <div className={css.container} style={{ backgroundColor: '#DE5962' }}>
      <SvgRegularEye width="24px" height="24px" color="white" />
      <p className={css.text}>Impersonation Mode</p>
      <Button
        style="ghost"
        className={css.button}
        label="Stop Presenting"
        color="red"
        isLoading={isLoading}
        onClick={handleClose}
        iconLeft={<SolidClose width="18px" height="18px" />}
      />
    </div>
  )
}
