import cn from 'classnames'

import Modal from 'components/modal'

import css from './index.module.scss'

type HalfImageModalProps = {
  isOpen: boolean
  onChange: () => void
  imgBackgroundCss: string
  leftComponent?: React.ReactNode
  rightComponent?: React.ReactNode
  classNameRightContainer?: string
  trackName?: string
}

const LayoutHalfImageModal = ({
  isOpen,
  onChange,
  leftComponent = null,
  rightComponent = null,
  classNameRightContainer = '',
  imgBackgroundCss = '',
  trackName = '',
}: HalfImageModalProps) => {
  return (
    <Modal isOpen={isOpen} onChange={onChange} trackName={trackName}>
      <div className={css.container}>
        <div
          className={css.image}
          style={{
            background: imgBackgroundCss,
          }}
        >
          {leftComponent}
        </div>
        <div className={cn([css.formContainer, classNameRightContainer])}>{rightComponent}</div>
      </div>
    </Modal>
  )
}

export default LayoutHalfImageModal
