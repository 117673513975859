import { create } from 'zustand'

export type GlobalState = {
  signupRetailer: {
    username: string
    password: string
    hasCompleteSignUpCreation: boolean
    setCredential: (username: string, password: string) => void
    setHasCompleteSignUpCreation: (hasCompleteSignUpCreation: boolean) => void
  }
}

const useGlobalStore = create<GlobalState>((set) => ({
  signupRetailer: {
    username: '',
    password: '',
    hasCompleteSignUpCreation: false,
    setCredential: (username: string, password: string) =>
      set((state) => ({ signupRetailer: { ...state.signupRetailer, username, password } })),
    setHasCompleteSignUpCreation: (hasCompleteSignUpCreation: boolean) =>
      set((state) => ({ signupRetailer: { ...state.signupRetailer, hasCompleteSignUpCreation } })),
  },
}))

export default useGlobalStore
