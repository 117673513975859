import { useTranslate } from 'hooks'

import styles from './or.module.scss'

const Or = () => {
  const { t } = useTranslate('common')
  return (
    <div className={styles.container}>
      <div className={styles.dash}></div>
      <span>{t('or')}</span>
      <div className={styles.dash}></div>
    </div>
  )
}

export default Or
