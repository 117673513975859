import { SVGProps } from 'react'

const SvgLightArrowChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 15.65c-.117 0-.23-.016-.337-.05a.604.604 0 0 1-.288-.2L6.85 10.9a.766.766 0 0 1-.2-.525c0-.2.075-.375.225-.525.15-.133.325-.2.525-.2s.375.067.525.2L12 13.925l4.1-4.075c.133-.15.3-.22.5-.213.2.009.375.08.525.213.15.15.225.33.225.537 0 .209-.075.38-.225.513l-4.5 4.5a.605.605 0 0 1-.287.2c-.109.034-.221.05-.338.05Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightArrowChevronDown
