import { useRouter } from 'next/router'
import { useEffect } from 'react'

import LayoutHalfImageModal from 'layouts/modals/half-image-modal'

import Button from 'components/button'
import Link from 'components/link'
import Logo from 'components/logo'
import { useModal } from 'components/modal/use-modal'

import { useTranslate } from 'hooks'

import { BG_IMG_CDN_PREFIX, STORAGE_KEY } from 'constants/constants'
import { ROUTES } from 'constants/routes'

import css from './index.module.scss'

const WelcomeNewRetailerModal = () => {
  const { t } = useTranslate('retailer')
  const { isOpen, open, close, onChange } = useModal()
  const router = useRouter()

  useEffect(() => {
    sessionStorage.getItem(STORAGE_KEY.RETAILER_FIRST_TIME_LOGIN) === 'true' && open()
    sessionStorage.removeItem(STORAGE_KEY.RETAILER_FIRST_TIME_LOGIN)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname])

  const renderLeftComponent = () => {
    return (
      <section className={css.leftContainer}>
        <Logo variant="white" width={182} height={49} />
      </section>
    )
  }

  const renderRightComponent = () => {
    return (
      <section>
        <h1 className={css.title}>{t('welcomeNewRetailer.title')}</h1>
        <h2 className={css.desc}>{t('welcomeNewRetailer.desc')}</h2>
        <ul className={css.pointList}>
          <li>
            <h2 className={css.subtitle} dangerouslySetInnerHTML={{ __html: t('welcomeNewRetailer.point_one') }} />
          </li>
          <li>
            <h2 className={css.subtitle} dangerouslySetInnerHTML={{ __html: t('welcomeNewRetailer.point_two') }} />
          </li>
        </ul>

        <div className={css.btnGroup}>
          <Link href={ROUTES.RETAILER_UPLOAD}>
            <Button label={t('welcomeNewRetailer.btn1_text')} style="secondary" className={css.actionBtn} />
          </Link>
          <Button
            label={t('welcomeNewRetailer.btn2_text')}
            style="tertiary"
            className={css.actionBtn}
            onClick={() => close()}
          />
        </div>
      </section>
    )
  }

  return (
    <LayoutHalfImageModal
      isOpen={isOpen}
      onChange={onChange}
      leftComponent={renderLeftComponent()}
      rightComponent={renderRightComponent()}
      classNameRightContainer={css.rightContainer}
      imgBackgroundCss={`url(${BG_IMG_CDN_PREFIX}/images/welcome_modal_banner.jpg)`}
      trackName="retailer-welcome"
    />
  )
}

export default WelcomeNewRetailerModal
