import { SECONDS_IN_A_DAY } from './time'

export const sitemapRoutes = [
  '/sitemap-static.xml',
  '/sitemap-brands.xml',
  '/sitemap-categories.xml',
  '/sitemap-products.xml',
  '/sitemap-discover.xml',
]

export const SITEMAP_PROD_URL = 'https://www.markato.com'

export const SITEMAP_CACHE_DURATION = SECONDS_IN_A_DAY * 3
