import React from 'react'

import ImageComponent from 'components/image'

import css from './autocomplete-action.module.scss'

type AutocompleteItemActionProps = {
  src: string
} & React.ComponentProps<'button'>

const AutocompleteItemAction = ({ children, src, ...props }: AutocompleteItemActionProps) => {
  return (
    <button className={css.btnAction} {...props}>
      <ImageComponent src={src} alt="search" width={24} height={24} />
    </button>
  )
}

export default AutocompleteItemAction
