import { useState } from 'react'

export const useModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const onChange = () => setIsOpen((previousState) => !previousState)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  return { isOpen, onChange, open, close }
}
