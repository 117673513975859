import { UseQueryResult, useQuery } from 'react-query'

import httpClient from 'services/http-client'

import { TEN_MINUTES_STALE_TIME } from 'constants/apis'

export const GET_BRANDS = '/brands'

export type Brand = {
  id: string
  storeName: string
  storeKey: string
  brandPage?: {
    logoRef: string
    description: string
    imgRef: string[]
  }
  isNew?: boolean
  isOnSale?: boolean
}

export const useBrands = (): UseQueryResult<Brand[]> =>
  useQuery({
    queryKey: [GET_BRANDS],
    queryFn: async () => {
      const { data: brands } = await httpClient.get<Brand>(GET_BRANDS)
      return brands
    },
    staleTime: TEN_MINUTES_STALE_TIME,
  })
