/* eslint-disable @tanstack/query/exhaustive-deps */
import { UseQueryResult, useQuery } from 'react-query'

import httpClient from 'services/http-client'

type TopBanner = {
  name: string
  text: string
  backgroundColor: string
  textColor: string
  linkText?: string
  url?: string
}

const GET_TOP_BANNER_QK = 'TOP_BANNER_QK'

export const useGetTopBanner = (lang: string): UseQueryResult<TopBanner> =>
  useQuery({
    queryKey: [GET_TOP_BANNER_QK, lang],
    queryFn: async () => {
      const { data: topBanner } = await httpClient.get<TopBanner>(`${location.origin}/api/top-banner?lang=${lang}`)
      return topBanner
    },
  })
