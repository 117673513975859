import { TranslationQuery } from 'next-translate'
// eslint-disable-next-line no-restricted-imports
import useTranslation from 'next-translate/useTranslation'
import { useCallback } from 'react'

export type TranslateFunc = (
  i18nKey: string | TemplateStringsArray,
  query?: TranslationQuery | null,
  options?: {
    returnObjects?: boolean
    fallback?: string | string[]
    default?: string
    ns?: string
  }
) => string

export const useTranslate = (file: string) => {
  const { t, lang } = useTranslation(file)

  const translate = useCallback(
    (
      i18nKey: string | TemplateStringsArray,
      query?: TranslationQuery | null,
      options?: {
        returnObjects?: boolean
        fallback?: string | string[]
        default?: string
        ns?: string
      }
    ): string => t(i18nKey, query, options),
    [t]
  )

  const COUNTRY = {
    HK: 'Hong Kong',
    SG: 'Singapore',
    MY: 'Malaysia',
  }

  const CURRENCY = {
    HK: 'HKD',
    SG: 'SGD',
    MY: 'MYR',
  }

  const defaultRedirectSubPath = {
    [COUNTRY.HK]: '/en-hk',
    [COUNTRY.SG]: '/en-sg',
    [COUNTRY.MY]: '/en-my',
  }

  const localeToCountry: Record<string, string> = {
    'en-hk': COUNTRY.HK,
    'zh-hk': COUNTRY.HK,
    'en-sg': COUNTRY.SG,
    'zh-sg': COUNTRY.SG,
    'en-my': COUNTRY.MY,
    'zh-my': COUNTRY.MY,
  }

  const localeToCurrency: Record<string, string> = {
    'en-hk': CURRENCY.HK,
    'zh-hk': CURRENCY.HK,
    'en-sg': CURRENCY.SG,
    'zh-sg': CURRENCY.SG,
    'en-my': CURRENCY.MY,
    'zh-my': CURRENCY.MY,
  }

  return {
    t: translate,
    lang: lang === 'en' ? 'en-hk' : lang.toLowerCase(),
    langSubPath: lang === 'en' ? '/en-hk' : '/' + lang.toLowerCase(),
    country: localeToCountry[lang],
    currency: localeToCurrency[lang],
    defaultRedirectSubPath,
    COUNTRY,
  }
}
